@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;700;800&display=swap');
@import"https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

li.timeline-inverted:hover {
    transform: scale(0.9);
    border-bottom: 1px solid #777;
    background: #1b31227d;
    border-radius: 10px;
}
.box-body p {
    margin-bottom: 0px;
}
.chatmsg {
    background-color: #ffffff;
    border-radius: 50%;
    height: 56px;
    line-height: 54px;
    margin: 0 15px 0 0;
    text-align: center;
    transform: rotate(0deg);
    width: 56px;
}
h3.nav-info-box-title {
    color: #fff;
    font-family: Rubik,Sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.15px;
    line-height: 22px;
    margin: 0 0 5px;
    padding: 0;
    text-transform: none;
}
.headercnt .box-body p a {
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -.15px;
    line-height: 22px;
    margin-bottom: 0;
    z-index: -111111!important;
}
.headercnt {
    background-color: #0f5c2e;
    border: 0 solid #02010100;
    border-radius: 0;
    display: flex;
    padding: 18px 30px;
    position: relative;
}
section.newcontct .container a.newnumber {
    margin-left: 0px;
    margin-top: 2px;
}
a.newnumber {
    margin-left: 35px;
    margin-top: 2px;
}
.one {
    margin-left: 63px;
    padding-left: 20px;
    border-left: 3px solid;
}
.abtbox1 {
    border-right: 50px solid #29733f;
    background: #ffffff;
    display: flow-root;
    border-bottom-right-radius: 50px;
    padding: 25px 10px 10px 15px;
    font-size: 50px;
    font-weight: bold;
    border-top-right-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
    padding-left: 64px;
margin-bottom: 5px;

}

section.abtsec32 {
    padding-top: 40px;
    padding-bottom: 80px;
}

div#wrong {
    padding-top: 80px;
}
.abtbox3 {
    border-right: 50px solid #29733f;
    background: #ffffff;
    display: flow-root;
    border-bottom-right-radius: 50px;
    padding: 25px 10px 10px 15px;
    font-size: 50px;
    font-weight: bold;
    border-top-right-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
    padding-left: 64px;
margin-bottom: 5px;
}
span.abtvisi {
    float: left;
    color: #357643;
    font-size: 40px;
}
.abtbox2 {
    border-left: 50px solid #29733f;
    background: #ffffff;
    display: flow-root;
    border-bottom-left-radius: 50px;
    padding: 25px 10px 10px 15px;
    font-size: 50px;
    font-weight: bold;
    border-top-left-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
    padding-left: 13px;
    padding-right: 50px;
    margin-bottom: 5px;
}
 /* Preloader */
        .ctn-preloader{
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: default;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          height: 100%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 9000;
          transition: opacity 0.2s linear;
        }
        .loader--hide {
          opacity: 0;
        }
        .loader--hide .ctn-preloader{
          z-index: -9999;
          visibility: hidden;
        }
        .ctn-preloader .animation-preloader {
          z-index: 1000;
        }

        .ctn-preloader .animation-preloader .spinner {
          -webkit-animation: spinner 1s infinite linear;
          animation: spinner 1s infinite linear;
          border-radius: 50%;
          border: 3px solid rgba(0, 0, 0, 0.2);
          border-top-color: #000000;
          height: 9em;
          margin: 0 auto 3.5em auto;
          width: 9em;
        }

        .ctn-preloader .animation-preloader .txt-loading {
          font: bold 5em "Poppins", sans-serif;
          text-align: center;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading {
          color: rgba(0, 0, 0, 0.2);
          position: relative;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
          -webkit-animation: letters-loading 4s infinite;
          animation: letters-loading 4s infinite;
          color: #000000;
          content: attr(data-text-preloader);
          left: 0;
          opacity: 0;
          font-family: "Poppins", sans-serif;
          position: absolute;
          top: -3px;
          -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
          -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
          -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
          -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
          -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
          -webkit-animation-delay: 1s;
          animation-delay: 1s;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
          -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
        }

        .ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
          -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
        }

        .ctn-preloader.dark .animation-preloader .spinner {
          border-color: rgba(255, 255, 255, 0.2);
          border-top-color: #fff;
        }

        .ctn-preloader.dark .animation-preloader .txt-loading .letters-loading {
          color: rgba(255, 255, 255, 0.2);
        }

        .ctn-preloader.dark .animation-preloader .txt-loading .letters-loading:before {
          color: #fff;
        }

        .ctn-preloader p {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 8px;
          color: #3b3b3b;
          display: block;
          text-align: center;
        }


section.banner-section.mybannerupdated form#myForm {
    background: #fff;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
    margin-bottom: 70px;
}
section.newcontct .container {
    background: #17703b;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 25px 0 #17703b26;
    margin-top: -80px;
}
.fromback {
    padding: 60px 160px 60px 60px;
    transition: 0.3s all ease-in-out;
    position: relative;
    background: #fff;
    right: 4%;
    border-radius: 16px;
    box-shadow: 0 6px 25px 0 #17703b26;
    z-index: -111;
}
section.newcontctbottm {
    padding-top: 80px;
    padding-bottom: 80px;
}
.cntctdetail a {
    color: #fff;
}
.cntctdetail {
    text-align: center;
}

.cntctdetail i {
    color: #fff;
    font-size: 40px;
}
.cntctdetail h6 {
    margin-top: 20px;
    color: #fff;
}
.cntctdetail p {
    color: #fff;
}
section.faqsec {
    padding-top: 100px;
}
.seekermodal .col-xxl-6.col-xl-6.col-md-6.col-lg-6.col-12 {
    padding: 5px;
}
.react-responsive-modal-modal {
    max-width: 500px;}
.seekermodal select#experience {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    font-size: 14px;
     line-height: 20px;
    margin-bottom: 16px;
    outline: none;
    color: #6f6f6f;
}
.seekermodal .col-xxl-12.col-xl-12.col-md-12.col-lg-12.col-12 {
    padding: 5px;
}
.react-responsive-modal-modal {
    padding: 50px;
    border-radius: 10px;
}
.jbeeker h4 {
    color: #fff;
}
.seekermodal input {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    outline: none;
}
.companyhiring-content h4 {
    color: #000;
}
button.modalbtn {
    background: #d2eae0;
    border-radius: 8px;
}
button.modalbtn2 {
    background: #17703b;
    color: #fff;
    border-radius: 8px;
}
img.companyhiring {
    position: relative;
    float: right;
    top: -10px;
    margin-right: -11%;
}


.jbeeker {
    padding: 30px 30px 30px 50px;
    height:240px ;
    margin-left: 90px;
    background: #17703b;
    border-radius: 10px;

}
.companyhiringcontent {
    padding: 30px 30px 30px 30px;
    height:240px ;
    background: #b4d7c9;
    border-radius: 10px;
      margin-right: 90px;
}
section.jobseekr {
    padding-top: 100px;
    padding-bottom: 100px;
}
div#jobhiring {
    margin-top: 60px;
}
img.companyhiring {
    position: absolute;
    top: 7%;
    right: 25%;
}
section.jobseekr h2 {
    text-align: center;
    margin-bottom: 10px;
}
.jobseeker-content p {
    color: #fff;
}
.jobseeker-content {
    margin-left: 170px;
}
img.jobseeker {
    position: absolute;
    top: 7%;
    left: 14%;
}
.companyhiring-content {
    margin-right: 150px;
}
.bnrtext1 {
    margin-top: -50px;
    margin-right: 40px;
}
.bnrtext1 h3 {
    font-size: 22px;
    margin-bottom: 20px;
}
.bnrtext1 p.wow.fadeInUp {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 40px;
    margin-top: 30px;
}
.banner-thumb {
    margin-left: 30px;
}
.bnrtext1 h1 {
    line-height: 64px;
    font-size: 54px;
}
section.banner-section.mybannerupdated {
    background: #f9fcfb;
}
section.banner-section.mybannerupdated button {
    color: #fff;
    background: #17703b;
    border-radius: 5px;
    padding: 12px 23px;
}
img.smartgirl {
    transition: all .3s ease;
    max-width: 80%;
}
ul#bnrshpaeimg img {
    padding-right: 10px;
}

p.count-text {
    margin-bottom: 0px;
}
.box1 {
    display: none;
}
p.fs-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    color: #17703b;
}
div#candidate .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
    padding: 10px;
}
div#fsty .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
    padding: 10px;
}
div#selrow {
    padding-left: 10px;
    padding-right: 10px;
}
ul.typeexperience li {
    display: inline-flex;
    margin-right: 20px;
    font-size: 16px;
}
select#cars {
    outline: none;
    border: 1px solid #d9d9da;
    margin-bottom: 5px;
    border-radius: 5px;
    color: #777777;
    line-height: 20px;
    font-size: 14px;
}
section.faqsec {
    padding-bottom: 80px;
}
section.Updatefaq {
    padding-top: 50px;
}
section.Updatefaq .accordion-button:focus {
    z-index: 3;
    border-color: unset;
    outline: 0;
    box-shadow: unset;
}
section.Updatefaq .card-body {
    border: 1px solid #dee6e3;
    margin-top: -10px;
    margin-bottom: 10px;
}
section.Updatefaq .accordion-button:not(.collapsed) {
    color: #17703b;
    background-color: #fff;
    box-shadow: unset;
}
section.Updatefaq .accordion-item {
    padding: 0px;
    background-color: #fff;
    border: unset;
}
section.Updatefaq .accordion-button:hover{
    background: #17703b;
    color: #fff;
    margin-bottom: 5px !important;
    border: 1px solid rgba(0,0,0,.125);
}
section.Updatefaq .accordion-button {
     border: 1px solid rgba(0,0,0,.125);
    color: #353535;
    font-size: 16px;
    border-radius: 5px;
    padding-left: 20px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
a.accserve {
    color: #17703b !important;
    font-weight: 700;
    text-decoration: underline;
}
.collapsible-link {
    width: 100%;
    position: relative;
    text-align: left;
  }
  
  section.Updatefaq .accordion-header{
    text-align: center;
    margin-bottom: 10px;
  }
  .collapsible-link::before {
    content: "\f107";
    position: absolute;
    top: 50%;
    right: 0.8rem;
    transform: translateY(-50%);
    display: block;
    font-family: "FontAwesome";
    font-size: 1.1rem;
  }
  
  .collapsible-link[aria-expanded="true"]::before {
    content: "\f106";
  }
  

img.blogimg {
    width: 85%;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
    position: relative;
    top: -36px;
}
.copyright-area.copyright-style-one.no-border i {
    font-size: 16px;
}
.thankyoubox {
    background: #fff;
    width: 700px;
    margin: 0 auto;
    text-align: center;
    padding: 80px 20px;
    box-shadow: 0 6px 25px 0 #17703b26;
    border-radius: 10px;
}
.thankyoubox img {
    width: 30%;
    margin-bottom: 30px;
}
p.updates {
    margin-top: 16px;
    font-size: 18px;
}
section.thankyou{
    background-image: url(../images/abtbnner.webp);
    padding-bottom: 150px;
    padding-top: 200px;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.widget.widget-tags ul.widget-wrapper li a:hover {
    color: #fff !important;
}
.dlab-post-textdetail {
    padding: 10px 0px 0px 0px;
}
h3.post-title-detail {
    padding: 10px 0px;
}
h4.communication {
    padding-bottom: 6px;
}
.pageblog {
    padding-top: 100px;
    padding-bottom: 80px;
}
img.blgdetailimg {
    width: 100%;
}
.dlab-post-text {
    padding: 0px 20px;
}
li.post-author i.fa.fa-user {
    color: #17703b;
    margin-right: 8px;
}
li.post-date i.fa.fa-calendar {
    margin-right: 10px;
}
li.post-date {
    background: #17703b;
    padding: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 10px;
    color: #fff;
    font-size: 14px;
}
.dlab-post-meta ul {
    list-style: none;
}
.dlab-post-title h4 a {
    color: #353535;
    padding: 20px 20px 10px 20px;
}
.dlab-post-title h4 a:hover {
    color: #17703b;
    padding: 20px 20px 10px 20px;
}
a.site-button.outline.outline-1 {
    color: #17703b;
    padding: 0px 20px 30px 20px;
    font-weight: 700;
}
div#perfect {
    background: #fff;
    transition: 0.3s all ease-in-out;
    position: relative;
    background: #fff;
    right: 4%;
    border-radius: 16px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 60px 60px 60px 100px;
    z-index: -111;
}
section.blogsection .post-inner {
    border-radius: 10px;
    box-shadow: 0 5px 10px rgb(16 17 21 / 10%);
    transition: all .3s ease;
    margin-bottom: 10px;
}
section.blogsection {
    padding-top: 130px;
    padding-bottom: 80px;
}
.client-thumb4 h4{
font-size: 20px;
font-weight: 600;
}
img.industimg {
    width: 40%;
    opacity: 0.7;
    margin-bottom: 30px;
}
.insd-section {
    padding-top: 70px;
}
.client-thumb4 {
    text-align: center;
}
section.hiringserivce7 {
    padding-top: 140px;
}
section.serpgsec6 {
    padding-top: 140px;
}
section.hiringserivce5 {
    padding-top: 140px;
}
section.alsersections4 {
    padding-top: 140px;
}
section.hiringserivce3 {
    padding-top: 140px;
}
.top-notch {
    box-shadow: 0 0 10px rgb(136 136 136 / 10%);
    margin-right: 80px;
}
.top-notch h3 {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #ecf0f3;
    font-size: 22px;
}
ul.widget-wrapper2 i {
    color: #17703b;
    margin-right: 5px;
    font-size: 20px;
}
ul.widget-wrapper2 {
    list-style: none;
}
p.aim {
    background: #f2f7f5;
    padding: 15px 10px 15px 30px;
    border-left: 3px solid #17703b;
    margin: 15px 0px;
}
ul.widget-wrapper2 li {
    border-bottom: 1px solid #ecf0f3;
    padding: 15px 25px;
    transition: 0.3s all ease-in-out;
}
section.serpgsec3 {
    padding-top: 100px;
}
img.tabhrteam {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
section.serpgsec3 h2 {
    margin-bottom: 20px;
}
h5.match {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 300;
    color: #353535;
}
.top-notch ul.lab-ul li {
    border-bottom: 1px solid #ecf0f3;
    padding: 15px 25px;
 
}
.react-tabs__tab-panel--selected {
    display: block;
    margin-left: 100px;
}
section.hiringserivce {
    padding-bottom: 60px;
}
div#secondbadge {
    box-shadow: 0 6px 25px 0 #0b371d;
    background: #e2f8ef;
}
section.alsersections {
    padding-bottom: 120px;
}
.cacilds {
    background: #17703b;
    padding: 30px 20px 30px 40px;
    border-radius: 10px;
    box-shadow: 0 6px 25px 0 #17703b26;
}
div#firstbadge {
    box-shadow: 0 6px 25px 0 #0b371d;
}
.cacilds h2 {
    color: #fff;
    margin-bottom: 30px;
}
h6.How {
    color: #fff;
}
.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
  }
  
  .timeline-heading {
    padding: 10px 10px 0px 10px;
}
/* .timeline:before {
    top: 69px;
    bottom: 50px;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 0%;
    margin-left: 6px;
} */
  
  .timeline > li {
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }
  
  .timeline > li:before,
  .timeline > li:after {
    content: " ";
    display: table;
  }
  
  .timeline > li:after {
    clear: both;
  }
  
 
  
  .timeline > li:after {
    clear: both;
  }
  
  .timeline > li > .timeline-panel {
    width: 95%;
    float: left;
    position: relative;
  
  }
  
  .timeline > li > .timeline-badge {
    color: #353535;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    font-weight: 700;
    text-align: center;
    position: absolute;
    top: 11%;
    left: 0%;
    margin-left: -18px;
    background-color: #fff;
    z-index: 100;
    border-top-right-radius: 10%;
    border-top-left-radius: 10%;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
}
  
  .timeline > li.timeline-inverted > .timeline-panel {
    float: left;
    margin-left: 40px;
    
  }
  
  .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .timeline-heading p {
    color: #fff;
    padding-right: 10px;
}
  
  .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
    
  .timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 500;
    color: #fff;
}
  
  .timeline-body > p,
  .timeline-body > ul {
    margin-bottom: 0;
  }
  
  .timeline-body > p + p {
    margin-top: 5px;
  }
  
  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  
  .flex-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  
  .flex-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  .flex-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  
  .flex-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .hrtabtile {
    font-size: 18px;

}
  .react-tabs__tab--selected i{
    color: #fff !important;
    transition: 0.3s all ease-in-out;
  }
  .react-tabs h2 {
    margin-bottom: 20px;
}
  .react-tabs__tab--selected {
    background: #17703b !important;
    border-color: #17703b !important;
    box-shadow: 0 0px 0px 0 #17703b26 !important;
    color: #fff !important;
    border-radius: 5px !important;
    transition: 0.3s all ease-in-out;
}


 .react-tabs__tab-list {
    border-bottom: unset;
    margin: 0 0 10px;
    padding: 0;
}
img.Hype {
    width: 28%;
}
.hrtabcontent {
    background: #17703b;
    padding: 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 6px 25px 0 #17703b26;
}
.hrtabtile i {
    color: #272727;
    font-size: 30px;
    padding: 15px 0;
}
section.hiringserivce .tabtile {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
}
.hrtabcontent h5 {
    color: #fff;
}
.hrtabcontent p {
    color: #fff;
}
.react-tabs__tab {
    transition: 0.3s all ease-in-out;
    display: inline-block;
    border: 1px solid #f2f7f5;
    border-bottom: none;
    bottom: unset;
    position: relative;
    list-style: none;
    text-align: center;
    margin-right: 10px;
    width: 200px;
    border-radius: 5px;
    height: 140px;
    background: #f2f7f5;
}
section.hiringserivce9 {
    padding-top: 120px;
    padding-bottom: 100px;
}
section.alsersections8 {
    padding-top: 120px;
}

.advance-tab-button.advance-tab-button-1 {
    margin-top: 40px;
}

section.bottom.sersec {
    padding-top: 80px;
    padding-bottom: 60px;
}
ul.Solutions {
    list-style: none;
    padding-left: 0px;
}
.Privacypolicyheader h6 {
    padding: 5px 0px;
}
section.second.footer a:hover {
    color: #17703b;
    font-weight: 500;
}
h2.Privacy {
    margin-bottom: 30px;
}
h1.Privacy {
    text-align: center;
}
section.Privacypolicy2 {
    padding-top: 80px;
    padding-bottom: 80px;
}
p.address i {
    font-size: 25px;
    color: #17703b;
    margin-right: 8px;
}
section.Privacypolicy {
    background: #f2f7f5;
    padding: 200px 0px 100px 0px;
}
section.Servicepagebanner h1 {
    text-align: center;
}
section.Servicepagebanner {
    background-image: url(../images//serivcebanner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 220px;
    padding-bottom: 150px;
}
ul#Reserved {
    float: right;
}
section.second.footer form#myForm {
    width: 85%;
}
.flex-item.flex-item-grow.flex-item-shrink.text-right {
    display: none !important;
}
div#challenges {
    margin-bottom: 60px;
}
section.second.footer button {
    color: #fff;
    background: #17703b;
    border-radius: 5px;
    padding: 12px 23px;
}
section.second.footer form#myForm {
    background: #fff;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
}
.whatsapp {
    position: fixed;
    top: 90%;
    left: 90%;
    bottom: 10%;
}
i.icofont-whatsapp {
    background: #0dc134;
    float: right;
    padding: 8px;
    border-radius: 50%;
    font-size: 29px;
    color: #fff;
}
a.Copyright {
    color: #000 !important;
}

.clients-slider-nav.clients-slider2-next {
    background: #f2f7f5 !important;
}
.clients-slider-nav.clients-slider2-prev i {
    color: #17703b;
}
.clients-slider-nav.clients-slider2-prev i:hover {
    color: #17703b
}
.clients-slider-nav.clients-slider2-prev {
    background: #17703b !important;
}
.clients-slider-nav.clients-slider2-prev {
    background: #f2f7f5 !important;
}
p#booterheader {
    font-size: 18px;
    line-height: 28px;
}
.clients-slider-nav.clients-slider2-next i {
    color: #17703b;
}
section.abtbnner{
    background-image: url(../images//abtbnner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 220px;
    padding-bottom: 150px;
}
html{
    scroll-behavior: smooth;
}
section.process {
    padding-top: 100px;
    padding-bottom: 80px;
}
.cont p {
    margin-top: 20px;
}
ul.Solutions li {
    padding: 3px 0;
}
ul.Solutions li i{
    margin-right: 5px;
}
.copyright-area.copyright-style-one.no-border {
    background: #f2f7f5;
    border-top: 1px solid #777;
    padding-top: 20px;
}
a.Copyright {
    color: #17703b;
}
ul.Solutions ul.lab-ul.social-icons i {
    font-size: 18px;
    margin: 0 auto;
}
.copyright-area  a{
    color: #353535;
}

section.second.footer a {
    color: #353535;
}
section.second.footer {
    background: #f2f7f5;
    padding: 100px 0px;
}
section.second.footer h4 {
    color: #17703b;
    margin-bottom: 20px;
}
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .content ul li a:hover{
    color: #17703b;
}
section.contactbner h1 {
    text-align: center;
}
section.contactbner {
    background-image: url(../images//contactbner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 220px;
    padding-bottom: 150px;
}
.swiper-Industriesserve::after {
    display: block;
    content: '';
    position: relative;
    right: 0;
    bottom: 0px;
    left: 98%;
    top: -41px;
    width: 11px;
    height: auto;
    border-left: 15px solid transparent;
    border-right: 86px solid transparent;
    border-top: 35px solid #7777;
    transform: rotate(111deg);
}
.swiper-Industriesserve::before {
    display: block;
    content: '';
    position: relative;
    right: 72px;
    bottom: 0px;
    top: 93px;
    width: 11px;
    height: auto;
    border-left: 14px solid transparent;
    border-right: 84px solid transparent;
    border-bottom: 33px solid #7777;
    transform: rotate(70deg);
}
.swiper-Industriesserve {
    z-index: 111111;
    background: #fafafa;
    padding: 10px 20px;
    margin-top: -80px;
    width: 100%;
    border: 1px solid #7777770f;
    height: 180px;
    text-align: center;
}
img.clientlogo1 {
    max-width: 150px;
    max-height: 100px;
}
div#myformfield {
    padding: 3px 6px;
}
ul.lab-ul.social-icons i {
    color: #fff;
    font-size: 20px;
    border-radius: 5px;
}
h6.Follow {
    color: #fff;
    margin-right: 15px;
}
.contact-thumb i {
    color: #fff;
    font-size: 40px;
}
.contact-content h6 {
    color: #fff;
}
.section-cont {
    padding-top: 100px;
    padding-bottom: 100px;
}
.contact-content p {
    color: #fff;
}
.contbox1 p {
    color: #fff;
    margin-top: 20px;
}
.contbox1 i {
    color: #fff;
    font-size: 25px;
}
section.reachout h4 {
    color: #fff;
    margin-bottom: 10px;
}
p.Are {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
}
.contbox1 {
    background: #17703b;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 25px 0 #17703b26;
    width: 80%;
    margin-bottom: 25px;
}
section.reachout {
    padding-top: 100px;
    padding-bottom: 100px;
}
section.cntbottom h3 {
    background: #17703b;
    border-radius: 5px;
    margin-top: -52px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 22px 0px 17px 0px;
    color: #fff;
}
h6.client-name3 {
    color: #fff;
}
.clients-section {
    padding-bottom: 80px;
}
.client-inner3 {
    padding-top: 80px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
}
span.client-degi3 {
    color: #fff;
}
p.test-review {
    color: #fffafa;
    padding-top: 10px;
}
.client-thumb3 {
    text-align: center;
    margin-bottom: -60px;
}
.client-item3 {
    text-align: center;
    background: #17703b;
    border-radius: 10px;
}
.jobs h2 {
    margin-bottom: 20px;
    margin-top: 30px;
}
div#blackcounter {
    padding: 0px 0px 20px 0px;
    box-shadow: 0px 5px 20px 3px #edf4f1;
    border-radius: 0px 0px 10px 10px;
    background: #f2f7f5;
}
.jobs h6 {
    margin-bottom: 20px;
}
section.hrounter i {
    font-size: 50px;
    color: #17703b;
    line-height: 1;
    margin-bottom: 5px;
    display: block;
}
div#blackcounter1 {
    padding-left: 40px;
    padding-right: 40px;
}
.jobs {
    margin-left: 100px;
}
.counter h5 {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 25px;
}
section.hrounter .counter {
    text-align: center;
    box-shadow: 0px 5px 20px 3px #edf4f1;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 30px;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}
section.hrounter h4 {
    background: #17703b;
    padding: 30px;
    border: 1px solid #e7f5ef;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 40px;
    color: #fff;
}
section.hrounter {

    padding-bottom: 100px;
}
section.abtsec4 h6 {
    padding: 5px 0px;
}
ul.lab-ul i.icofont-tick-mark {
    margin-right: 5px;
    color: #17703b;
}
ul.lab-ul li {
    padding: 3px 0;
}
section.abtsec4 h2 {
    margin-bottom: 10px;
}
section.abtsec3 h2 {
    margin-bottom: 20px;
    text-align: center;
}
section.abtsec3 p {
    text-align: center;
}
section.abtsec3 {
    padding-top: 80px;
    padding-bottom: 80px;
}
img.visi {
    margin-top: 80px;
    max-width: 80%;
}
.Expenses {
    padding-top: 180px;
    padding-right: 60px;
}
.one h5 {
    color: #357643;
}
.Expenses h2 {
    margin-bottom: 20px;
}
section.abtsec2 {
    background: #f2f7f5;
    padding-top: 200px;
    margin-top: -185px;
    padding-bottom: 70px;
    position: relative;
        z-index: -111111;
}
div#tean {
    margin-top: 60px !important;
    margin: 0;
    text-align: center;
}
div#tean-left {
    padding: 0;
}
img.hrteam {
    left: 10%;
    border-radius: 10px;
    width: 77%;
    margin-top: 21px;
    position: relative;
    z-index: 11111;
}
.abtcontent {
    text-align: left;
}
div#tean-right {
    position: relative;
    transition: 0.3s all ease-in-out;
    position: relative;
    background: #fff;
    right: 7%;
    border-radius: 16px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 60px 60px 60px 140px;
    z-index: 111;
}
section.abtsec1 {
    padding-top: 80px;
    z-index: -1111;
    position: relative;

}
p.working {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
li.firstlist {
    border-right: 2px solid;
    transition: 0.4s all ease-in-out;
}
ul.abtlist li {
    padding: 0px 20px;
}
ul.abtlist {
    display: inline-flex;
    color: #fff;
    list-style: none;
}
.abtbotmbox {
    background: #17703b;
    border-radius: 5px;
    margin-top: -52px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 25px 0px 12px 0px;
}
section.abtbnner h1 {
    text-align: center;
}
.accordion {
    margin: auto;
    width: 100%;
  }
  .arrows section .box-title:hover {
    background: #17703b;
    color: #fff;
    margin-bottom: 5px !important;
    border: 1px solid rgba(0,0,0,.125);
}
  .accordion input {
    display: none;
  }
  .box {
    position: relative;
    background: white;
        margin-bottom: 5px;
      transition: all .15s ease-in-out;
  }
  .box::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    
  }
  header.box {
    background: #00BCD4;
    z-index: 100;
    cursor: initial;
    box-shadow: 0 -1px 0 #e5e5e5,0 0 2px -2px rgba(0,0,0,.12),0 2px 4px -4px rgba(0,0,0,.24);
  }
  header .box-title {
    margin: 0;
    font-weight: normal;
    font-size: 16pt;
    color: white;
    cursor: initial;
  }
  .box-title {
    width: calc(100% - 40px);
    height: 64px;
    line-height: 64px;
    padding: 0 20px;
    display: inline-block;
    cursor: pointer;
    -webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
  }
  .box-content {
    width: 100%;
    padding: 30px 56px;
    font-size: 16px;
    border: 1px solid #17703b;
    margin-top: -5px;
    color: #353535;
    display: none;
  }
  .box-close {
    position: absolute;
    height: 64px;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none;
  }
  input:checked + .box {
    height: auto;
    margin: 5px 0;
   
  }
  input:checked + .box .box-title {
    border-bottom: 1px solid rgba(0,0,0,.18);
  }
  input:checked + .box .box-content,
  input:checked + .box .box-close {
    display: inline-block;
  }
  .arrows section .box-title {
    width: 100%;
    background: #fff;
    font-weight: 500;
    margin-bottom: 5px !important;
    border: 1px solid rgba(0,0,0,.125);
    color: #000;
    font-size: 20px;
    border-radius: 5px;
    padding-left: 45px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .arrows section .box-title:before {
    position: absolute;
    display: block;
    content: '\203a';
    font-size: 20pt;
    left: 20px;
    top: -2px;
    transition: transform .15s ease-in-out;
    color: #17703b;
  }
  .arrows section .box-title:hover:before {
    position: absolute;
    display: block;
    content: '\203a';
    font-size: 20pt;
    left: 20px;
    top: -2px;
    transition: transform .15s ease-in-out;
    color: #fff;
  }

  input:checked + section.box .box-title:before {
    transform: rotate(90deg);
  }
  
p.organiation {
    text-align: center;
}
div#faqs {
    margin-top: 50px;
}
section.faqhome {
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 100px;
}
section.faqsec h2 {
    text-align: center;
    margin-bottom: 20px;
}
.pf-left a.lab-btn-text1 {
    color: #17703b;
}
ul.widget-wrapper li a {
    color: #353535 !important;
}
.post-item {
    margin-bottom: 90px;
}
ul.widget-wrapper li i {
    color: #17703b;
}
img.blgimg {
    max-width: 80%;
    margin: 0 auto;
    float: none;
    text-align: center;
    display: block;
    border-radius: 10px;
    margin-top: -50px;
}
div#bbg {
    padding: 0px;
}
.homeform {
    margin-left: 40px;
    margin-top: 30px;
}
img.contctlady {
    position: relative;
    transition: all .3s ease;
    left: -22%;
    width: 75%;
    top: 5%;
    border-radius: 10px;
    box-shadow: 0 6px 25px 0 #17703b26;
}
div#contctbottom {
    align-items: center;
    text-align: center;
}
.feature-register input {
    outline: none;
    border: 1px solid #d9d9da;
    margin-bottom: 5px;
    border-radius: 5px;
    line-height: 20px;
    font-size: 14px;
}
.feature-register {
    padding: 40px 104px 100px 80px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 6px 25px 0 #17703b26;
    margin-left: 100px;
}
.feature-register button.lab-btn {
    text-align: left !important;
    float: left;
}
div#Expense {
    background: linear-gradient(130deg, #17703b 0%, #197d42 100%);
    border-radius: 20px;
    padding: 80px 80px 0px 80px;
    box-shadow: 0 6px 25px 0 #17703b26;
}
.feature-register p {
    text-align: left;
}
.feature-register h3 {
    text-align: left;
}
div#lasthiring {
    margin-top: 10px;
}
section.contactsec {
    padding-top: 100px;
    padding-bottom: 100px;
}
.client-item .client-inner .client-content p {
    font-size: 22px;
}
span.subtitle {
    font-size: 1.125rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #17703b;
    font-weight: 500;
}
section.abtfreature {
    padding-top: 60px;
    text-align: center;
}

section.abtfreature h2 {
    margin-bottom: 20px;
}
img.aboutfreature {
    margin-top: 60px;
}
img.hire2 {
    border: 1px solid #e4e4e491;
    padding: 10px;
    width: 16%;
    background: #fff;
    box-shadow: -6px 10px 10px #17703b26;
    border-radius: 8px;
    margin-bottom: 20px;
}
a.Quote {
    color: #fff !important;
    background: #17703b;
    border-radius: 5px;
    padding: 12px 30px;
    margin-top: 15px
}

img.gentleman {
    margin-top: -26px;
    margin-left: 208px;
    max-width: 52%;
}

.services .box {
    transition: 0.3s all ease-in-out;
    position: relative;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 30px 20px;
    height: auto;
}
  .services.box .boxContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 15px;
    overflow: hidden;
  }
  a.Copyright {
    color: #17703b;
}
.abtcontent h2 {
    margin-bottom: 20px;
}
section.process h2 {
    margin-bottom: 20px;
    margin-top: 30px;
}
  
  .services  .box .boxContent .title {
    font-size: 18px;
    color: #000;
    font-weight: bold;
   
  }
  .services .box .boxContent .desc {
    color: #353535;
      line-height: 24px;
  }
  .services  .box a {
    font-size: 16px;
    font-weight: 600;
    color: #17703b;
 
  }
  .services .box:hover {
    background: linear-gradient(130deg, #17703b 0%, #197d42 100%);
    box-shadow: none;
  }
  .services  .box:hover a,
  .services .box:hover .title,
  .services .box:hover .desc {
    color: white;
    transition: 0.3s all ease-in-out;
  }

.vacant h4{
    color: #353535;
}

.learnbtn a {
    color: #17703b;
    font-weight: 600;
}
.vacant:hover {
    background-image: linear-gradient(90deg,#55a06d,#18723c);
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 20px;
}
.vacant {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 20px;
}
img.hire {
    border: 1px solid #e4e4e491;
    padding: 6px;
    width: 40%;
    background: #fff;
    box-shadow: -6px 10px 10px #17703b26;
    border-radius: 8px;
    margin-bottom: 15px;
}
.allserivces h2 {
    line-height: 45px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.allserivces {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
    padding: 30px 30px 0px 30px;
    position: relative;
}
section.industries {
    text-align: center;
    padding-top: 100px;
}
section.industries h2 {
    margin-bottom: 15px;
}
section.services {
    padding-bottom: 150px;
    padding-top: 60px;
    background: #f2f7f5;
    margin-top: 80px;
}
img.indusimg {
    width: 30%;
    margin-bottom: 20px;
}
.Industries {
    padding-top: 60px;
    text-align: center;
}
.allserivces span.subtitle {
    font-size: 1.125rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #17703b;
    font-weight: 500;
}
.inst-item h4 {
    font-size: 20px;
}
.serviceBox .service-icon {
    background: #fff;
    position: absolute;
    width: 75px;
    height: 75px;
    border-radius: 10px;
    top: 26%;
    line-height: 90px;
    font-size: 50px;
    text-align: center;
    box-shadow: -10px 6px 28px 4px #17703b38;
    color: #17703b;
    left: 4%;
}
img.consultation {
    max-width: 65px;
    margin-top: -20px;
}
section.featuressec1 {
    padding-top: 40px;
 }
span.featspan {
    font-size: 45px;
    font-weight: 800;
    color: #e3e3e3;
    font-family: 'Poppins', sans-serif;
    margin-left: -15px;
}
p.description {
    padding-top: 50px;
    line-height: 28px;
}
.serviceBox h3.title {
    background-image: linear-gradient(90deg,#55a06d,#18723c);
    padding: 15px 20px 15px 80px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;

}
.bnrtext h3 {
    font-size: 22px;
    margin-bottom: 20px;
}
.serviceBox {
    padding: 10px 0;
    }

.serviceBox .service-content{
    margin-left:85px;
}
.serviceBox .service-content h3{
    font-size:13px;
    margin-top: 5px;
    font-weight:bold;
    color:#000;
}
.serviceBox .service-content p{
    font-size:13px;
    color:#000;
    line-height:21px;
}
@media screen and (max-width: 990px){
    .serviceBox{
        margin-bottom: 5px;
    }
}
section.Myhomesec1 h2 {
    margin-bottom: 15px;
}
section.Myhomesec1 {
    padding-top: 100px;
    text-align: center;
}
.bnrtext {
    margin-top: 110px;
    margin-right: 30px;
}
.bnrtext p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 40px;
    margin-top: 30px;
}
.bnrtext h1 {
    font-size: 57px;
    letter-spacing: -2px;
    font-weight: 650;
    line-height: 70px;
    margin-bottom: 22px;
}
input.bnnrfrm {
    width: calc(100% - 146px);
    border: none;
    padding: 18px;
    padding-left: 20px;
}
section.mybanner form button {
    color: #fff;
    background: #17703b;
    border-radius: 5px;
    padding: 12px 23px;
}
span.mycolor {
    color: #17703b;
}
section.mybanner form .banner-icon {
    left: 15px;
    top: 50%;
    font-weight: 700;
    transform: translateY(-50%);
}
section.mybanner form {
    background: #fff;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 6px 25px 0 #17703b26;
}
section.mybanner {
    background: #f9fcfb;
    padding-top: 150px;
}

@media (min-width: 1200px){
.container {
    max-width: 1140px;
}
}

@media (min-width: 1400px){
    .container {
        max-width: 1200px;
    }
    }
    @media (min-width: 1600px){
        .container {
            max-width: 1400px;
        }
        }
        @media (min-width: 1920px){
            .container {
                max-width: 1400px;
            }
            }
        

.news-letter .section-wrapper,
footer.style-2 .footer-bottom .section-wrapper,
footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li,
.widget.letest-product .widget-wrapper ul li,
.widget.widget-tags ul.widget-wrapper,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent,
.comment-respond .add-comment .comment-form,
.comments .comment-list .comment,
.navigations-part,
.csdc-lists ul li,
.course-side-detail .csd-content .sidebar-social .ss-content ul,
.course-side-detail .csd-title,
.authors,
.contact-form,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box,
.shop-cart .section-wrapper .cart-top table tbody tr td.product-item,
.shop-single .review .review-content .description .post-item,
.shop-single .review .review-content .review-showing .client-review .review-form form .rating ul,
.shop-single .review .review-content .review-showing .client-review .review-form form .rating,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta,
.shop-single .review .review-nav,
.shop-single .product-details .post-content form,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .cart-button,
.shop-page .shop-product-wrap .product-list-item,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link,
.app-section .section-wrapper ul,
.date li,
.date,
.workshop-join .workshop-join-form form .input-area,
.workshop-join .workshop-join-form form,
.instructor-section.style-2 .section-wrapper .count-item .count-inner,
.contact-info-section .section-wrapper,
.about-section.style-4 .about-item .counter-part .count-item .count-inner,
.about-section.style-4 .about-item .about-content .details li p,
.about-section.style-4 .about-item .about-content .details li,
.about-section.style-4 .about-items,
.about-section .about-right .section-wrapper ul li,
.course-section.style-3 .section-header .course-filter-group ul,
.course-section.style-3 .section-header,
.course-section.style-2 .section-header .course-navigations,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content,
.banner-section.style-4 .banner-content ul,
.banner-section.style-4 .banner-content form,
.banner-section .section-wrapper .banner-content form,
.menu-search-form form,
.menu>ul,
.header-wrapper .menu-area,
.header-wrapper,
.header-top-area .left,
.header-top-area,
.header-section.style-2 .logo-search-acte,
.feature-item .feature-inner,
.event-item .event-inner .event-content .event-content-info ul,
.event-item .event-inner .event-content,
.contact-item,
.skill-item .skill-inner,
.achieve-item .achieve-inner,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider,
.post-item .post-inner .post-footer,
.post-item .post-inner .post-content .meta-post ul,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left,
.stu-feed-item .stu-feed-inner .stu-feed-top,
.instructor-item.style-2 .instructor-inner,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item.style-3 .course-inner .course-thumb .course-info,
.course-item.style-2 .course-inner,
.course-item .course-inner .course-content .course-footer,
.course-item .course-inner .course-content .course-details,
.course-item .course-inner .course-content .course-category,
.social-icons,
.account-form .form-group .checkgroup,
.default-pagination {
    display: flex;
    flex-wrap: wrap
}

.comment-respond .add-comment .comment-form p.comment-form-cookies-consent {
    flex-wrap: nowrap
}

.news-letter .section-wrapper .news-form form .nf-list,
.news-letter .section-wrapper,
.news-footer-wrap,
.footer-top .footer-item .footer-inner .footer-content .title h4,
.widget.widget-search .search-wrapper,
.blog-single .section-wrapper .post-item .post-inner .post-content .video-thumb,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote,
.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content h6 span,
.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content p span,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items .or-left,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li,
.overview-announce-section .course-view-bottom ul li a,
.course-view-section .course-view .video-part .content-wrapper,
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left,
.course-select-area .csa-body ul li .card .card-header h5 button,
.shop-single .review .review-content .description .post-item .post-content ul li,
.shop-single .product-details .post-content form .select-product select,
.shop-single .product-details .post-content form .select-product,
.shop-single .product-details .product-thumb,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .thumb,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb,
.shop-page .shop-product-wrap .product-item .product-thumb,
.shop-page .shop-product-wrap .product-list-item .product-thumb,
.skill-section .container,
.skill-section,
.choose-section,
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb,
.student-feedbak-section,
.register-section .section-wrapper *,
.register-section .section-wrapper,
.clients-section.style-3 .section-wrapper .clients-slider2,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb,
.clients-section.style-3 .section-header,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb img,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb,
.clients-section.style-2 .section-wrapper .clients,
.feature-section *,
.feature-section,
.newsletters-section .newsletter-area .news-mass i,
.newsletters-section .newsletter-area,
.newsletters-section,
.workshop-join .section-wrapper .workshop-title h5,
.event-section.style-2 .section-wrapper,
.event-section *,
.event-section,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li span,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb img,
.instructor-single-section .instructor-wrapper .instructor-single-top,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider,
.instructor-section.style-2 .section-wrapper,
.instructor-section.style-2,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-thumb,
.contact-info-section .section-wrapper .cinfo-or span,
.contact-info-section .section-wrapper,
.about-section.style-3 .about-left,
.about-section .about-right,
.about-section .about-left,
.about-section,
.course-section.style-3,
.course-section.style-2 .section-header,
.category-section.style-4 .section-wrapper .category-item .category-inner,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate,
.pageheader-section.style-2 .pageheader-thumb,
.banner-section.style-5 .section-wrapper .banner-content,
.banner-section.style-4 .banner-content form,
.banner-section.style-4,
.banner-section.style-2 .section-wrapper .thumb-part,
.banner-section.style-2,
.banner-section .section-wrapper .banner-thumb,
.banner-section .section-wrapper .banner-content form,
.banner-section .cbs-content-list ul li.ccl-shape a,
.banner-section,
.menu-item-has-children>a,
.header-bar,
.feature-item .feature-inner .feature-content .lab-btn-text span,
.event-item .event-inner .event-content .event-date-info .edi-box h4,
.achieve-item .achieve-inner,
.count-item .count-inner .count-icon i,
.count-item .count-inner .count-icon,
.post-item.qute-post .post-inner .post-thumb,
.post-item.video-post .post-thumb,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider,
.post-item .post-inner .post-footer .pf-right,
.course-item.style-5 .course-inner .course-thumb,
.course-item.style-4 .course-inner .course-thumb,
.course-item.style-3 .course-inner .course-thumb,
.course-item.style-2 .course-inner .course-thumb,
.course-item .course-inner .course-content .course-category .course-cate a,
.course-item .course-inner .course-content,
.cart-plus-minus,
.or span,
.or,
.shape-img,
.video-button,
.select-item {
    position: relative
}

.news-letter .section-wrapper .news-form form .nf-list::after,
.news-footer-wrap .fs-shape img,
.footer-top .footer-item .footer-inner .footer-content .title h4::after,
.widget.widget-search .search-wrapper button,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote::before,
.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content h6 span::after,
.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content p span::after,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .view-details,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items .or-left:after,
.overview-announce-section .course-view-bottom ul li a::after,
.course-view-section .course-view .video-part .content-wrapper .content-icon,
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left>i,
.course-select-area .csa-body ul li .card .card-header h5 button .icon,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select .select-icon,
.shop-single .review .review-content .description .post-item .post-content ul li::before,
.shop-single .review .review-content .review-showing .client-review .review-form .review-title h5::after,
.shop-single .review .review-content .review-showing .client-review .review-form .review-title h5::before,
.shop-single .product-details .post-content form .select-product i,
.shop-single .product-details .product-thumb .pro-single-prev,
.shop-single .product-details .product-thumb .pro-single-next,
.shop-page .modal .modal-dialog .modal-content button.close,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb::after,
.skill-section::before,
.choose-section::after,
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb .video-button,
.student-feedbak-section::before,
.register-section .section-wrapper::after,
.clients-section.style-3 .section-wrapper .clients-slider-nav,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb .quote,
.clients-section.style-3 .section-header .abs-title,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::before,
.clients-section.style-2 .section-wrapper .clients .client-list,
.feature-section.style-3 .feature-shape,
.feature-section::after,
.newsletters-section::after,
.workshop-join .section-wrapper .workshop-title h5::after,
.event-section.style-2 .section-wrapper .event-navi-item,
.event-section::after,
.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item .skill-thumb .circles .circle strong,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::after,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::before,
.instructor-single-section .instructor-wrapper .instructor-single-top::before,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi,
.instructor-section.style-2::after,
.contact-info-section .section-wrapper .cinfo-or,
.contact-info-section .section-wrapper::after,
.about-section.style-3 .about-left .about-left-content::after,
.about-section.style-3 .about-left .about-left-content,
.about-section.style-3 .about-left .abs-thumb,
.about-section::before,
.course-section.style-3 .course-shape,
.course-section.style-2 .section-header .course-navigations,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content,
.category-section.style-4 .section-wrapper .category-item .category-inner::after,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate::before,
.pageheader-section.style-2 .pageheader-thumb .video-button,
.banner-section.style-5::after,
.banner-section.style-4 .banner-content form button,
.banner-section.style-2 .section-wrapper .thumb-part .abs-thumb,
.banner-section.style-2::before,
.banner-section .section-wrapper .banner-content form .banner-icon,
.banner-section .cbs-content-list ul li.ccl-shape a::after,
.banner-section .cbs-content-list ul li.ccl-shape,
.banner-section .all-shapes::after,
.banner-section .all-shapes::before,
.banner-section .all-shapes,
.menu-search-form form,
.menu-item-has-children>a::before,
.menu-item-has-children>a::after,
.header-bar span,
.header-section,
.feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-item .feature-inner .feature-content .lab-btn-text span::before,
.event-item .event-inner .event-content .event-date-info .edi-box h4::after,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-next,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-prev,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item.style-3 .course-inner .course-thumb::after,
.course-item.style-3 .course-inner .course-thumb .course-info,
.course-item .course-inner .course-price,
.course-item .course-inner .course-content .course-category .course-cate a::before,
.cart-plus-minus .qtybutton,
.or::before,
.or::after,
.shape-img::after,
.video-button::before,
.video-button::after,
.select-item .select-icon {
    position: absolute;
    content: ""
}

.student-feedbak-section .section-wrapper .sf-left .sfl-thumb .video-button,
.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item .skill-thumb .circles .circle strong,
.pageheader-section.style-2 .pageheader-thumb .video-button,
.banner-section .all-shapes::after,
.banner-section .all-shapes::before,
.shape-img::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.widget.letest-product .widget-wrapper ul li .product-thumb,
.widget.recipe-categori,
.widget .widget-header,
.comments .comment-list .comment .comment-list .comment,
.comments .comment-list .comment .com-content,
.comments .comment-list .comment .com-thumb,
.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-thumb,
.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-thumb,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right.overview-description,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li,
.shop-cart .section-wrapper .cart-bottom,
.shop-single .product-details,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .product-container,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .thumb,
.shop-page .shop-product-wrap .product-item .product-thumb,
.shop-page .shop-product-wrap .product-list-item .product-thumb,
.clients-section.style-2,
.feature-section.style-3,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item .instructor-inner .instructor-thumb,
.instructor-section.style-2,
.course-section.style-3,
.course-section.style-2 .section-wrapper .course-slider,
.sponsor-section .section-wrapper,
.banner-section.style-4 .banner-content form,
.banner-section,
.client-item .client-inner .client-thumb,
.event-item .event-inner .event-thumb,
.achieve-item .achieve-inner,
.post-item.style-3 .post-inner .post-thumb,
.post-item.style-2 .post-inner .post-thumb,
.course-item.style-5 .course-inner,
.course-item.style-3 .course-inner,
.course-item .course-inner .course-thumb,
.preloader,
.yellow-color-section .post-item.style-3 .post-inner .post-thumb,
.cart-plus-minus,
.post-item.style-2 .post-inner .post-content a>h2,
.widget.widget-post ul.widget-wrapper li .post-content a h6,
.post-item.style-2 .post-inner .post-content h3,
.post-item .post-inner .post-content h4,
.course-item .course-inner .course-content h4 {
    overflow: hidden
}

.news-letter .section-wrapper .news-form form .nf-list input[type=submit],
.widget.widget-search .search-wrapper button,
.comment-respond .add-comment .comment-form .lab-btn,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .view-details,
.course-select-area .csa-body ul li .card .card-body .course-lists,
.shop-single .product-details .post-content form .lab-btn,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb,
.course-video .course-video-content .accordion-item .accordion-header button,
.course-section.style-3 .section-header .course-filter-group ul li,
.header-bar,
.header-wrapper .menu-area .ellepsis-bar i,
.search-icon {
    cursor: pointer
}

.news-letter .section-wrapper .news-form form .nf-list,
.news-letter .section-wrapper .news-title,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.widget.shop-widget .widget-wrapper .shop-menu li a,
.widget.widget-post ul.widget-wrapper li .post-thumb a img,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags,
.blog-single .section-wrapper .post-item .post-inner .post-content img,
.blog-single .section-wrapper .post-item .post-inner .post-content iframe,
.blog-single .section-wrapper .post-item .post-inner .post-thumb img,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent,
.comment-respond .add-comment .comment-form input[type=text],
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.authors .author-thumb img,
.map-area .maps iframe,
.map-area .maps,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .cart-page-input-text,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select.shipping-select,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select select,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout,
.shop-cart .section-wrapper .cart-top table,
.shop-single .review .review-content .description .post-item .post-content,
.shop-single .review .review-content .description .post-item .post-thumb,
.shop-single .review .review-content .review-showing .client-review .review-form form textarea,
.shop-single .review .review-content .review-showing .client-review .review-form form input,
.shop-single .review .review-content .review-showing .content li .post-content,
.shop-single .product-details .post-content form .discount-code,
.shop-single .product-details .post-content form .select-product select,
.shop-single .product-details .post-content form .select-product,
.shop-single .product-details .product-thumb .pro-single-thumbs .single-thumb img,
.shop-single .product-details .product-thumb .pro-single-top .single-thumb img,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-content,
.shop-page .shop-product-wrap .product-list-item .product-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-thumb,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb::after,
.skill-section::before,
.student-feedbak-section .section-wrapper .sf-left .sfl-thumb img,
.feature-section::after,
.newsletters-section::after,
.date li .count-title,
.date li p,
.event-section::after,
.instructor-single-section .instructor-wrapper .instructor-single-bottom .subtitle,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb img,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item .instructor-inner .instructor-thumb img,
.course-video .course-video-content .accordion-item .accordion-header button,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-thumb img,
.category-section.style-4 .section-wrapper .category-item .category-inner::after,
.banner-section.style-7 .section-wrapper .banner-bottom .flag-item .flag-thumb img,
.banner-section.style-5::after,
.banner-section.style-4 .banner-content form input,
.menu-search-form,
.header-bar span,
.header-section.header-fixed,
.header-section,
.event-item .event-inner .event-content .event-content-info,
.event-item .event-inner .event-thumb img,
.post-item.qute-post .post-inner .post-thumb .qute-content,
.post-item .post-inner .post-thumb a img,
.instructor-item.style-2 .instructor-inner .instructor-content,
.instructor-item.style-2 .instructor-inner .instructor-thumb,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item.style-3 .course-inner .course-thumb::after,
.course-item.style-3 .course-inner .course-thumb .course-info,
.course-item.style-2 .course-inner .course-thumb,
.course-item .course-inner .course-thumb img,
.or::before,
.or::after,
.account-form .form-group button,
.account-form .form-group input,
.select-item select,
input,
textarea,
select {
    width: 100%
}

.comment-respond .add-comment .comment-form p.comment-form-cookies-consent input {
    width: auto
}

.widget.widget-post ul.widget-wrapper li .post-thumb a,
.map-area .maps iframe,
.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb::after,
.skill-section::before,
.feature-section::after,
.newsletters-section .newsletter-area::after,
.newsletters-section::after,
.workshop-join .section-wrapper .workshop-title h5::after,
.event-section::after,
.instructor-section.style-2::after,
.category-section.style-4 .section-wrapper .category-item .category-inner::after,
.banner-section.style-5::after,
.or::before,
.or::after {
    height: 100%
}

.course-item.style-3 .course-inner .course-thumb::after {
    height: 50%
}

.post-item .post-inner .post-footer .pf-right .comment-count {
    font-weight: 700
}

.blog-single .section-wrapper .post-item .post-inner .post-content .video-thumb .video-button,
.newsletters-section .newsletter-area .news-mass::after,
.newsletters-section .newsletter-area .news-mass::before,
.newsletters-section .newsletter-area .news-mass,
.newsletters-section .newsletter-area::after,
.contact-info-section .section-wrapper .cinfo-or::after,
.contact-info-section .section-wrapper .cinfo-or::before,
.count-item .count-inner .count-icon::after,
.count-item .count-inner .count-icon::before,
.post-item.qute-post .post-inner .post-thumb .qute-content,
.post-item.video-post .post-thumb .video-button {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.news-letter .section-wrapper,
.widget.widget-tags ul.widget-wrapper,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link,
.app-section .section-wrapper ul,
.offer-section .section-wrapper .date,
.banner-section.style-4 .banner-content ul,
.course-item.style-3 .course-inner .course-content .course-details,
.course-item.style-3 .course-inner .course-thumb .course-info,
.default-pagination {
    justify-content: center
}

footer.style-2 .footer-bottom .section-wrapper,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section,
.comment-respond .add-comment .comment-form,
.comments .comment-list .comment,
.navigations-part,
.csdc-lists ul li,
.course-side-detail .csd-title,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta,
.shop-single .product-details .post-content form,
.workshop-join .workshop-join-form form .input-area,
.workshop-join .workshop-join-form form,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item,
.contact-info-section .section-wrapper,
.course-section.style-3 .section-header,
.header-wrapper,
.header-top-area,
.event-item .event-inner .event-content,
.achieve-item .achieve-inner,
.post-item .post-inner .post-footer,
.stu-feed-item .stu-feed-inner .stu-feed-top,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-item .course-inner .course-content .course-footer,
.course-item .course-inner .course-content .course-details {
    justify-content: space-between
}

.shop-single .product-details .post-content p.rating,
.course-item.style-5 .course-inner .course-content .course-footer {
    justify-content: flex-start
}

.navigations-part .right {
    justify-content: flex-end
}

.news-letter .section-wrapper,
footer.style-2 .footer-bottom .section-wrapper,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box,
.shop-single .review .review-content .description .post-item,
.shop-single .review .review-content .review-showing .client-review .review-form form .rating,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta,
.shop-single .product-details .post-content form,
.shop-single .product-details .post-content p.rating,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .cart-button,
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item,
.about-section .about-right .section-wrapper ul li,
.course-section.style-3 .section-header,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content,
.header-wrapper .menu-area,
.header-wrapper,
.header-top-area,
.header-section.style-2 .logo-search-acte,
.feature-item .feature-inner,
.contact-item,
.skill-item .skill-inner,
.post-item .post-inner .post-footer,
.post-item .post-inner .post-content .meta-post ul,
.instructor-item.style-2 .instructor-inner,
.course-item.style-2 .course-inner,
.course-item .course-inner .course-content .course-footer,
.account-form .form-group .checkgroup {
    align-items: center
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item {
    align-items: flex-start
}

.blog-single .section-wrapper .post-item .post-inner .post-content iframe,
.comment-respond .add-comment .comment-form .lab-btn,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea {
    border: none
}

.comment-respond .add-comment .comment-form .lab-btn,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea {
    outline: none
}

.widget.shop-widget .widget-wrapper .shop-menu li a,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote cite,
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-content a,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .ratting,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item .instructor-inner .instructor-thumb img,
.course-video .course-video-content .accordion-item .accordion-header button,
.header-wrapper .logo a,
.feature-item .feature-inner .feature-content a,
.event-item.style-2 .event-inner .event-content>span,
.event-item.style-2 .event-inner .event-content,
.post-item .post-inner .post-thumb a,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content span,
.course-item.style-5 .course-inner .course-content .course-cate,
.category-item .category-inner .category-content span,
.or,
.video-button {
    display: block
}

footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title h4::after,
.student-feedbak-section::before,
.feature-section.style-3::after,
.feature-section.style-2::after,
.newsletters-section.style-2 .newsletter-area::after,
.newsletters-section.style-2::after,
.instructor-single-section .instructor-wrapper .instructor-single-top::before,
.about-section.style-4::before,
.about-section::before,
.banner-section.style-2::before,
.header-section.style-7.header-fixed .header-top,
.course-item.style-4 .course-inner .course-thumb .course-category .course-cate a::before {
    display: none
}

footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li p a,
.comments .comment-list .comment .com-content .com-title .com-title-meta span,
.authors .author-content span,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::before,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li span,
.course-video .course-video-content .accordion-item .accordion-header span,
.pageheader-section.style-2 .pageheader-content .phs-thumb .course-reiew,
.pageheader-section.style-2 .pageheader-content .phs-thumb span,
.header-bar span,
.header-top-area .social-icons li a,
.feature-item .feature-inner .feature-content .lab-btn-text span,
.count-item .count-inner .count-icon i,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-3 .course-inner .course-thumb .course-info li span,
.scrollToTop i,
.lab-btn,
.section-header .subtitle,
a {
    display: inline-block
}

.widget.widget-post ul.widget-wrapper li .post-content a h6,
.post-item.style-2 .post-inner .post-content h3,
.post-item .post-inner .post-content h4,
.course-item .course-inner .course-content h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis
}

.post-item.style-2 .post-inner .post-content a>h2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis
}

.news-letter .section-wrapper .news-form form .nf-list input[type=submit],
.news-letter .section-wrapper .news-title h3,
.footer-top .footer-item.our-address .footer-inner .footer-content .content ul li span,
.footer-top .footer-item.our-address .footer-inner .footer-content .content ul li i,
.footer-top .footer-item.our-address .footer-inner .footer-content .content p,
.footer-top .footer-item .footer-inner .footer-content .content ul li a,
.footer-top .footer-item .footer-inner .footer-content .title h4,
.widget.widget-tags ul.widget-wrapper li a.active,
.widget.widget-tags ul.widget-wrapper li a:hover,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a:hover,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a .active,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote cite a,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote p,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote::before,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote,
.course-side-cetagory .csc-title h5,
.course-side-detail .csd-content .sidebar-social .ss-content ul li a,
.course-side-detail .csd-title h4,
.course-side-detail .csd-title p,
.shop-single .product-details .product-thumb .pro-single-prev,
.shop-single .product-details .product-thumb .pro-single-next,
.group-select-section .group-select-left span,
.group-select-section .group-select-left i,
.skill-section .section-header .title,
.achieve-part .col:last-child .achieve-item .achieve-inner .achieve-content .lab-btn span,
.register-section .section-header .title,
.register-section .section-header p,
.feature-section.style-3 .section-wrapper .feature-register form .lab-btn span,
.newsletters-section .newsletter-area .news-mass,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-section.style-2 .section-wrapper .instructor-content .subtitle,
.instructor-section.style-2 .section-wrapper .instructor-content .title,
.instructor-section.style-2 .section-wrapper .instructor-content p,
.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-content p,
.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-content span,
.about-section.style-3 .about-left .about-left-content p,
.about-section.style-3 .about-left .about-left-content h3,
.video-section .section-wrapper h3,
.course-video .course-video-content .accordion-item .accordion-header button,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content h6,
.pageheader-section.style-2 .pageheader-content .course-category .course-offer,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate,
.banner-section.style-6 .section-wrapper .banner-content p,
.banner-section.style-6 .section-wrapper .banner-content .title,
.banner-section.style-5 .section-wrapper .banner-content .lab-btn:hover span,
.banner-section .cbs-content-list ul li.ccl-shape a,
.menu-search-form form button,
.header-section.style-6 .header-top .header-top-area .header-top-contact a,
.header-section.style-3 .header-top .header-top-area .header-top-reglog a,
.header-section.style-3 .header-top .header-top-area .header-top-contact a,
.count-item .count-inner .count-icon i,
.post-item.qute-post .post-inner .post-thumb .qute-content h4,
.post-item.style-3 .post-inner .post-content .lab-btn:hover span,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-3 .course-inner .course-content .lab-btn:hover span,
.course-item.style-3 .course-inner .course-thumb .course-info li span.price,
.course-item .course-inner .course-price,
.course-item .course-inner .course-content .course-category .course-cate a,
.social-icons li a,
.or,
.clients-slider-nav:hover,
.course-navi {
    color: #fff
}

.news-letter .section-wrapper .news-form form .nf-list input[type=email],
.widget.widget-search,
.widget,
.blog-single .section-wrapper .post-item .post-inner .post-content,
.comment-respond,
.comments,
.course-side-cetagory,
.course-side-detail .csd-content,
.authors,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input,
.shop-single .review,
.shop-single .product-details .post-content form .select-product,
.shop-single .product-details,
.shop-single .sidebar-widget .widget-search form input,
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item,
.shop-page .sidebar-widget .widget-search form input,
.skill-section .section-header .lab-btn,
.register-section .section-wrapper,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.date li,
.event-section.style-2 .section-wrapper .event-navi-item,
.instructor-single-section .instructor-wrapper .instructor-single-bottom,
.instructor-single-section .instructor-wrapper .instructor-single-top,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi,
.instructor-section.style-3 .section-wrapper .instructor-bottom,
.instructor-section.style-2 .section-wrapper .instructor-content .lab-btn,
.offer-section .section-wrapper,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-thumb,
.course-video .course-video-content .accordion-item .accordion-collapse .video-item-list li:nth-child(even),
.course-section.style-3 .section-header,
.banner-section.style-7 .section-wrapper .banner-bottom,
.banner-section.style-4 .banner-content ul li,
.banner-section.style-4 .banner-content form input,
.banner-section.style-4 .banner-content form select,
.menu-search-form form input,
.header-wrapper .menu-area .login,
.header-section.style-6 .header-bottom .header-wrapper,
.header-section.style-3 .header-bottom,
.header-section.style-3 .header-top .header-top-area .header-top-contact a.lab-btn,
.header-section.style-2 .logo-search-acte .header-search form,
.feature-item .feature-inner,
.client-item .client-inner,
.event-item.style-2,
.contact-item,
.skill-item .skill-inner,
.achieve-item .achieve-inner,
.post-item .post-inner,
.stu-feed-item .stu-feed-inner,
.instructor-item,
.course-item.style-5 .course-inner,
.course-item.style-4 .course-inner,
.course-item.style-3 .course-inner .course-content .lab-btn,
.course-item.style-3 .course-inner .course-thumb .course-info li span,
.course-item .course-inner,
.category-item .category-inner,
.account-wrapper,
.video-button::before,
.video-button::after,
.video-button,
.clients-slider-nav {
    background-color: #fff
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.office-address li,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .content ul li a,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title h4,
.widget.letest-product .widget-wrapper ul li .product-content h6 a,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a,
.widget.shop-widget .widget-wrapper .shop-menu li a,
.widget.widget-archive .widget-wrapper li a,
.widget.widget-category .widget-wrapper li a,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a,
.comments .comment-list .comment .com-content .com-title .reply .comment-reply-link,
.comments .comment-list .comment .com-content .com-title .com-title-meta h6 a,
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.title-border,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input,
.shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-content a,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on a,
.shop-page .modal .modal-dialog .modal-content button.close:hover,
.shop-page .shop-product-wrap .product-item .product-content h5 a,
.shop-page .shop-product-wrap .product-list-item .product-content h5 a,
.shop-page .shop-title .product-view-mode a,
.shop-page .shop-title p,
.achieve-part .col .achieve-item .achieve-inner .achieve-content .lab-btn span,
.app-section .section-header .lab-btn span,
.register-section .section-wrapper .register-form .lab-btn span,
.clients-section.style-3 .section-wrapper .clients-slider-nav:hover,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb .quote,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.feature-section.style-2 .section-wrapper .feature-item .feature-inner:hover .feature-icon,
.newsletters-section.style-2 .newsletter-area .section-wrapper .newsletter-form button span,
.event-section.style-2 .section-wrapper .event-navi-item,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi,
.offer-section .section-wrapper .lab-btn span,
.contact-info-section .section-wrapper .cinfo-or,
.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-content p,
.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-content span,
.about-section.style-4 .about-item .about-content .details li p,
.course-section.style-4 .section-header .subtitle,
.course-section.style-3 .section-header .course-filter-group ul li.active,
.course-section.style-3 .section-header .course-filter-group ul li:hover,
.course-section.style-3 .section-header .course-filter-group ul li,
.category-section.style-4 .section-wrapper .lab-btn span,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content .cate-icon,
.banner-section.style-7 .section-wrapper .banner-top .banner-content .lab-btn span,
.banner-section.style-7 .section-wrapper .banner-top .banner-content p,
.banner-section.style-5 .section-wrapper .banner-content .lab-btn span,
.banner-section.style-5 .section-wrapper .banner-content .subtitle,
.banner-section.style-4 .banner-content ul li,
.banner-section.style-4 .banner-content form select,
.banner-section.style-4 .banner-content h2,
.menu-search-form form input,
.header-section.style-7 .header-bottom .header-wrapper .menu-area .signup,
.header-section.style-5 .header-bottom .menu>ul>li ul li a:hover,
.header-section.style-5 .header-bottom .menu>ul>li ul li a.active,
.header-section.style-4 .lab-btn span,
.header-section.style-4 .menu>ul li ul li a:hover,
.header-section.style-4 .menu>ul li ul li a.active,
.event-item.style-2 .event-inner .event-content .lab-btn span,
.course-item.style-5 .course-inner .course-content .course-footer .course-price,
.course-item.style-4 .course-inner .course-thumb .course-category .course-reiew .ratting i,
.course-item.style-4 .course-inner .course-thumb .course-category .course-cate a,
.course-item.style-3 .course-inner .course-thumb .course-info li span.course-name,
.scrollToTop.yellow-color i,
.yellow-color-section .post-item.style-3 .post-inner .post-content .lab-btn span,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-content .lab-btn span,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-thumb ul li .price,
.cart-plus-minus .qtybutton,
.cart-plus-minus .cart-plus-minus-box,
.video-button:hover i,
.clients-slider-nav,
a:hover,
a:focus,
a:active,
a:visited,
a {
    color: #fff
}

footer.style-2 .footer-top.dark-view,
.skill-section,
.feature-section.style-3 .section-wrapper .feature-register form .lab-btn,
.instructor-section.style-2,
.banner-section.style-5 .section-wrapper .banner-content .lab-btn:hover,
.header-section.style-7 .header-bar span,
.header-section.style-7 .menu-item-has-children>a::after,
.header-section.style-7 .menu-item-has-children>a::before,
.header-section.style-4 .menu-item-has-children>a::after,
.header-section.style-4 .menu-item-has-children>a::before {
    background: #f2f7f5
}


footer.style-2 .footer-bottom .section-wrapper .footer-bottom-list a:hover,
footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li p a,
footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.office-address li i,

.widget.letest-product .widget-wrapper ul li:hover .product-content h6 a,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a.active,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a:hover,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li.open>a,
.widget.shop-widget .widget-wrapper .shop-menu li.open>a,
.widget.widget-post ul.widget-wrapper li .post-content a h6:hover,
.widget.widget-archive .widget-wrapper li a.active,
.widget.widget-archive .widget-wrapper li a:hover,
.widget.widget-category .widget-wrapper li a.active,
.widget.widget-category .widget-wrapper li a:hover,
.comments .comment-list .comment .com-content .com-title .ratting,
.comments .comment-list .comment .com-content .com-title .reply .comment-reply-link:hover,
.comments .comment-list .comment .com-content .com-title .com-title-meta h6 a:hover,
.navigations-part .left .next:hover,
.navigations-part .left .next .active,
.navigations-part .left .prev:hover,
.navigations-part .left .prev .active,
.navigations-part .right .next:hover,
.navigations-part .right .next .active,
.navigations-part .right .prev:hover,
.navigations-part .right .prev .active,
.navigations-part .left .title:hover,
.navigations-part .left .title .active,
.navigations-part .right .title:hover,
.navigations-part .right .title .active,
.course-side-cetagory .csc-content .csdc-lists ul li:hover .csdc-left a,
.course-side-cetagory .csc-content .csdc-lists ul li:hover .csdc-right,
.form-message.error,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li .pull-right,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input[type=submit]:hover,
.shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-content a:hover,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on a:hover,
.shop-page .modal .modal-dialog .modal-content button.close,
.shop-page .shop-product-wrap .product-item .product-content h5 a:hover,
.shop-page .shop-product-wrap .product-list-item .product-content h5 a:hover,
.shop-page .shop-title .product-view-mode a.active,
.shop-page .section-header h4 span,
.date li .count-title,
.date li p,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .ratting i,
.instructor-section .section-wrapper .footer-btn p a,
.course-single-section .course-item .course-inner .course-content ul li i,
.pageheader-section.style-2 .pageheader-content .phs-thumb .course-reiew .ratting,
.header-wrapper .menu-area .ellepsis-bar i,
.header-section.style-3 .header-top .header-top-area .header-top-contact a.lab-btn span,
.feature-item .feature-inner:hover .feature-content a h5,
.event-item .event-inner .event-content .event-content-info ul li i,
.event-item .event-inner:hover .event-content .event-content-info h3,
.event-item .event-inner:hover .event-content .event-content-info h5,
.post-item.style-3 .post-inner .post-content .lab-btn span,
.post-item.style-2 .post-inner .post-content h2.opps,
.post-item.style-2 .post-inner:hover .post-content a>h2,
.post-item.style-2 .post-inner:hover .post-content h3,
.post-item .post-inner .post-content .meta-post ul li a:hover,
.post-item .post-inner .post-content .meta-post ul li i,
.post-item .post-inner:hover .post-content h4,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-right .ratting i,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content h6:hover,
.instructor-item .instructor-inner .instructor-content .ratting i,
.instructor-item:hover .instructor-inner .instructor-content h4,
.course-item.style-3 .course-inner .course-content .lab-btn span,
.course-item.style-3 .course-inner .course-thumb .course-info li span.ratting,
.course-item .course-inner .course-content .course-footer .course-author .ca-name:hover,
.course-item .course-inner .course-content .course-details .couse-count i,
.course-item .course-inner .course-content .course-details .couse-topic i,
.course-item .course-inner .course-content .course-category .course-reiew .ratting i,
.course-item .course-inner:hover .course-content h4,
.category-item .category-inner .category-content h4:hover,
.category-item .category-inner .category-content h6:hover,
.category-item .category-inner:hover .category-content h6,
.account-form .form-group a:hover,
.video-button i,
.course-showing-part .course-showing-part-right .select-item .select-icon,
.course-showing-part .course-showing-part-right .select-item select,
.theme-color {
    color: #17703b
}

.news-letter .section-wrapper .news-form form .nf-list::after,
.form-message.success,
.skill-section .section-header .lab-btn span {
    color: #17703b
}

.widget.widget-tags ul.widget-wrapper li a.active,
.widget.widget-tags ul.widget-wrapper li a:hover,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a:hover,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a .active,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote,
.course-side-cetagory .csc-title,
.course-side-detail .csd-title,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping button,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select .select-icon,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input[type=submit],
.shop-cart .section-wrapper .cart-top table thead tr,
.shop-single .review .review-content .review-showing .client-review .review-form form button,
.shop-single .review .review-content .review-showing .client-review .review-form .review-title h5::before,
.shop-single .review .review-nav li.active,
.shop-single .product-details .post-content form .lab-btn,
.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link a,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link a,
.group-select-section,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::before,
.feature-section.style-3 .section-wrapper .feature-register form .lab-btn:hover,
.newsletters-section .newsletter-area .news-mass::after,
.newsletters-section .newsletter-area .news-mass::before,
.newsletters-section .newsletter-area .news-mass,
.workshop-join .section-wrapper .workshop-title h5,
.about-section.style-3 .about-left .about-left-content,
.pageheader-section.style-2 .pageheader-content .course-category .course-offer,
.banner-section .cbs-content-list ul li.ccl-shape a::after,
.banner-section .cbs-content-list ul li.ccl-shape a,
.menu-search-form form button,
.header-bar span,
.header-wrapper .menu-area .signup,
.header-section.style-3 .header-top,
.feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-item .feature-inner .feature-content .lab-btn-text span::before,
.feature-item .feature-inner .feature-content .lab-btn-text span,
.count-item .count-inner .count-icon i,
.post-item.style-3 .post-inner .post-content .lab-btn:hover,
.post-item .post-inner .post-footer .pf-right .comment-count,
.course-item.style-3 .course-inner .course-content .lab-btn:hover,
.course-item.style-3 .course-inner .course-thumb .course-info li span.price,
.course-item .course-inner .course-price,
.course-item .course-inner .course-content .course-category .course-cate a,
.scrollToTop i,
.cart-plus-minus .qtybutton:hover,
.social-icons li a,
.or,
.clients-slider-nav:hover,
.course-navi {
    background: #17703b
}

.news-letter .section-wrapper .news-form form .nf-list input[type=submit],
.footer-top .footer-item .footer-inner .footer-content .title h4::after,
.course-side-detail .csd-content .course-enroll .lab-btn,
.skill-section::before,
.achieve-part .col:last-child .achieve-item .achieve-inner .achieve-content .lab-btn,
.achievement-section.style-3 .counter-part .col:nth-child(2) .count-item .count-inner .count-icon i,
.instructor-section.style-2 .section-wrapper .count-item:nth-child(3n+2) .count-inner .count-icon i,
.about-section.style-4 .about-item .counter-part .count-item:nth-child(3n+2) .count-inner .count-icon i,
.course-video .course-video-content .accordion-item .accordion-header button,
.pageheader-section.style-2 .pageheader-content .course-category .course-cate,
.banner-section .cbs-content-list ul li.shape-1 a::after,
.post-item.qute-post .post-inner .post-thumb,
.course-navi:hover,
.course-navi.active {
    background: #17703b
}

.widget.widget-search .search-wrapper button,
.widget.widget-search .search-wrapper input,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea,
.newsletters-section .newsletter-area::after {
    background: #ecf0f3
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content p,
.widget.widget-tags ul.widget-wrapper li a,
.comment-respond .add-comment .comment-form input[type=text],
.comments .comment-list .comment .com-content .com-title .com-title-meta span,
.navigations-part .left .title,
.navigations-part .right .title,
.choose-section .section-header .subtitle,
.instructor-section.style-3 .section-header .subtitle,
.offer-section .section-wrapper .date li p,
.banner-section.style-4 .banner-content form button,
.banner-section.style-4 .banner-content form input,
.course-item.style-5 .course-inner .course-content .course-footer .course-price del,
.account-form .form-group a,
.account-form .form-group .checkgroup label,
.section-header .subtitle.desc-color,
p {
    color: #353535
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::after,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::before {
    background: #555
}

footer.style-2 .footer-bottom,
.feature-section.style-3,
.section-bg-ash {
    background: #f9f9f9
}

.news-letter .section-wrapper .news-form form .nf-list::after,
.news-letter .section-wrapper,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media,
.course-side-detail .csd-content .sidebar-social .ss-content ul li a,
.form-message,
.shop-cart .section-wrapper .cart-top table thead tr th:nth-child(3),
.register-section .section-wrapper,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb .quote,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.feature-section.style-3 .section-wrapper .feature-register,
.feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-icon,
.newsletters-section.style-2 .newsletter-area .section-wrapper,
.newsletters-section .newsletter-area .news-mass,
.event-section.style-2 .section-wrapper .event-navi-item,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi,
.offer-section .section-wrapper,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner,
.contact-info-section .section-wrapper .cinfo-or,
.about-section.style-3 .about-left .about-left-content,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content .cate-icon,
.header-top-area .social-icons li a,
.client-item .client-inner,
.event-item.style-2 .event-inner .event-content,
.event-item .event-inner .event-content .event-date-info .edi-box,
.contact-item .contact-thumb,
.count-item .count-inner .count-icon i,
.count-item .count-inner,
.post-item.qute-post .post-inner .post-thumb .qute-content,
.post-item .post-inner .post-footer .pf-right .comment-count,
.instructor-item .instructor-inner,
.course-item .course-inner .course-price,
.scrollToTop i,
.social-icons li a,
.account-wrapper,
.video-button,
.clients-slider-nav,
.course-navi {
    text-align: center
}

footer.style-2 .footer-bottom .section-wrapper p,
.navigations-part .left,
.instructor-section.style-2 .section-wrapper .count-item .count-inner,
.about-section.style-4 .about-item .counter-part .count-item .count-inner,
.instructor-item.style-2 .instructor-inner {
    text-align: left
}

.blog-single .section-wrapper .post-item .post-inner .post-content blockquote cite,
.navigations-part .right {
    text-align: right
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a,
.widget .widget-header .title,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .title,
.account-wrapper .title {
    text-transform: capitalize
}

.comment-respond .add-comment .comment-form .lab-btn,
.four-zero-section .four-zero-content .title,
.or {
    text-transform: uppercase
}

.comments .comment-list .comment .comment-list .comment {
    list-style: none
}

.news-letter .section-wrapper .news-title h3,
.widget.letest-product .widget-wrapper ul li .product-content h6,
.widget.letest-product .widget-wrapper ul,
.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.widget.shop-widget .widget-wrapper .shop-menu,
.widget.widget-post ul.widget-wrapper li .post-content p,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags,
.comments .comment-list .comment .com-content .com-title .com-title-meta h6,
.comments .comment-list,
.navigations-part .right,
.navigations-part .left,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul,
.shop-single .review .review-content .description .post-item .post-content ul,
.shop-single .review .review-content .review-showing .client-review .review-form form .rating ul,
.shop-single .review .review-content .review-showing .content,
.shop-single .review .review-nav,
.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .cart-button .cart-plus-minus,
.shop-page .shop-product-wrap .product-item .product-content h5,
.shop-page .shop-product-wrap .product-list-item .product-content h5,
.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item p,
.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-icon,
.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-icon,
.header-top-area .social-icons,
.course-item .course-inner .course-content .course-footer .course-author,
.cart-plus-minus .qtybutton,
.cart-plus-minus .cart-plus-minus-box,
.account-form .form-group .checkgroup label {
    margin: 0
}

.news-letter .section-wrapper .news-form form .nf-list input,
.footer-top .footer-item.our-address .footer-inner .footer-content .content p,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title,
.widget.letest-product .widget-wrapper ul li .product-content p,
.widget .widget-wrapper,
.widget:last-child,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote p,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent label,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent input,
.comments .comment-list .comment .com-content p,
.form-message,
.contact-section .contact-form .form-group:last-child,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li .pull-right,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input,
.shop-single .review .review-content .review-showing .content li .post-content .entry-content p,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on p,
.shop-single .product-details .post-content form .discount-code input,
.shop-page .shop-title p,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-content .client-info .client-name,
.date li .count-title,
.date li p,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item .instructor-inner .instructor-content h5,
.instructor-section .section-wrapper .footer-btn p,
.about-section.style-3 .about-left .about-left-content p,
.about-section.style-3 .about-left .about-left-content h3,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content h6,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-thumb,
.header-top-area .social-icons li p,
.client-item .client-inner .client-content .client-info .client-name,
.event-item .event-inner .event-content .event-date-info .edi-box p,
.contact-item .contact-content p,
.skill-item .skill-inner .skill-content p,
.count-item .count-inner .count-content p,
.post-item.qute-post .post-inner .post-thumb .qute-content h4,
.stu-feed-item .stu-feed-inner .stu-feed-bottom p,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content h6,
.stu-feed-item:last-child .stu-feed-inner,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-showing-part .course-showing-part-left p {
    margin-bottom: 0px
}

.blog-single .section-wrapper .post-item .post-inner .post-content h2,
.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev,
.authors .author-content span,
.shop-single .product-details .post-content p.rating,
.shop-single .product-details .post-content h6,
.shop-page .paginations,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .ratting,
.instructor-section.style-2 .section-wrapper .instructor-content .subtitle,
.course-single-section .course-item .course-inner .course-content ul,
.pageheader-section.style-2 .pageheader-content .course-category,
.contact-item {
    margin-bottom: 10px
}

.post-item.style-3 .post-inner .post-content p,
.post-item.style-2 .post-inner .post-content p,
.course-item.style-3 .course-inner .course-content .course-details {
    margin-bottom: 25px
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.office-address,
.newsletter-form button,
.pageheader-section.style-2 .pageheader-content .phs-thumb {
    margin-top: 10px
}

.instructor-section .section-wrapper .footer-btn p a {
    margin-left: 5px
}

.feature-item .feature-inner .feature-content .lab-btn-text:hover span,
.course-item .course-inner .course-content .course-category .course-reiew .ratting-count {
    margin-left: 10px
}

footer.style-2 .footer-bottom .section-wrapper .footer-bottom-list a:last-child,
.widget.shop-widget .widget-wrapper .shop-menu li.menu-item-has-children,
.comments .comment-list .comment .comment-list,
.course-section.style-2 .section-header .course-navigations .course-navi:last-child,
.header-section.style-6 .header-top .header-top-area .header-top-contact a:last-child,
.event-item .event-inner .event-content .event-content-info ul li:last-child,
.post-item .post-inner .post-content .meta-post ul li:last-child,
.course-item.style-3 .course-inner .course-content .course-details div:last-child {
    margin-right: 0
}

.widget.widget-archive .widget-wrapper li a i,
.widget.widget-category .widget-wrapper li a i,
.csdc-lists ul li .csdc-left i,
.course-side-detail .csd-title .csdt-right p i,
.group-select-section .group-select-left i,
.choose-section .section-header ul li i,
.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-content span,
.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-content span,
.course-single-section .course-item .course-inner .course-content ul li i,
.pageheader-section.style-2 .pageheader-content .phs-thumb .course-reiew .ratting,
.banner-section.style-4 .banner-content ul li i,
.header-top-area .left li i,
.event-item .event-inner .event-content .event-content-info ul li i,
.post-item .post-inner .post-content .meta-post ul li i,
.instructor-item.style-2 .instructor-inner .instructor-content .ins-info li i,
.instructor-item .instructor-footer ul li i,
.course-item .course-inner .course-content .course-footer .course-author img,
.course-item .course-inner .course-content .course-details .couse-count i,
.course-item .course-inner .course-content .course-details .couse-topic i,
.social-icons li {
    margin-right: 5px
}

.footer-top .footer-item.our-address .footer-inner .footer-content .content ul li i,
.footer-top .footer-item .footer-inner .footer-content .content ul li a i,
footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li i,
footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.office-address li i,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent input,
.course-section.style-2 .section-header .course-navigations .course-navi,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content .cate-icon,
.pageheader-section.style-2 .pageheader-content .phs-thumb img,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-thumb,
.course-item.style-5 .course-inner .course-content .course-footer .course-price del {
    margin-right: 10px
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.office-address,
.widget .widget-header .title,
.comments .comment-list .comment .com-thumb,
.four-zero-section .four-zero-content .title,
.four-zero-section .four-zero-content img,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li,
.shop-cart .section-wrapper .cart-bottom,
.shop-single .review .review-content .review-showing .content li .post-thumb,
.shop-single .review,
.choose-section .section-header .title,
.feature-section.style-3 .section-wrapper .feature-register form input,
.feature-section.style-3 .section-wrapper .feature-register form select,
.newsletter-form input,
.workshop-join .workshop-join-form form .input-area input,
.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item .skill-thumb,
.instructor-section.style-2 .section-wrapper .instructor-content .title,
.offer-section .section-header .title,
.course-video .course-video-title,
.post-item .post-inner .post-content .meta-post,
.course-item.style-4 .course-inner .course-content h5,
.category-item .category-inner .category-thumb,
.account-form .form-group,
p {
    margin-bottom: 15px
}

.shop-single .review .review-content .review-showing .client-review .review-form form button,
.post-item.qute-post .post-inner .post-thumb .qute-content h4 {
    margin-top: 15px
}

.course-item.style-5 .course-inner .course-content .course-footer .course-price {
    margin-left: 15px
}

footer.style-2 .footer-bottom .section-wrapper .footer-bottom-list a,
.date li,
.pageheader-section.style-2 .pageheader-content .phs-thumb span,
.header-section.style-6 .header-top .header-top-area .header-top-contact a,
.event-item .event-inner .event-content .event-content-info ul li,
.skill-item .skill-inner .skill-thumb,
.post-item .post-inner .post-content .meta-post ul li,
.course-item.style-3 .course-inner .course-content .course-details div,
.account-form .form-group .checkgroup input[type=checkbox] {
    margin-right: 15px
}

.blog-single .section-wrapper .post-item .post-inner .post-content p,
.blog-single .section-wrapper .post-item .post-inner .post-content img,
.blog-single .section-wrapper .post-item .post-inner .post-content iframe,
.comment-respond .add-comment .comment-form input,
.comment-respond .add-comment .comment-form textarea,
.authors .author-content p,
.register-section .section-wrapper .register-form input,
.feature-item .feature-inner .feature-thumb,
.event-item.style-2 .event-inner .event-content ul,
.event-item.style-2 .event-inner .event-content>span,
.achieve-item .achieve-inner .achieve-thumb,
.instructor-item .instructor-inner .instructor-thumb,
.course-item.style-5 .course-inner .course-content .course-cate,
.account-wrapper .title,
.course-showing-part {
    margin-bottom: 20px
}

.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-content .client-info {
    margin-top: 20px
}

.header-wrapper .menu-area .ellepsis-bar {
    margin-left: 20px
}

.news-letter .section-wrapper .news-form form .nf-list input,
.footer-top .footer-item.our-address .footer-inner .footer-content .content p,
footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title,
.widget.letest-product .widget-wrapper ul li .product-content p,
.widget .widget-wrapper,
.widget:last-child,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media,
.blog-single .section-wrapper .post-item .post-inner .post-content blockquote p,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent label,
.comment-respond .add-comment .comment-form p.comment-form-cookies-consent input,
.comments .comment-list .comment .com-content p,
.form-message,
.contact-section .contact-form .form-group:last-child,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li .pull-right,
.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input,
.shop-single .review .review-content .review-showing .content li .post-content .entry-content p,
.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on p,
.shop-single .product-details .post-content form .discount-code input,
.shop-page .shop-title p,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-content .client-info .client-name,
.date li .count-title,
.date li p,
.workshop-join .section-wrapper .workshop-title h5,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item .instructor-inner .instructor-content h5,
.instructor-section .section-wrapper .footer-btn p,
.about-section.style-3 .about-left .about-left-content p,
.about-section.style-3 .about-left .about-left-content h3,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content h6,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-thumb,
.header-top-area .social-icons li p,
.client-item .client-inner .client-content .client-info .client-name,
.event-item .event-inner .event-content .event-date-info .edi-box p,
.contact-item .contact-content p,
.skill-item .skill-inner .skill-content p,
.count-item .count-inner .count-content p,
.post-item.qute-post .post-inner .post-thumb .qute-content h4,
.stu-feed-item .stu-feed-inner .stu-feed-bottom p,
.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content h6,
.stu-feed-item:last-child .stu-feed-inner,
.course-item.style-4 .course-inner .course-thumb .course-category,
.course-showing-part .course-showing-part-left p {
    margin-bottom: 0px
}

.widget.widget-post ul.widget-wrapper li .post-content a h6,
.comments .comment-list .comment .com-content .com-title .com-title-meta span,
.shop-single .product-details .post-content h4,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content p.ins-dege,
.course-video .course-video-content .accordion-item .accordion-header button,
.contact-item .contact-content .title,
.skill-item .skill-inner .skill-content h5,
.post-item .post-inner .post-content p,
.instructor-item.style-2 .instructor-inner .instructor-content h4,
.instructor-item .instructor-inner .instructor-content p,
.instructor-item .instructor-inner .instructor-content h4,
.course-item.style-5 .course-inner .course-content h5 {
    margin-bottom: 5px
}

.footer-top .footer-item .footer-inner .footer-content .title,
.widget,
.course-side-detail,
.map-area,
.four-zero-section .four-zero-content p,
.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview h3,
.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping h3,
.shop-single .review .review-content .review-showing .client-review .review-form form input,
.shop-single .product-details .post-content p,
.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item,
.shop-page .shop-title,
.skill-section .section-header .title,
.choose-section .section-header p,
.register-section .section-wrapper h4,
.feature-section.style-3 .section-wrapper .feature-register h3,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb,
.instructor-section.style-2 .section-wrapper .instructor-content p,
.offer-section .section-wrapper .date,
.about-section.style-4 .about-item .about-content p,
.banner-section.style-5 .section-wrapper .banner-content p,
.banner-section.style-4 .banner-content form,
.banner-section.style-4 .banner-content h2,
.event-item.style-2 .event-inner .event-content .lab-btn,
.event-item.style-2 .event-inner .event-content h5,
.account-form .form-group button {
    margin-bottom: 30px
}

.comments,
.form-message.success,
.form-message.error,
.instructor-section .section-wrapper .footer-btn,
.client-item .client-inner .client-content .client-info,
.account-form .form-group button {
    margin-top: 30px
}

.header-bar {
    margin-left: 30px
}

.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-icon,
.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-icon {
    margin-right: 30px
}

.widget.letest-product,
.widget.shop-widget,
.widget.recipe-categori,
.widget.widget-tags ul.widget-wrapper li,
.widget.widget-tags,
.widget.widget-instagram ul.widget-wrapper li,
.widget.widget-instagram,
.widget.widget-post ul.widget-wrapper,
.widget.widget-post,
.widget.widget-archive .widget-wrapper li,
.widget.widget-category .widget-wrapper li,
.widget.widget-search .search-wrapper button,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li,
.newsletters-section.style-2 .newsletter-area .section-wrapper,
.newsletters-section.style-2 .newsletter-area .section-header,
.event-section .section-wrapper .event-right .event-item .event-inner .event-content,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li,
.course-single-section .course-item .course-inner .course-content,
.course-single-section .course-item .course-inner,
.category-section.style-4 .section-wrapper .category-item .category-inner,
.pageheader-section.style-2,
.banner-section.style-4 .banner-content form button,
.header-top-area .social-icons li,
.event-item.style-2 .event-inner .event-content,
.post-item.style-3 .post-inner .post-content,
.post-item.style-3 .post-inner,
.post-item.style-2 .post-inner,
.course-item.style-5 .course-inner,
.course-item.style-4 .course-inner .course-thumb .course-category .course-cate a,
.course-item.style-4 .course-inner,
.course-item.style-3 .course-inner,
.video-button {
    padding: 0
}

.blog-single .section-wrapper .post-item .post-inner .post-content blockquote {
    padding: 25px
}

.course-side-detail .csd-content,
.banner-section.style-7 .section-wrapper .banner-bottom,
input,
textarea,
select {
    padding: 10px
}

.comment-respond .add-comment .comment-form textarea,
.course-side-cetagory .csc-content,
.clients-section.style-2 .section-wrapper .clients .client-list .client-content,
.about-section.style-3 .about-left .about-left-content,
.course-video .course-video-content .accordion-item .accordion-collapse .video-item-list li,
.course-video .course-video-content .accordion-item .accordion-header button,
.menu-search-form form input,
.header-section.style-6 .header-bottom .header-wrapper,
.skill-item .skill-inner,
.post-item .post-inner .post-content,
.post-item .post-inner,
.instructor-item .instructor-footer,
.course-item.style-2 .course-inner .course-content {
    padding: 15px
}

.comments .comment-list {
    padding: 20px
}

.event-item .event-inner .event-content,
.post-item.style-2 .post-inner .post-content,
.instructor-item.style-2 .instructor-inner,
.course-item.style-3 .course-inner .course-content {
    padding: 30px
}

.comments .comment-list .comment .comment-list .comment:last-child {
    padding-bottom: 0
}

.course-item.style-4 .course-inner .course-content .course-footer {
    padding-top: 5px
}

.header-top-area .social-icons li:first-child {
    padding-right: 10px
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.event-section .section-wrapper .event-right .event-item .event-inner .event-content,
.event-item .event-inner .event-content .event-date-info .edi-box h4 {
    padding-bottom: 15px
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu,
.course-item.style-5 .course-inner .course-content .course-footer,
.course-item .course-inner .course-content .course-footer {
    padding-top: 15px
}

.widget.letest-product .widget-wrapper ul li .product-content,
.banner-section.style-5 .section-wrapper .banner-content,
.contact-item .contact-content {
    padding-left: 15px
}

.course-item.style-5 .course-inner .course-content .course-footer .ratting {
    padding-right: 15px
}

.comments .comment-list .comment .com-content {
    padding-bottom: 20px
}

.post-item.style-3 .post-inner .post-content {
    padding-top: 20px
}

.widget.widget-archive .widget-wrapper li a.active,
.widget.widget-archive .widget-wrapper li a:hover,
.widget.widget-category .widget-wrapper li a.active,
.widget.widget-category .widget-wrapper li a:hover {
    padding-left: 30px
}

footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li i {
    color: #55acee
}

.choose-section .section-header ul li i,
.register-section .section-header .subtitle,
.feature-section.style-3 .section-wrapper .feature-item .feature-inner .feature-content .lab-btn-text:hover,
.feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-icon,
.feature-section.style-2 .section-wrapper .feature-item .feature-inner:hover .feature-content h5,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item:hover .instructor-inner .instructor-content h5,
.offer-section .section-wrapper .date li .count-title,
.offer-section .section-wrapper .title span,
.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-content h6,
.contact-info-section .section-header .title span,
.video-section .section-wrapper .video-thumb a i,
.category-section.style-4 .section-wrapper .category-item .category-inner:hover .category-content h6,
.category-section.style-4 .section-header .subtitle,
.banner-section.style-6 .section-wrapper .banner-content .subtitle,
.banner-section.style-4 .banner-content ul li i,
.banner-section.style-4 .banner-content h2 span,
.header-section.style-7 .header-bottom .header-wrapper .menu-area .login,
.header-section.style-5 .header-bottom .menu>ul>li.active>a,
.instructor-item.style-2 .instructor-inner .instructor-content .ins-info li i,
.instructor-item.style-2:hover .instructor-inner .instructor-content h4,
.course-item.style-5 .course-inner .course-content .course-footer .ratting,
.course-item.style-5 .course-inner:hover .course-content h5,
.course-item.style-4 .course-inner .course-content .course-footer .ca-name:hover,
.course-item.style-4 .course-inner .course-content .course-footer .course-price,
.course-item.style-4 .course-inner:hover .course-content h5,
.yellow-color-section .post-item.style-3 .post-inner .post-content .meta-post ul li i,
.yellow-color-section .post-item.style-3 .post-inner:hover .post-content h4,
.yellow-color-section.style-2 .footer-bottom .section-wrapper .footer-bottom-list a:hover,
.yellow-color-section.style-2 .footer-bottom .section-wrapper p a:hover,
.yellow-color-section.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li a,
.yellow-color-section.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content .office-address li i,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-content .course-details .couse-count i,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-content .course-details .couse-topic i,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-thumb ul li .ratting,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner:hover .course-content h4,
.lab-btn-text.yellow-color:hover,
.lab-btn-text.yellow-color i,
.yellow-color,
.section-header .subtitle.yellow-color {
    color: #17703b
}

.social-icons li a.facebook {
    background: #3b5998
}

.course-side-detail .csd-content .sidebar-social .ss-content ul li a.twitter,
.social-icons li a.twitter {
    background: #55acee
}

.social-icons li a.linkedin {
    background: #007bb5
}

.social-icons li a.google {
    background: #dd4b39
}

.social-icons li a.instagram {
    background: #e4405f
}

.achievement-section.style-3 .counter-part .col:nth-child(4) .count-item .count-inner .count-icon::after,
.achievement-section.style-3 .counter-part .col:nth-child(4) .count-item .count-inner .count-icon::before,
.achievement-section.style-3 .counter-part .col:nth-child(4) .count-item .count-inner .count-icon i,
.social-icons li a.behance {
    background: #1769ff
}

.achievement-section.style-3 .counter-part .col:nth-child(3) .count-item .count-inner .count-icon::after,
.achievement-section.style-3 .counter-part .col:nth-child(3) .count-item .count-inner .count-icon::before,
.achievement-section.style-3 .counter-part .col:nth-child(3) .count-item .count-inner .count-icon i,
.social-icons li a.dribbble {
    background: #ea4c89
}

.course-side-detail .csd-content .sidebar-social .ss-content ul li a.rss,
.app-section .section-header .lab-btn:hover,
.register-section .section-wrapper .register-form .lab-btn,
.clients-section.style-3 .section-wrapper .clients-slider-nav:hover,
.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb .quote,
.feature-section.style-3 .section-wrapper .feature-register,
.feature-section.style-3 .section-wrapper .feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-section.style-3 .section-wrapper .feature-item .feature-inner .feature-content .lab-btn-text span::before,
.feature-section.style-3 .section-wrapper .feature-item .feature-inner .feature-content .lab-btn-text span,
.feature-section.style-2 .section-wrapper .feature-item .feature-inner:hover .feature-icon,
.newsletters-section.style-2 .newsletter-area .section-wrapper .newsletter-form button,
.event-section.style-2 .section-wrapper .event-navi-item:hover,
.event-section.style-2 .section-wrapper .event-navi-item.active,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi.active,
.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi:hover,
.instructor-section.style-2 .section-wrapper .count-item:nth-child(3n+3) .count-inner .count-icon i,
.instructor-section.style-2::after,
.offer-section .section-wrapper .lab-btn,
.contact-info-section .section-wrapper .cinfo-or,
.about-section.style-4 .about-item .counter-part .count-item:nth-child(3n+3) .count-inner .count-icon i,
.course-section.style-4,
.course-section.style-3 .section-header .course-filter-group ul li.active,
.course-section.style-3 .section-header .course-filter-group ul li:hover,
.category-section.style-4 .section-wrapper .lab-btn:hover,
.category-section.style-4 .section-wrapper .category-item .category-inner .category-content .cate-icon,
.banner-section.style-7 .section-wrapper .banner-top .banner-content .lab-btn:hover,
.header-section.style-7 .header-bottom .header-wrapper .menu-area .signup,
.header-section.style-5 .header-bottom .lab-btn,
.header-section.style-5 .header-bottom .menu>ul>li ul li a:hover,
.header-section.style-5 .header-bottom .menu>ul>li ul li a.active,
.header-section.style-4 .lab-btn,
.header-section.style-4 .menu>ul li ul li a:hover,
.header-section.style-4 .menu>ul li ul li a.active,
.event-item.style-2 .event-inner .event-content .lab-btn,
.event-item.style-2 .event-inner .event-content h5,
.scrollToTop.yellow-color i,
.yellow-color-section .post-item.style-3 .post-inner .post-content .lab-btn:hover,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-content .lab-btn,
.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-thumb ul li .price,
.yellow-color-section .section-header .course-navi {
    background: #17703b
}

.social-icons li a.pinterest {
    background: #bd081c
}

.course-side-detail .csd-content .sidebar-social .ss-content ul li a.vimeo,
.social-icons li a.vimeo {
    background: #3b5998
}

@keyframes angle-move {
    0%,
    100% {
        transform: translateY(0) rotate(-6deg)
    }
    50% {
        transform: translateY(-20px) rotate(8deg)
    }
}

@keyframes up-down {
    0%,
    100% {
        transform: translateY(-20px) translateX(15px)
    }
    50% {
        transform: translateY(0)
    }
}

@keyframes up_down_2 {
    0%,
    100% {
        transform: translateY(15px) translateX(15px)
    }
    50% {
        transform: translateY(0)
    }
}

@keyframes up_down_3 {
    0%,
    100% {
        transform: translateY(-10px) translateX(1px)
    }
    50% {
        transform: translateY(0)
    }
}

@keyframes zoom {
    0%,
    100% {
        transform: scale(0.9) rotate(0deg)
    }
    50% {
        transform: scale(1.1) rotate(30deg)
    }
    80% {
        transform: rotate(-30deg)
    }
}

@keyframes rotate {
    100% {
        transform: rotateZ(360deg)
    }
}

@keyframes rotate-rev {
    100% {
        transform: rotateZ(-360deg) translate(-50%, -50%)
    }
}

@keyframes bounce-1 {
    0% {
        transform: translateY(0)
    }
    50% {
        transform: translateY(-25px)
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes ripple {
    100% {
        width: 200%;
        height: 200%;
        opacity: 0
    }
}

@keyframes rotate-2 {
    100% {
        transform: rotate(150deg)
    }
}

@keyframes outer-ripple {
    0% {
        transform: scale(1);
        filter: alpha(opacity=50);
        opacity: .5;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -webkit-filter: alpha(opacity=50)
    }
    80% {
        transform: scale(1.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5)
    }
    100% {
        transform: scale(2.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -ms-transform: scale(2.5);
        -o-transform: scale(2.5)
    }
}

@-webkit-keyframes outer-ripple {
    0% {
        transform: scale(1);
        filter: alpha(opacity=50);
        opacity: .5;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1)
    }
    80% {
        transform: scale(2.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -ms-transform: scale(2.5);
        -o-transform: scale(2.5)
    }
    100% {
        transform: scale(3.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(3.5);
        -moz-transform: scale(3.5);
        -ms-transform: scale(3.5);
        -o-transform: scale(3.5)
    }
}

@-moz-keyframes outer-ripple {
    0% {
        transform: scale(1);
        filter: alpha(opacity=50);
        opacity: .5;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1)
    }
    80% {
        transform: scale(2.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -ms-transform: scale(2.5);
        -o-transform: scale(2.5)
    }
    100% {
        transform: scale(3.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(3.5);
        -moz-transform: scale(3.5);
        -ms-transform: scale(3.5);
        -o-transform: scale(3.5)
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1
    }
    90% {
        opacity: .5;
        -webkit-transform: translate(-50%, -50%) scale(1.8);
        -ms-transform: translate(-50%, -50%) scale(1.8);
        transform: translate(-50%, -50%) scale(1.8)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(2);
        -ms-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2)
    }
}

@-webkit-keyframes circle-2 {
    100% {
        width: 200%;
        height: 200%;
        opacity: 0
    }
}

@keyframes circle-2 {
    100% {
        width: 200%;
        height: 200%;
        opacity: 0
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    box-shadow: none
}

html {
    scroll-behavior: auto !important
}

body {
    background: #fff;
    color: #353535 ;
font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #101115;
    font-weight: 700;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    line-height: 1.3;
    font-family: 'Inter', sans-serif;
    /* font-family: 'DM Sans', sans-serif; */
    font-weight: 800;
    text-transform: capitalize;

}


a>h1,
a>h2,
a>h3,
a>h4,
a>h5,
a>h6 {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

h1 {
    font-size: 3.75rem
}

h2 {
font-size: 42px;
}

h3 {
    font-size: 1.25rem
}

h4 {
    font-size: 1.25rem
}

h5 {
    font-size: 1.25rem;
    margin-top: -5px
}

h6 {
    font-size: 1.125rem
}

p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px
}

a {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    text-decoration: none
}

a:hover,
a:focus,
a:active,
a:visited {
    text-decoration: none;
    outline: none
}

img {
    height: auto;
    max-width: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(min-width: 576px) {
    h1 {
        font-size: 3.75rem
    }
    h2 {
        font-size: 2.5rem
    }
    h3 {
        font-size: 1.875rem
    }
    h4 {
        font-size: 1.5rem
    }
    h5 {
        font-size: 1.25rem
    }
    h6 {
        font-size: 1.125rem
    }
}

ul.lab-ul {
    margin: 0;
    padding: 0
}

ul.lab-ul li {
    list-style: none
}

input,
textarea,
select {
    outline: none;
    border: 1px solid #ecf0f3
}

button {
    border: none;
    outline: none;
    padding: 13px 15px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.padding-tb {
    padding: 70px 0
}

@media(min-width: 992px) {
    .padding-tb {
        padding: 110px 0
    }
}

.section-bg {
    background: #fff9f1
}

.section-header {
    margin-bottom: 50px
}

.section-header .subtitle {
    font-size: 1.125rem;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #17703b;
    font-weight: 500;
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px)
}
textarea {
    outline: none;
    border: 1px solid #d9d9da;
    margin-bottom: 5px;
    border-radius: 5px;
}
.lab-btn {
    padding: 10px 30px;
    background: #17703b;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 10px;
    font-weight: 700
}
a.Quote:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgb(45 127 69 / 42%);
}
.lab-btn span {
    color: #fff
}

.lab-btn:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgb(45 127 69 / 42%);
}

.lab-btn-text {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: capitalize
}

.lab-btn-text i {
    color: #17703b;
    margin-left: 5px
}

.lab-btn-text:hover {
    color: #17703b
}

.header-shadow {
    box-shadow: 0 6px 10px rgba(85, 85, 85, .1)
}

.search-icon i {
    font-weight: 700
}

.default-pagination {
    margin: -7px !important;
    margin-top: 31px !important
}

@media(min-width: 768px) {
    .default-pagination {
        margin-top: 41px !important
    }
}

.default-pagination li {
    padding: 7px
}

.default-pagination li a {
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .06);
    background: #fff;
    color: #101115
}

.default-pagination li a:hover,
.default-pagination li a.active {
    background: #17703b;
    box-shadow: none;
    color: #fff
}

.select-item select {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
   
}

.select-item .select-icon {
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.course-showing-part .course-showing-part-left p {
    font-size: 1.125rem
}

.course-showing-part .course-showing-part-right span {
    font-size: 1.125rem
}

.course-showing-part .course-showing-part-right .select-item select {
    background: transparent;
    border: none
}

.course-showing-part .course-showing-part-right .select-item .select-icon {
    right: 0
}

.course-navi {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: 700;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.clients-slider-nav {
    width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.video-button {
    z-index: 1;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(min-width: 576px) {
    .video-button {
        width: 92px;
        height: 92px;
        line-height: 88px;
        font-size: 30px;
        border: 2px solid #cfd0fe
    }
}

.video-button::before,
.video-button::after {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    opacity: .15;
    z-index: -10
}

.video-button::before {
    z-index: -10;
    -webkit-animation: inner-ripple 2000ms linear infinite;
    -moz-animation: inner-ripple 2000ms linear infinite;
    animation: inner-ripple 2000ms linear infinite
}

.video-button::after {
    z-index: -10;
    -webkit-animation: outer-ripple 2000ms linear infinite;
    -moz-animation: outer-ripple 2000ms linear infinite;
    animation: outer-ripple 2000ms linear infinite
}


.account-wrapper {
    box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, .1);
    padding: 60px 80px;
    max-width: 545px;
    margin: 0 auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

@media(max-width: 767px) {
    .account-wrapper {
        padding: 50px 40px
    }
}

@media screen and (max-width: 499px) {
    .account-wrapper {
        padding: 40px 15px
    }
}

.account-wrapper .cate {
    font-style: normal
}

.account-bottom .subtitle {
    margin-bottom: 23px
}

@media(min-width: 576px) {
    .account-bottom .subtitle {
        font-size: 20px
    }
}

.account-form .form-group input {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.account-form .form-group .checkgroup {
    flex-wrap: nowrap
}

.account-form .form-group .checkgroup input[type=checkbox] {
    width: 18px;
    height: 18px
}

@media(max-width: 575px) {
    .account-form .form-group .checkgroup input[type=checkbox] {
        width: 14px;
        height: 14px;
        margin-right: 8px
    }
}

@media(max-width: 575px) {
    .account-form .form-group .checkgroup label {
        font-size: 14px
    }
}

.account-form .form-group a {
    text-decoration: underline
}

@media(max-width: 575px) {
    .account-form .form-group a {
        font-size: 14px
    }
}

@media(max-width: 575px) {
    .account-form .form-group button {
        margin-top: 20px;
        margin-bottom: 20px
    }
}

.or {
    margin: 24px auto 30px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 500;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

.or span {
    z-index: 1
}

.or::before,
.or::after {
    top: 50%;
    left: 50%;
    background: #17703b;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: rgba(241, 97, 38, .3);
    animation: pulse 2s linear infinite;
    -webkit-animation: pulse 2s linear infinite;
    -moz-animation: pulse 2s linear infinite;
    opacity: 0
}

.or::before {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s
}

.social-icons {
    margin: -5px
}

.social-icons li a {
    height: 36px;
    width: 36px;
    line-height: 36px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

.social-icons li a:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px)
}

.cart-plus-minus {
    border: 1px solid #ecf0f3;
    width: 120px;
    margin: 0 auto;
    cursor: pointer
}

.cart-plus-minus .dec.qtybutton {
    border-right: 1px solid #ecf0f3;
    height: 40px;
    left: 0;
    top: 0
}

.cart-plus-minus .cart-plus-minus-box {
    border: medium none;
    float: left;
    font-size: 14px;
    height: 40px;
    text-align: center;
    width: 120px;
    outline: none
}

.cart-plus-minus .qtybutton {
    float: inherit;
    font-size: 14px;
    font-weight: 500;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.cart-plus-minus .qtybutton:hover {
    color: #fff
}

.cart-plus-minus .inc.qtybutton {
    border-left: 1px solid #ecf0f3;
    right: 0;
    top: 0
}

.yellow-color-section .section-wrapper .course-item.style-3 .course-inner .course-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.yellow-color-section .post-item.style-3 .post-inner .post-content .lab-btn {
    background: transparent;
    border: 2px solid #17703b
}

.yellow-color-section .post-item.style-3 .post-inner .post-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: #17703b
}

.preloader .preloader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.preloader .preloader-inner .preloader-icon {
    width: 72px;
    height: 72px;
    display: inline-block;
    padding: 0px
}

.preloader .preloader-inner .preloader-icon span {
    position: absolute;
    display: inline-block;
    width: 72px;
    height: 72px;
    border-radius: 100%;
    background: #fff;
    -webkit-animation: preloader-fx 1.6s linear infinite;
    animation: preloader-fx 1.6s linear infinite
}

.preloader .preloader-inner .preloader-icon span:last-child {
    animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s
}

@keyframes preloader-fx {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: .5
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0
    }
}

@-webkit-keyframes preloader-fx {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: .5
    }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0
    }
}

@keyframes price-ani {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: .5
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        opacity: .1
    }
}

.scrollToTop {
    position: fixed;
    bottom: -30%;
    right: 5%;
    z-index: 9
}

.scrollToTop i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 1.5rem;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.scrollToTop i:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 20px 0px rgba(241, 97, 38, .5)
}

.scrollToTop.yellow-color i:hover {
    box-shadow: 0px 5px 20px 0px rgba(255, 223, 64, .5)
}

.listActive .list,
.gridActive .grid {
    color: #17703b !important
}

.RevActive .rev,
.DescActive .desc {
    background: #17703b !important
}

.search-close {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(16, 17, 21, .5);
    cursor: crosshair;
    font-size: 20px
}

.category-item .category-inner {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 40px 30px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(min-width: 1440px) {
    .category-item .category-inner {
        padding: 40px
    }
}

.category-item .category-inner:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
}

.category-item .category-inner .category-thumb img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.category-item .category-inner .category-content h6 {
    font-weight: 500
}

.category-item .category-inner .category-content span {
    font-weight: 500;
    font-size: 1.125rem
}

.course-item .course-inner {
    padding: 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.course-item .course-inner:hover .course-thumb img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}

.course-item .course-inner .course-content {
    padding: 30px 15px 0
}

@media(min-width: 576px) {
    .course-item .course-inner .course-content .course-category {
        margin-bottom: 15px
    }
}

.course-item .course-inner .course-content .course-category .course-cate {
    margin-right: 10px
}

@media(max-width: 991px) {
    .course-item .course-inner .course-content .course-category .course-cate {
        margin-bottom: 10px
    }
}

.course-item .course-inner .course-content .course-category .course-cate a {
    padding: 3px 15px;
    padding-left: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-weight: 500
}

@media(max-width: 1439px) {
    .course-item .course-inner .course-content .course-category .course-cate a {
        font-size: small
    }
}

.course-item .course-inner .course-content .course-category .course-cate a::before {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: rgba(255, 255, 255, .5);
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.course-item .course-inner .course-content .course-category .course-reiew .ratting i {
    font-size: 1rem
}

.course-item .course-inner .course-content .course-details {
    margin: 0px 0 15px
}

.course-item .course-inner .course-content .course-details .couse-count,
.course-item .course-inner .course-content .course-details .couse-topic {
    font-size: 1.125rem
}

.course-item .course-inner .course-content .course-footer {
    border-top: 1px solid #ecf0f3
}

.course-item .course-inner .course-content .course-footer .course-author .ca-name {
    font-size: 1.125rem;
    font-weight: 500
}

.course-item .course-inner .course-price {
    width: 60px;
    height: 60px;
    line-height: 60px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-weight: 700;
    font-size: 1.25rem;
    right: 20px;
    top: -30px
}

@media(min-width: 576px) {
    .course-item.style-2 .course-inner .course-thumb {
        width: 40%
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .course-item.style-2 .course-inner .course-thumb {
        width: 100%
    }
}

.course-item.style-2 .course-inner .course-thumb .course-price {
    top: 10px;
    right: auto;
    left: 10px
}

@media(min-width: 576px) {
    .course-item.style-2 .course-inner .course-content {
        width: 60%
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .course-item.style-2 .course-inner .course-content {
        width: 100%
    }
}

.course-item.style-3 .course-inner {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.course-item.style-3 .course-inner .course-thumb .course-info {
    bottom: 10px;
    z-index: 9
}

.course-item.style-3 .course-inner .course-thumb .course-info li {
    margin: 3px
}

.course-item.style-3 .course-inner .course-thumb .course-info li span {
    padding: 5px 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.course-item.style-3 .course-inner .course-thumb .course-info li span.course-name {
    font-weight: 700
}

.course-item.style-3 .course-inner .course-thumb .course-info li span.ratting {
    font-size: small;
    line-height: 24px
}

.course-item.style-3 .course-inner .course-thumb::after {
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, #05193700, #05193747, #05193794, #051937c4, #051937db)
}

.course-item.style-3 .course-inner .course-content {
    padding-bottom: 40px
}

.course-item.style-3 .course-inner .course-content .lab-btn {
    border: 1px solid #ecf0f3
}

.course-item.style-3 .course-inner .course-content .lab-btn:hover {
    border-color: transparent
}

.course-item.style-4 .course-inner {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.course-item.style-4 .course-inner:hover .course-thumb img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}

.course-item.style-4 .course-inner .course-thumb .course-category {
    padding: 5px 15px;
    left: 0;
    bottom: 0;
    background: rgba(255, 223, 64, .85)
}

.course-item.style-4 .course-inner .course-thumb .course-category .course-cate {
    margin-right: 10px
}

@media(max-width: 991px) {
    .course-item.style-4 .course-inner .course-thumb .course-category .course-cate {
        margin-bottom: 10px
    }
}

.course-item.style-4 .course-inner .course-thumb .course-category .course-cate a {
    font-weight: 700
}

.course-item.style-4 .course-inner .course-content {
    padding: 20px 15px 5px
}

.course-item.style-4 .course-inner .course-content .course-footer .course-price {
    position: static;
    font-size: 1.25rem;
    font-weight: 500;
    width: auto;
    height: auto;
    line-height: 1;
    background: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.course-item.style-5 .course-inner .course-content {
    padding: 30px 23px 20px
}

.course-item.style-5 .course-inner .course-content .course-footer .ratting {
    font-size: 1rem;
    border-right: 1px solid #ecf0f3
}

.course-item.style-5 .course-inner .course-content .course-footer .course-price {
    position: static;
    font-size: 1.125rem;
    font-weight: 700;
    width: auto;
    height: auto;
    line-height: 1;
    background: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.course-item.style-5 .course-inner .course-content .course-footer .course-price del {
    font-weight: 500
}

.instructor-item {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.instructor-item:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
}

.instructor-item .instructor-inner {
    padding: 30px 15px 20px
}

.instructor-item .instructor-inner .instructor-thumb img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.instructor-item .instructor-inner .instructor-content .ratting i {
    font-size: 1rem
}

.instructor-item .instructor-footer {
    border-top: 1px solid #ecf0f3
}

.instructor-item.style-2 {
    box-shadow: 0 2px 4px rgba(16, 17, 21, .06)
}

@media(min-width: 576px) {
    .instructor-item.style-2 .instructor-inner .instructor-thumb {
        width: 30%;
        margin-bottom: 0
    }
}

@media(min-width: 992px) {
    .instructor-item.style-2 .instructor-inner .instructor-thumb {
        width: 50%
    }
}

.instructor-item.style-2 .instructor-inner .instructor-thumb img {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

@media(max-width: 575px) {
    .instructor-item.style-2 .instructor-inner .instructor-thumb img {
        width: 100%
    }
}

@media(min-width: 576px) {
    .instructor-item.style-2 .instructor-inner .instructor-content {
        width: 70%;
        padding-left: 30px
    }
}

@media(min-width: 992px) {
    .instructor-item.style-2 .instructor-inner .instructor-content {
        width: 50%
    }
}

.instructor-item.style-2 .instructor-inner .instructor-content .social-icons li a {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.instructor-item.style-2 .instructor-inner .instructor-content .ins-info {
    margin: 10px 0 20px
}

.stu-feed-item .stu-feed-inner {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(136, 136, 136, .1);
    padding: 20px 30px;
    margin-bottom: 20px
}

@media(min-width: 576px) {
    .stu-feed-item .stu-feed-inner {
        padding: 10px 30px
    }
}

.stu-feed-item .stu-feed-inner .stu-feed-top {
    padding: 15px 0;
    border-bottom: 1px solid #ecf0f3
}

.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-thumb img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.stu-feed-item .stu-feed-inner .stu-feed-top .sft-left .sftl-content span {
    font-size: 1.125rem
}

.stu-feed-item .stu-feed-inner .stu-feed-top .sft-right .ratting i {
    font-size: 1rem
}

.stu-feed-item .stu-feed-inner .stu-feed-bottom {
    padding: 20px 0 10px
}

.stu-feed-item .stu-feed-inner .stu-feed-bottom p {
    font-size: 1.125rem
}

.post-item .post-inner {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(16, 17, 21, .1);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    margin-bottom: 10px;
  
}

.post-item .post-inner:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
}

.post-item .post-inner .post-content {
    padding-top: 20px
}

.post-item .post-inner .post-content .meta-post ul li a {
    font-weight: 500
}

.post-item .post-inner .post-content p {
    font-size: 1.125rem
}

.post-item .post-inner .post-footer {
    margin: 0 15px;
    padding-top: 15px;
    border-top: 1px solid #ecf0f3
}

.post-item .post-inner .post-footer .pf-right i {
    font-size: 1.5rem
}

.post-item .post-inner .post-footer .pf-right .comment-count {
    width: 16px;
    height: 16px;
    line-height: 16px;
    top: -5px;
    left: 5px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-size: 12px;
    box-shadow: 0 3px 10px rgba(16, 17, 21, .1)
}

.post-item.style-2 .post-inner:hover {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px)
}

.post-item.style-2 .post-inner:hover .post-thumb a img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.post-item.style-2 .post-inner .post-content h2.not-ruselt {
    font-weight: 400
}

.post-item.style-2 .post-inner .post-content h2.opps {
    margin: 10px 0 0
}

@media(min-width: 992px) {
    .post-item.style-2 .post-inner .post-content h2.opps {
        font-size: 60px
    }
}

.post-item.style-3 .post-inner {
    box-shadow: none
}

@media(max-width: 575px) {
    .post-item.style-3 .post-inner {
        margin-bottom: 15px
    }
}

.post-item.style-3 .post-inner:hover {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px)
}

.post-item.style-3 .post-inner:hover .post-thumb a img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.post-item.style-3 .post-inner .post-content .lab-btn {
    background: transparent;
    border: 2px solid #17703b
}

.post-item.slider-post .post-inner:hover .post-thumb.post-thumb-slider .post-thumb-slider-next {
    left: 10px
}

.post-item.slider-post .post-inner:hover .post-thumb.post-thumb-slider .post-thumb-slider-prev {
    right: 10px
}

.post-item.slider-post .post-inner .post-thumb.post-thumb-slider img {
    width: 100%
}

.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-next,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    color: #101115;
    z-index: 1;
    text-align: center;
    top: 50%;
    outline: none;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-next:hover,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-next .active,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-prev:hover,
.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-prev .active {
    background: #17703b;
    color: #fff
}

.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-next {
    left: -10%
}

.post-item.slider-post .post-inner .post-thumb.post-thumb-slider .post-thumb-slider-prev {
    right: -10%
}

.post-item.qute-post .post-inner .post-thumb {
    height: 190px
}

@media(min-width: 576px) {
    .post-item.qute-post .post-inner .post-thumb {
        height: 320px
    }
}

.post-item.qute-post .post-inner .post-thumb .qute-content {
    padding: 20px
}

.post-item.qute-post .post-inner .post-thumb .qute-content h4 {
    line-height: 1.5
}

@media(max-width: 767px) {
    .post-item.qute-post .post-inner .post-thumb .qute-content h4 {
        display: none
    }
}

.count-item .count-inner {
    padding: 5px 30px
}

@media(min-width: 576px) {
    .count-item .count-inner {
        padding: 30px
    }
}

.count-item .count-inner:hover .count-icon::before {
    -webkit-animation: circle-2 1.05s infinite;
    -moz-animation: circle-2 1.05s infinite;
    -ms-animation: circle-2 1.05s infinite;
    -o-animation: circle-2 1.05s infinite;
    animation: circle-2 1.05s infinite
}

.count-item .count-inner:hover .count-icon::after {
    -webkit-animation: circle-2 1.08s infinite;
    -moz-animation: circle-2 1.08s infinite;
    -ms-animation: circle-2 1.08s infinite;
    -o-animation: circle-2 1.08s infinite;
    animation: circle-2 1.08s infinite
}

.count-item .count-inner .count-icon {
    width: 90px;
    height: 90px;
    margin: 0 auto 30px
}

.count-item .count-inner .count-icon i {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 1.875rem;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    z-index: 1
}

.count-item .count-inner .count-icon::after,
.count-item .count-inner .count-icon::before {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.count-item .count-inner .count-icon::before {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    background: rgba(241, 97, 38, .1)
}

.count-item .count-inner .count-icon::after {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    background: rgba(241, 97, 38, .2)
}

.achieve-item .achieve-inner {
    padding: 30px;
    box-shadow: 0 5px 10px rgba(16, 17, 21, .1)
}

@media(min-width: 576px) {
    .achieve-item .achieve-inner .achieve-thumb {
        width: 65%;
        position: absolute;
        bottom: 0;
        right: -60px;
        margin-bottom: 0
    }
}

@media(min-width: 576px) {
    .achieve-item .achieve-inner .achieve-content {
        width: 60%
    }
}

.achieve-item .achieve-inner .achieve-content p {
    margin-bottom: 25px
}

.skill-item .skill-inner {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

@media(min-width: 992px)and (max-width: 1199px) {
    .skill-item .skill-inner {
        text-align: center
    }
}

.skill-item .skill-inner:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(255, 255, 255, .5)
}

@media(min-width: 992px)and (max-width: 1199px) {
    .skill-item .skill-inner .skill-thumb {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .skill-item .skill-inner .skill-content {
        width: 100%
    }
}

.contact-item {
    padding: 26px 30px;
    box-shadow: 0 0px 10px rgb(136 136 136 / 10%);
    width: 80%;
    background: #17703b;
    border-radius: 10px;
    margin-bottom: 10px;
}

.contact-item .contact-thumb {
    max-width: 50px
}

.contact-item .contact-content {
    width: calc(100% - 50px)
}

@media(max-width: 575px) {
    .event-item .event-inner {
        margin-bottom: 15px
    }
}

.event-item .event-inner:hover .event-thumb img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}

.event-item .event-inner .event-thumb {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.event-item .event-inner .event-content .event-date-info {
    width: 95px
}

.event-item .event-inner .event-content .event-date-info .edi-box {
    padding: 15px 10px;
    border: 2px solid #17703b;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

@media(max-width: 575px) {
    .event-item .event-inner .event-content .event-date-info .edi-box {
        margin-bottom: 10px
    }
}

.event-item .event-inner .event-content .event-date-info .edi-box h4::after {
    width: 20px;
    height: 2px;
    border-bottom: 2px dotted #ecf0f3;
    bottom: 0;
    left: 50%;
    border-width: 5px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.event-item .event-inner .event-content .event-date-info .edi-box p {
    font-size: 1rem
}

@media(min-width: 576px) {
    .event-item .event-inner .event-content .event-content-info {
        width: calc(100% - 110px)
    }
}

.event-item .event-inner .event-content .event-content-info h3,
.event-item .event-inner .event-content .event-content-info h5 {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.event-item.style-2 .event-inner {
    border-right: 1px solid #f9f9f9
}

.event-item.style-2 .event-inner .event-thumb {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px
}

.event-item.style-2 .event-inner .event-content h5 {
    padding: 10px 0
}

.event-item.style-2 .event-inner .event-content ul li {
    padding: 5px 0
}

.event-item.style-2 .event-inner .event-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.client-item .client-inner {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    padding: 0px 30px
}

.client-item .client-inner .client-thumb {
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    border: 2px solid #17703b;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.client-item .client-inner .client-content p {
    line-height: 1.5
}

.client-item .client-inner .client-content .client-info .client-degi {
    font-size: small
}

.feature-item .feature-inner {
    padding: 50px 30px;
    box-shadow: 0 2px 4px rgba(16, 17, 21, .1);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(max-width: 575px) {
    .feature-item .feature-inner {
        text-align: center
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .feature-item .feature-inner {
        text-align: center
    }
}

.feature-item .feature-inner:hover {
    box-shadow: 0 24px 46px rgba(16, 17, 21, .12)
}

.feature-item .feature-inner .feature-thumb {
    width: 100%
}

@media(min-width: 576px) {
    .feature-item .feature-inner .feature-thumb {
        margin-right: 20px;
        margin-bottom: 0;
        width: auto
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .feature-item .feature-inner .feature-thumb {
        margin-bottom: 20px;
        width: 100%
    }
}

@media(max-width: 575px) {
    .feature-item .feature-inner .feature-content {
        width: 100%
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .feature-item .feature-inner .feature-content {
        width: 100%
    }
}

@media(min-width: 576px) {
    .feature-item .feature-inner .feature-content a h5 span {
        display: block
    }
}

.feature-item .feature-inner .feature-content .lab-btn-text:hover span {
    opacity: 1
}

.feature-item .feature-inner .feature-content .lab-btn-text span {
    width: 20px;
    height: 2px;
    margin-left: -15px;
    opacity: 0;
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.feature-item .feature-inner .feature-content .lab-btn-text span::after,
.feature-item .feature-inner .feature-content .lab-btn-text span::before {
    width: 10px;
    height: 2px;
    right: 0
}

.feature-item .feature-inner .feature-content .lab-btn-text span::after {
    transform: rotate(-45deg) translateY(4px)
}

.feature-item .feature-inner .feature-content .lab-btn-text span::before {
    transform: rotate(45deg) translateY(-4px)
}

.header-section {
    background: transparent;
    z-index: 999
}

.header-section.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    animation: fadeInDown .5s;
    -webkit-animation: fadeInDown .5s;
    -moz-animation: fadeInDown .5s;
    box-shadow: 0 0 10px rgba(16, 17, 21, .1);
    z-index: 999
}

@media(min-width: 992px) {
    .header-section.header-fixed {
        top: -48px
    }
}

@media(min-width: 1200px) {
    .header-section.header-fixed {
        top: -13px
    }
}

.header-section.header-fixed .header-bottom {
    background: #fff;
  
}

.header-section.style-2 .header-wrapper {
    padding: 20px;
    box-shadow: 0 6px 10px rgba(85, 85, 85, .1)
}

.header-section.style-2 .logo-search-acte .header-cate {
    margin: 0 40px
}

.header-section.style-2 .logo-search-acte .header-cate select {
    border: none;
    background: transparent
}

.header-section.style-2 .logo-search-acte .header-search form {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.header-section.style-2 .logo-search-acte .header-search form input {
    border: none;
    width: auto
}

.header-section.style-2 .logo-search-acte .header-search form button {
    background: transparent
}

@media(min-width: 992px) {
    .header-section.style-2.header-fixed {
        top: 0
    }
}

@media(min-width: 1200px) {
    .header-section.style-2.header-fixed {
        top: 0
    }
}

.header-section.style-3 {
    top: 0
}

@media(min-width: 992px) {
    .header-section.style-3 {
        position: static
    }
}

.header-section.style-3.header-fixed {
    position: fixed;
    border: none
}

.header-section.style-3 .header-top .header-top-area {
    padding: 8px 0
}

@media(max-width: 575px) {
    .header-section.style-3 .header-top .header-top-area {
        text-align: center
    }
}

.header-section.style-3 .header-top .header-top-area .header-top-contact a {
    letter-spacing: 1.5px;
    padding: 0 10px;
    border-right: 1px solid rgba(255, 255, 255, .6)
}

.header-section.style-3 .header-top .header-top-area .header-top-contact a:last-child {
    border-right: none
}

.header-section.style-3 .header-top .header-top-area .header-top-contact a.lab-btn {
    padding: 3px 15px;
    font-size: 14px;
    letter-spacing: normal
}

.header-section.style-3 .header-top .header-top-area .header-top-contact a.lab-btn:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    box-shadow: none
}

@media(max-width: 575px) {
    .header-section.style-3 .header-top .header-top-area .header-top-reglog {
        width: 100%
    }
}

.header-section.style-3 .header-top .header-top-area .header-top-reglog a {
    padding: 0 10px;
    border-right: 1px solid rgba(255, 255, 255, .6)
}

.header-section.style-3 .header-top .header-top-area .header-top-reglog a:last-child {
    padding-right: 0;
    border-right: none
}

.header-section.style-3 .header-bottom {
    box-shadow: 0 6px 10px rgba(85, 85, 85, .1)
}

@media(min-width: 992px) {
    .header-section.style-3 .header-bottom .header-wrapper {
        padding: 0
    }
}

@media(min-width: 992px) {
    .header-section.style-3 .header-bottom .menu>ul {
        margin-right: 20px
    }
}

@media(min-width: 992px) {
    .header-section.style-3 .header-bottom .menu>ul>li:hover a::after,
    .header-section.style-3 .header-bottom .menu>ul>li.active a::after {
        -webkit-transform: scaleX(1) translateY(-50%);
        -ms-transform: scaleX(1) translateY(-50%);
        transform: scaleX(1) translateY(-50%)
    }
}

@media(min-width: 992px) {
    .header-section.style-3 .header-bottom .menu>ul>li.menu-item-has-children>a::before {
        display: none
    }
}

@media(min-width: 992px) {
    .header-section.style-3 .header-bottom .menu>ul>li>a {
        padding: 30px 22px
    }
}

@media(min-width: 992px) {
    .header-section.style-3 .header-bottom .menu>ul>li>a::after {
        width: 100%;
        height: 2px;
        left: 0;
        top: 100%;
        position: absolute;
        content: "";
        background: #17703b;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        transition: all .3s ease;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        transform-origin: left;
        z-index: 1
    }
}

.header-section.style-4 {
    border: none
}

.header-section.style-4.header-fixed {
    top: 0
}

.header-section.style-4 .header-top {
    background: #fdfdfd73;
    padding: 15px 0
}

@media(max-width: 991px) {
    .header-section.style-4 .menu>ul {
        background: #17703b
    }
    .header-section.style-4 .menu>ul li a {
        color: #101115;
        border-top: 1px solid rgba(16, 17, 21, .1)
    }
    .header-section.style-4 .menu>ul li a:hover {
        background: #17703b
    }
}

.header-section.style-4 .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.header-section.style-5.header-fixed {
    top: 0
}

@media(min-width: 992px) {
    .header-section.style-5 .header-bottom .menu>ul>li>a::after {
        background: #17703b
    }
}

.header-section.style-5 .header-bottom .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.header-section.style-6 .header-top {
    background: transparent
}

.header-section.style-6 .header-bottom .header-wrapper {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.header-section.style-6.header-fixed {
    box-shadow: none
}

.header-section.style-6.header-fixed .header-top {
    display: none
}

.header-section.style-6.header-fixed .header-bottom {
    background: transparent;
    backdrop-filter: blur(0px)
}

.header-section.style-6.header-fixed .header-bottom .header-wrapper {
    backdrop-filter: blur(20px);
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px
}

.header-section.style-7 {
    top: 0
}

@media(min-width: 992px) {
    .header-section.style-7 .header-top-area .social-icons li a {
        color: #17703b
    }
}

@media(min-width: 992px) {
    .header-section.style-7 .menu>ul li ul {
        background: #fff;
        box-shadow: 0 0 10px rgba(16, 17, 21, .1)
    }
    .header-section.style-7 .menu>ul li ul li a {
        color: #101115
    }
    .header-section.style-7 .menu>ul li ul li a:hover,
    .header-section.style-7 .menu>ul li ul li a.active {
        background: #17703b;
        color: #101115
    }
    .header-section.style-7 .menu>ul li ul li a.active::after,
    .header-section.style-7 .menu>ul li ul li a.active::before {
        background: #101115
    }
}

@media(max-width: 991px) {
    .header-section.style-7 .menu>ul {
        background: #17703b
    }
    .header-section.style-7 .menu>ul li a {
        color: #101115;
        border-top: 1px solid rgba(25, 24, 24, .1)
    }
    .header-section.style-7 .menu>ul li a:hover {
        background: rgba(255, 223, 64, .6)
    }
}

.header-top {
    border-bottom: 1px solid rgba(16, 17, 21, .1)
}

@media(max-width: 991px) {
    .header-top {
        display: none;
        background-color: rgba(241, 97, 38, .95)
    }
    .header-top.open {
        display: block !important
    }
}

.header-top-area .social-icons li {
    border-left: 1px solid rgba(16, 17, 21, .1)
}

.header-top-area .social-icons li a {
    height: 50px;
    line-height: 50px;
    width: 50px;
    color: #17703b;
    background: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.header-top-area .social-icons li:first-child {
    border-left: none
}

.header-top-area .social-icons li:nth-child(2) {
    border-left: none
}

.header-top-area .left {
    margin: 0 -25px
}

.header-top-area .left li {
    padding: 15px 25px;
    border-right: 1px solid rgba(16, 17, 21, .1)
}

.header-top-area .left li:last-child {
    border-right: none
}

@media(max-width: 1199px) {
    .header-top-area .left li {
        font-size: 14px;
        padding: 9px 15px
    }
    .header-top-area .social-icons li a {
        height: 46px;
        line-height: 46px;
        width: 46px
    }
}

@media(max-width: 991px) {
    .header-top-area .left {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0;
        border: 1px solid rgba(255, 255, 255, .1)
    }
    .header-top-area .left li {
        flex-grow: 1;
        border-top: 1px solid rgba(255, 255, 255, .1);
        border-right: none;
        color: #fff
    }
    .header-top-area .left li:first-child {
        border-top: none
    }
    .header-top-area .social-icons {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1)
    }
    .header-top-area .social-icons li {
        flex-grow: 1;
        border-color: rgba(255, 255, 255, .1)
    }
    .header-top-area .social-icons li p,
    .header-top-area .social-icons li a {
        color: #fff
    }
}

/* .header-wrapper {
    padding: 19px 0
} */
header.header-section.header-fixed.fadeInUp .header-wrapper {
    padding: 14px 0px 0px;
}

@media(min-width: 576px) {
    .header-wrapper {
        position: relative
    }
}

.header-wrapper .logo a img {
    max-width: 100%
}

@media(max-width: 575px) {
    .header-wrapper .logo {
        width: 160px
    }
}

@media(max-width: 1199px)and (min-width: 992px) {
    .header-wrapper .logo {
        width: 200px
    }
}

.header-wrapper .menu-area .login,
.header-wrapper .menu-area .signup {
    display: none
}

@media(min-width: 768px) {
    .header-wrapper .menu-area .login,
    .header-wrapper .menu-area .signup {
        padding: 10px;
        display: inline-block
    }
}

@media(min-width: 1200px) {
    .header-wrapper .menu-area .login,
    .header-wrapper .menu-area .signup {
        padding: 15px
    }
}

.header-wrapper .menu-area .login {
    color: #17703b;
    border-radius: 2px 0 0 2px
}

.header-wrapper .menu-area .signup {
    color: #fff;
    background: #17703b;
    border-radius: 5px;
    padding: 12px 23px;
}

.header-wrapper .menu-area .ellepsis-bar {
    font-size: 20px
}

@media(max-width: 575px) {
    .header-wrapper .menu-area {
        padding: 15px 0
    }
    .header-wrapper .menu-area .ellepsis-bar {
        margin-left: 20px
    }
}

@media(min-width: 992px) {
    .menu>ul {
        margin-right: 30px
    }
}

.menu>ul>li>a {
    color: #101115;
    font-size: 1rem;
    font-weight: 700;
    padding: 15px 22px;
    text-transform: capitalize
}

.menu>ul>li>a i {
    margin-right: 5px
}

.menu>ul>li ul {
    margin-right: 0
}

.menu>ul>li ul li {
    width: 100%
}

@media(min-width: 992px) {
    .menu>ul li {
        position: relative
    }
    .menu>ul li ul {
        position: absolute;
        top: calc(100%);
        left: 0;
        -webkit-transition: all ease .3s;
        -moz-transition: all ease .3s;
        transition: all ease .3s;
        z-index: 9;
        background: #fff;
        width: 220px;
        padding: 0;
        -webkit-transform: translateY(35px);
        -ms-transform: translateY(35px);
        transform: translateY(35px);
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 10px rgba(16, 17, 21, .1)
    }
    .menu>ul li ul li {
        padding: 0
    }
    .menu>ul li ul li a {
        padding: 10px 20px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #000;
        font-weight: 500;
        border-bottom: 1px solid #ecf0f3
    }
    .menu>ul li ul li a:hover,
    .menu>ul li ul li a.active {
        background: #17703b;
        padding-left: 22px;
        color: #fff
    }
    .menu>ul li ul li a.active::after,
    .menu>ul li ul li a.active::before {
        background: #fff
    }
    .menu>ul li ul li:last-child a {
        border-bottom: none
    }
    .menu>ul li ul li ul {
        left: 100%;
        top: 0;
        display: none
    }
    .menu>ul li:hover>ul {
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
        visibility: visible;
        display: block
    }
}

@media(max-width: 991px) {
    .menu>ul {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: auto;
        max-height: 400px;
        -webkit-transition: all ease .3s;
        -moz-transition: all ease .3s;
        transition: all ease .3s;
        -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
        transform: scaleY(0);
        transform-origin: top;
        background: #17703b;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px)
    }
    .menu>ul li {
        width: 100%;
        padding: 0
    }
    .menu>ul li a {
        display: block;
        padding: 10px 25px;
        font-size: 15px;
        font-weight: 700;
        text-transform: capitalize;
        border-top: 1px solid rgba(255, 255, 255, .1);
        color: #fff
    }
    .menu>ul li a:hover {
        background: rgba(241, 97, 38, .6)
    }
    .menu>ul li a.show::before {
        display: none
    }
    .menu>ul li ul {
        padding-left: 20px;
        display: none;
        position: static !important;
        padding: 0;
        background: transparent;
        transform: translate3d(0px, 0px, 0px) !important;
        border: none
    }
    .menu>ul li ul li {
        width: 100%
    }
    .menu>ul li ul li a {
        font-size: 14px;
        display: flex;
        justify-content: space-between
    }
    .menu>ul.active {
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1)
    }
}

.header-bar {
    width: 25px;
    height: 20px
}

.header-bar span {
    height: 3px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    left: 0
}

.header-bar span:first-child {
    top: 0
}

.header-bar span:nth-child(2) {
    top: 52%;
    transform: translateY(-65%)
}

.header-bar span:last-child {
    bottom: 0
}

.header-bar.active span:first-child {
    -webkit-transform: rotate(45deg) translate(3px, 9px);
    -ms-transform: rotate(45deg) translate(3px, 9px);
    transform: rotate(45deg) translate(3px, 9px)
}

.header-bar.active span:nth-child(2) {
    opacity: 0
}

.header-bar.active span:last-child {
    -webkit-transform: rotate(-45deg) translate(3px, -9px);
    -ms-transform: rotate(-45deg) translate(3px, -9px);
    transform: rotate(-45deg) translate(3px, -9px)
}

@media(max-width: 575px) {
    .header-bar {
        width: 15px;
        height: 16px
    }
    .header-bar span {
        height: 2px;
        width: 20px
    }
    .header-bar.active span:first-child {
        -webkit-transform: rotate(45deg) translate(4px, 6px);
        -ms-transform: rotate(45deg) translate(4px, 6px);
        transform: rotate(45deg) translate(4px, 6px)
    }
    .header-bar.active span:nth-child(2) {
        opacity: 0
    }
    .header-bar.active span:last-child {
        -webkit-transform: rotate(-45deg) translate(4px, -6px);
        -ms-transform: rotate(-45deg) translate(4px, -6px);
        transform: rotate(-45deg) translate(4px, -6px)
    }
}

.menu-item-has-children>a::after {
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    width: 10px;
    height: 2px;
    background: #fff
}

.menu-item-has-children>a::before {
    top: 50%;
    transform: translateY(-50%);
    right: 29px;
    width: 2px;
    height: 10px;
    background-color: #fff;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(min-width: 992px) {
    .menu-item-has-children>a::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 10px;
        height: 2px;
        background-color: #101115
    }
    .menu-item-has-children>a::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        width: 2px;
        height: 10px;
        background-color: #101115;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        transition: all .3s ease
    }
}

@media(min-width: 992px) {
    .menu-item-has-children .menu-item-has-children a::after {
        right: 11px
    }
    .menu-item-has-children .menu-item-has-children a::before {
        right: 15px
    }
}

@media(min-width: 992px) {
    .menu-item-has-children .menu-item-has-children a:hover::after,
    .menu-item-has-children .menu-item-has-children a:hover::before {
        background: #fff
    }
}

@media(min-width: 992px) {
    .menu-item-has-children:hover>a:before {
        transform: translateY(-50%) rotate(90deg);
        opacity: 0
    }
}

.menu-item-has-children.open>a:before {
    transform: translateY(-50%) rotate(90deg);
    opacity: 0
}

.shop-menu li a::after,
.shop-menu li a::before {
    background: #101115;
    right: 0
}

.shop-menu li a::before {
    right: 4px
}

.shop-menu li:hover>a {
    color: #17703b !important
}

.shop-menu li:hover>a::before {
    -webkit-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    opacity: 1
}

.shop-menu li:hover>a:hover::after,
.shop-menu li:hover>a:hover::before {
    background: #17703b
}

.shop-menu li.open>a::after {
    background: #17703b
}

.shop-menu li.open>a::before {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
    opacity: 0
}

.shop-menu li ul li a:hover::after,
.shop-menu li ul li a:hover::before {
    background: #101115
}

.menu-search-form {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(16, 17, 21, .7);
    visibility: hidden;
    opacity: 0;
    z-index: 99999;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    transform: scale(0.1)
}

.menu-search-form.open {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.menu-search-form form {
    max-width: 600px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.menu-search-form form input {
    width: calc(100% - 50px);
    border: 1px solid #ecf0f3;
    outline: none
}

.menu-search-form form button {
    width: 50px
}

.banner-section {
background-color: #f9fcfb;
    background-size: cover;
    padding-top: 170px
}

@media(min-width: 576px) {
    .banner-section {
        padding-top: 215px
    }
}
/* 
.banner-section .all-shapes {
    display: none;
    width: 700px;
    height: 700px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 1px dashed #17703b;
    border-width: 1.75px;
    top: 25%;
    right: 0%;
    animation: rotate 110s infinite linear
} */
.banner-section .all-shapes {
    width: 600px;
    height: 600px;
    border-radius: 100%;
    border-width: 1.75px;
    top: 20%;
    background: #ecf3ef;
    animation: rotate 50s infinite linear;
    box-shadow: 0px 5px 10px 0px #ecf3ef;
}



.banner-section .all-shapes::after,
.banner-section .all-shapes::before {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}
.banner-section .all-shapes::before {
    width: calc(100% - 15%);
    height: calc(100% - 15%);
    border: 1px dashed #d8e4de;
    border-width: 1.8px !important;
    animation: rotate-rev 110s infinite linear;
    background: #d8e4de;
}
/* .banner-section .all-shapes::before {
    width: calc(100% - 15%);
    height: calc(100% - 15%);
    border: 1px dashed #17703b;
    border-width: 1.8px !important;
    animation: rotate-rev 110s infinite linear
} */

/* .banner-section .all-shapes::after {
    width: calc(100% - 30%);
    height: calc(100% - 30%);
    border: 1px dashed #17703b;
    border-width: 1.5px !important
} */
.banner-section .all-shapes::after {
    width: calc(100% - 30%);
    height: calc(100% - 30%);
    background: #aac4b5;
    border: 1px dashed #aac4b5;
    border-width: 1.5px !important;

}
/* @media(min-width: 1200px) {
    .banner-section .all-shapes {
        display: block
    }
}

@media(min-width: 1600px) {
    .banner-section .cbs-content-list {
        display: block !important
    }
} */

.banner-section .cbs-content-list ul li.ccl-shape {
    z-index: 2
}

.banner-section .cbs-content-list ul li.ccl-shape a {
    padding: 10px 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.banner-section .cbs-content-list ul li.ccl-shape a::after {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    top: 50%;
    left: -35px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.banner-section .cbs-content-list ul li.shape-1 {
    animation: up-down 6s infinite linear;
    top: 38%;
    right: 5%
}

.banner-section .cbs-content-list ul li.shape-1 a {
   background-color: #fff;
   color: #000;
   box-shadow: -10px 6px 28px 4px #17703b38;
   font-size: 18px;
   font-weight: 700;
}

.banner-section .cbs-content-list ul li.shape-1 a::after {
    left: auto;
    right: -40px
}


.banner-section .cbs-content-list ul li.shape-2 {
    animation: up_down_2 6s infinite linear;
    top: 40%;
    right: 19%
}

.banner-section .cbs-content-list ul li.shape-2 a {
    background-color: #fff;
    color: #000;
    box-shadow: -10px 6px 28px 4px #17703b38;
    font-size: 18px;
    font-weight: 700;
}

.banner-section .cbs-content-list ul li.shape-2 a::after {
    left: -40px;
    background: #17703b
}


.banner-section .cbs-content-list ul li.shape-3 {
    animation: up_down_2 8s infinite linear;
    top: 59%;
    right: 38%
}

.banner-section .cbs-content-list ul li.shape-3 a {
    background-image: linear-gradient(to right, #52f781, #a5e150, #d3c83a, #f0ae43, #fe955c)
}

.banner-section .cbs-content-list ul li.shape-3 a::after {
    left: -50px;
    background: #17703b
}


.banner-section .cbs-content-list ul li.shape-4 {
    animation: up_down_3 6s infinite linear;
    top: 26%;
    right: 26%
}

.banner-section .cbs-content-list ul li.shape-4 a {
    background-color: #fff;
    color: #000;
    box-shadow: -10px 6px 28px 4px #17703b38;
    font-size: 18px;
    font-weight: 700;
}

.banner-section .cbs-content-list ul li.shape-4 a::after {
    left: -38px;
    background: #17703b;
}


.banner-section .cbs-content-list ul li.shape-5 {
    animation: up_down_3 6s infinite linear;
    top: 50%;
    right: 45%
}

.banner-section .cbs-content-list ul li.shape-5 a {
    background-image: linear-gradient(to right, #785eff, #009fff, #00c8ff, #00e4ce, #52f781)
}

.banner-section .cbs-content-list ul li.shape-5 a::after {
    background: #785eff
}


.banner-section .section-wrapper .banner-content .subtitle {
    color: #17703b;
    letter-spacing: 5px;
    font-size: 18px
}

.banner-section .section-wrapper .banner-content .title {
    margin-bottom: 15px
}

@media(min-width: 992px) {
    .banner-section .section-wrapper .banner-content .title {
        font-size: 66px;
        line-height: 1.1
    }
}

.banner-section .section-wrapper .banner-content .desc {
    margin-bottom: 25px
}

@media(min-width: 576px) {
    .banner-section .section-wrapper .banner-content .desc {
        font-size: 20px;
        line-height: 1.5
    }
}

.banner-section .section-wrapper .banner-content form {
    background: #fff;
    justify-content: space-between;
    box-shadow: 0 5px 10px rgba(16, 17, 21, .1)
}

.banner-section .section-wrapper .banner-content form .banner-icon {
    left: 15px;
    top: 50%;
    font-weight: 700;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.banner-section .section-wrapper .banner-content form input {
    width: calc(100% - 132px);
    border: none;
    padding: 18px;
    padding-left: 40px
}

.banner-section .section-wrapper .banner-content form button {
    color: #fff;
    background: #17703b
}

.banner-section .section-wrapper .banner-content .banner-catagory {
    margin-top: 25px
}

@media(max-width: 991px) {
    .banner-section .section-wrapper .banner-content .banner-catagory ul li:first-child a {
        padding-left: 0
    }
}

.banner-section .section-wrapper .banner-content .banner-catagory ul li a {
    padding: 0 5px;
    font-weight: 500;
    text-decoration: underline
}

@media(min-width: 576px) {
    .banner-section .section-wrapper .banner-content .banner-catagory ul li a {
        padding: 0 10px
    }
}

.banner-section .section-wrapper .banner-content .banner-catagory ul li a:hover {
    color: #17703b
}

.banner-section .section-wrapper .banner-thumb {
    z-index: 2;
    margin-top: 30px
}

@media(min-width: 992px) {
    .banner-section .section-wrapper .banner-thumb {
        margin-top: 0
    }
}



.banner-section.style-2::before {
    width: 50%;
    height: 100%;
    top: 0;
    border-bottom: 875px solid #17703b;
    border-right: 960px solid transparent;
    z-index: 1
}

@media(min-width: 1440px) {
    .banner-section.style-2::before {
        display: block
    }
}

.banner-section.style-2 .section-wrapper .thumb-part .abs-thumb {
    bottom: 0;
    left: -10%
}

.banner-section.style-2 .section-wrapper .thumb-part .abs-thumb img {
    max-width: none
}

.banner-section.style-2 .section-wrapper .banner-thumb {
    animation: bounce-1 5s infinite linear
}


@media(min-width: 992px) {
    .banner-section.style-3 {
        padding: 255px 0;
        background-position: center;
        margin-top: 0
    }
}

.banner-section.style-3 .banner-content .lab-btn {
    padding: 18px 40px
}



@media(min-width: 992px) {
    .banner-section.style-4 {
        padding-top: 313px;
        padding-bottom: 215px
    }
}

.banner-section.style-4 .banner-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center
}

.banner-section.style-4 .banner-content h2 {
    font-size: 46px;
    line-height: 50px;
    font-weight: 300
}

.banner-section.style-4 .banner-content h2 span {
    font-weight: 500
}

@media(max-width: 575px) {
    .banner-section.style-4 .banner-content h2 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px
    }
}

.banner-section.style-4 .banner-content form {
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2)
}

@media(max-width: 575px) {
    .banner-section.style-4 .banner-content form {
        border-radius: 0px;
        margin-bottom: 20px
    }
}

.banner-section.style-4 .banner-content form select {
    padding: 21px 15px;
    outline: none;
    width: 200px;
    border: none
}

@media(max-width: 575px) {
    .banner-section.style-4 .banner-content form select {
        width: 100%;
        border-bottom: 1px solid #ecf0f3
    }
}

.banner-section.style-4 .banner-content form input {
    padding: 22px 50px 22px 15px;
    border: none;
    width: calc(100% - 200px)
}

@media(max-width: 575px) {
    .banner-section.style-4 .banner-content form input {
        width: 100%
    }
}

.banner-section.style-4 .banner-content form button {
    background: transparent;
    right: 30px;
    top: 21px;
    font-weight: 700
}

@media(max-width: 575px) {
    .banner-section.style-4 .banner-content form button {
        top: auto;
        bottom: 20px
    }
}

.banner-section.style-4 .banner-content p {
    font-size: 1.25rem;
    margin-bottom: 100px
}

@media(max-width: 575px) {
    .banner-section.style-4 .banner-content p {
        margin-bottom: 30px
    }
}

.banner-section.style-4 .banner-content ul li {
    padding: 10px 15px;
    margin: 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.banner-section.style-4 .banner-content ul li i {
    font-size: 22px
}



@media(min-width: 992px) {
    .banner-section.style-5 {
        padding: 300px 0
    }
}

.banner-section.style-5::after {
    right: 0;
    top: 0;
    background: rgba(255, 223, 64, .85)
}

@media(min-width: 768px) {
    .banner-section.style-5::after {
        width: 50%
    }
}

.banner-section.style-5 .section-wrapper .banner-content {
    z-index: 1
}

@media(min-width: 576px) {
    .banner-section.style-5 .section-wrapper .banner-content {
        padding-left: 30px
    }
}

.banner-section.style-5 .section-wrapper .banner-content .title span {
    font-weight: 400
}

@media(min-width: 992px)and (max-width: 1199px) {
    .banner-section.style-5 .section-wrapper .banner-content .title {
        font-size: 56px
    }
}

.banner-section.style-5 .section-wrapper .banner-content p {
    font-weight: 500
}

.banner-section.style-5 .section-wrapper .banner-content .lab-btn {
    background: transparent;
    border: 2px solid #101115;
    padding: 14px 30px
}

.banner-section.style-5 .section-wrapper .banner-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(16, 17, 21, .5)
}

@media(min-width: 992px) {
    .banner-section.style-6 {
        padding: 465px 0 300px
    }
}

@media(min-width: 1200px) {
    .banner-section.style-6 .section-wrapper .banner-content .title {
        font-size: 72px
    }
}

.banner-section.style-6 .section-wrapper .banner-content p {
    font-size: 1.25rem
}

@media(min-width: 992px) {
    .banner-section.style-7 {
        margin-top: 153px
    }
}

@media(min-width: 1200px) {
    .banner-section.style-7 {
        background-position: center
    }
}

.banner-section.style-7 .section-wrapper .banner-top {
    padding-bottom: 170px
}

@media(min-width: 576px) {
    .banner-section.style-7 .section-wrapper .banner-top {
        padding-bottom: 230px
    }
}

.banner-section.style-7 .section-wrapper .banner-top .banner-content .lab-btn {
    background: transparent;
    border: 2px solid #101115;
    padding: 14px 30px
}

.banner-section.style-7 .section-wrapper .banner-top .banner-content .lab-btn:hover {
    border-color: #17703b;
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.banner-section.style-7 .section-wrapper .banner-bottom {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0
}



.pageheader-section .pageheader-content h2 {
    margin-bottom: 10px
}

@media(max-width: 991px) {
    .pageheader-section.style-2 {
        padding-bottom: 60px;
        padding-top: 150px
    }
}

.pageheader-section.style-2 .pageheader-thumb {
    margin-bottom: 30px;
    border: 10px solid #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

@media(min-width: 992px) {
    .pageheader-section.style-2 .pageheader-thumb {
        margin-bottom: -60px
    }
}

.pageheader-section.style-2 .pageheader-content .course-category .course-cate {
    padding: 3px 15px;
    padding-left: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-weight: 500;
    margin-right: 10px
}

.pageheader-section.style-2 .pageheader-content .course-category .course-cate::before {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: rgba(255, 255, 255, .5);
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.pageheader-section.style-2 .pageheader-content .course-category .course-offer {
    padding: 3px 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.pageheader-section.style-2 .pageheader-content .phs-thumb img {
    width: 40px;
    height: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.pageheader-section.style-2 .pageheader-content .phs-thumb span {
    font-size: 1.125rem;
    font-weight: 500
}

.sponsor-section {
    padding: 50px 0
}

.category-section .section-wrapper .col .category-item .category-inner .category-content span {
    color: #00a556
}

.category-section .section-wrapper .col:nth-of-type(6n + 2) .category-item .category-inner .category-content span {
    color: #31aabd
}

.category-section .section-wrapper .col:nth-of-type(6n + 3) .category-item .category-inner .category-content span {
    color: #eec93d
}

.category-section .section-wrapper .col:nth-of-type(6n + 4) .category-item .category-inner .category-content span {
    color: #cf9fb7
}

.category-section .section-wrapper .col:nth-of-type(6n + 5) .category-item .category-inner .category-content span {
    color: #fe6225
}

.category-section .section-wrapper .col:nth-of-type(6n + 6) .category-item .category-inner .category-content span {
    color: #f79a3d
}


.category-section.style-2 .section-wrapper .col .category-item .category-inner .category-thumb {
    margin-top: 60px;
    margin-bottom: 20px
}

.category-section.style-2 .section-wrapper .col .category-item .category-inner .category-content h6,
.category-section.style-2 .section-wrapper .col .category-item .category-inner .category-content span {
    color: #fff !important
}


@media(min-width: 992px) {
    .category-section.style-3 .section-header {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto
    }
}

@media(min-width: 992px) {
    .category-section.style-3 .section-header .title {
        line-height: 1.1
    }
}

.category-section.style-4 .section-wrapper .category-item .category-inner {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.category-section.style-4 .section-wrapper .category-item .category-inner::after {
    top: 0;
    left: 0;
    background-image: linear-gradient(to top, #051937bd, #0519379e, #05193759, #05193703, #05193700)
}

.category-section.style-4 .section-wrapper .category-item .category-inner:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
}

.category-section.style-4 .section-wrapper .category-item .category-inner .category-thumb img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.category-section.style-4 .section-wrapper .category-item .category-inner .category-content {
    bottom: 20px;
    left: 20px;
    z-index: 1
}

.category-section.style-4 .section-wrapper .category-item .category-inner .category-content .cate-icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.category-section.style-4 .section-wrapper .lab-btn {
    background: transparent;
    border: 2px solid #17703b
}

.category-section.style-4 .section-wrapper .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.course-section .section-wrapper .col:nth-of-type(6n + 2) .course-item .course-inner .course-content .course-category .course-cate a {
    background: #00a556
}

.course-section .section-wrapper .col:nth-of-type(6n + 2) .course-item .course-inner .course-content .course-category .course-cate a {
    background: #00a556
}

.course-section .section-wrapper .col:nth-of-type(6n + 3) .course-item .course-inner .course-content .course-category .course-cate a {
    background: #eec93d
}

.course-section .section-wrapper .col:nth-of-type(6n + 4) .course-item .course-inner .course-content .course-category .course-cate a {
    background: #cf9fb7
}

.course-section .section-wrapper .col:nth-of-type(6n + 5) .course-item .course-inner .course-content .course-category .course-cate a {
    background: #31aabd
}

.course-section .section-wrapper .col:nth-of-type(6n + 6) .course-item .course-inner .course-content .course-category .course-cate a {
    background: #f79a3d
}

.course-section.style-2 .section-header .course-navigations {
    right: 0;
    bottom: 0
}

@media(max-width: 767px) {
    .course-section.style-2 .section-header .course-navigations {
        display: none
    }
}


.course-section.style-3 .course-shape {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.course-section.style-3 .course-shape.one {
    left: 0;
    animation: outer-ripple 15s infinite
}

.course-section.style-3 .course-shape.two {
    right: 0;
    animation: outer-ripple 10s infinite
}

.course-section.style-3 .section-header {
    padding: 15px 30px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

@media(max-width: 991px) {
    .course-section.style-3 .section-header {
        text-align: center
    }
    .course-section.style-3 .section-header .title {
        width: 100%
    }
}

@media(max-width: 991px) {
    .course-section.style-3 .section-header .course-filter-group {
        width: 100%
    }
}

@media(max-width: 991px) {
    .course-section.style-3 .section-header .course-filter-group ul {
        justify-content: center
    }
}

.course-section.style-3 .section-header .course-filter-group ul li {
    padding: 2px 15px;
    margin: 2px;
    font-weight: 500;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.course-single-section .course-item .course-inner {
    box-shadow: none;
    background: transparent
}

.course-single-section .course-item .course-inner:hover h4 {
    color: #101115 !important
}

.course-single-section .course-item .course-inner .course-content ul li {
    padding: 3px 0
}

.course-video {
    margin: 30px 0
}

.course-video .course-video-content .accordion-item {
    background: transparent;
    border: none
}

@media(max-width: 575px) {
    .course-video .course-video-content .accordion-item .accordion-header button {
        flex-wrap: nowrap !important
    }
}

.course-video .course-video-content .accordion-item .accordion-header span {
    font-size: 1.125rem;
    font-weight: 700;
    cursor: auto
}

@media(max-width: 575px) {
    .course-video .course-video-content .accordion-item .accordion-header span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis
    }
}

.course-video .course-video-content .accordion-item .accordion-header span:last-child {
    font-weight: 500
}

.course-author {
    margin: 30px 0
}



@media(min-width: 1200px) {
    .video-section {
        padding: 250px 0
    }
}

.video-section .section-wrapper .video-thumb a {
    margin: 30px auto 0
}

.about-section::before {
    width: 50%;
    height: 100%;
    border-bottom: 753px solid #17703b;
    border-right: 960px solid transparent
}

@media(min-width: 1440px) {
    .about-section::before {
        display: block
    }
}

.about-section .about-left {
    z-index: 1
}

.about-section .about-right {
    z-index: 1
}

.about-section .about-right .section-header {
    margin-bottom: 30px
}

.about-section .about-right .section-header p {
    font-size: 1.125rem;
    margin-bottom: 0
}

.about-section .about-right .section-wrapper ul li {
    padding: 15px 0
}

.about-section .about-right .section-wrapper ul li:last-child {
    padding-bottom: 0
}

.about-section .about-right .section-wrapper ul li .sr-left {
    width: 70px;
    margin-right: 15px
}

.about-section .about-right .section-wrapper ul li .sr-left img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.about-section .about-right .section-wrapper ul li .sr-right {
    width: calc(100% - 85px)
}

.about-section .about-right .section-wrapper ul li .sr-right h5 {
    font-weight: 600
}

.about-section .about-right .section-wrapper ul li .sr-right p {
    font-size: 1.125rem;
    margin-bottom: 0;
    max-width: 80%
}

.about-section.style-2 {
    padding: 50px 0
}

.about-section.style-2::before {
    display: none
}

.about-section.style-2 .about-right .section-wrapper ul li {
    padding-bottom: 15px !important
}

.about-section.style-3::before {
    display: none
}

.about-section.style-3 .about-left {
    margin-bottom: 60px
}

.about-section.style-3 .about-left .about-thumb img {
    box-shadow: 0 0 10px rgba(16, 17, 21, .1);
    border: 10px solid #fff
}

.about-section.style-3 .about-left .abs-thumb {
    right: 15px;
    bottom: -60px;
    box-shadow: 0 0 10px rgba(16, 17, 21, .1)
}

@media(max-width: 575px) {
    .about-section.style-3 .about-left .abs-thumb {
        display: none
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .about-section.style-3 .about-left .abs-thumb {
        right: 260px
    }
}

.about-section.style-3 .about-left .abs-thumb img {
    border: 10px solid #fff
}

.about-section.style-3 .about-left .about-left-content {
    width: 150px;
    left: 0;
    bottom: 0;
    border: 10px solid #fff;
    box-shadow: 0 0 10px rgba(16, 17, 21, .1)
}

.about-section.style-3 .about-left .about-left-content::after {
    left: -10px;
    top: calc(100% + 10px);
    border-top: 60px solid #ededed;
    border-left: 60px solid transparent
}

@media(min-width: 576px) {
    .about-section.style-3 .about-left .about-left-content h3 {
        font-size: 48px
    }
}

.about-section.style-4 .about-items {
    margin: -15px 0
}

.about-section.style-4 .about-item {
    width: calc(100% / 1);
    padding: 15px
}

@media(min-width: 992px) {
    .about-section.style-4 .about-item {
        width: calc(100% / 2)
    }
}

@media(min-width: 1200px) {
    .about-section.style-4 .about-item {
        width: calc(100% / 3)
    }
}

.about-section.style-4 .about-item .image {
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}

.about-section.style-4 .about-item .image img {
    width: 100%
}

.about-section.style-4 .about-item .about-content h2 span {
    font-weight: 300
}

.about-section.style-4 .about-item .about-content>span {
    font-size: 22px;
    margin-bottom: 10px;
    display: block
}

.about-section.style-4 .about-item .about-content .details li {
    padding: 5px 0
}

.about-section.style-4 .about-item .about-content .details li p {
    margin: 0px;
    font-size: 16px;
    width: 100px;
    font-weight: 600;
    margin-right: 30px;
    justify-content: space-between
}

.about-section.style-4 .about-item .counter-part .count-item:nth-child(3n+2) .count-inner .count-icon::before {
    background: rgba(38, 201, 118, .1)
}

.about-section.style-4 .about-item .counter-part .count-item:nth-child(3n+2) .count-inner .count-icon::after {
    background: rgba(38, 201, 118, .2)
}

.about-section.style-4 .about-item .counter-part .count-item:nth-child(3n+3) .count-inner .count-icon::before {
    background: rgba(255, 223, 64, .1)
}

.about-section.style-4 .about-item .counter-part .count-item:nth-child(3n+3) .count-inner .count-icon::after {
    background: rgba(255, 223, 64, .2)
}

.about-section.style-4 .about-item .counter-part .count-item .count-inner {
    padding: 18px 0
}

.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-content span {
    font-weight: 400
}

.about-section.style-4 .about-item .counter-part .count-item .count-inner .count-content span.count {
    font-weight: 700
}



@media(min-width: 992px) {
    .contact-info-section .section-wrapper {
        max-width: 70%;
        margin: auto
    }
}

@media(min-width: 1200px) {
    .contact-info-section .section-wrapper {
        max-width: 50%
    }
}

.contact-info-section .section-wrapper::after {
    width: 340px;
    height: 2px;
    left: 50%;
    top: 41%;
    border-bottom: 2px dashed #ecf0f3;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

@media(min-width: 576px) {
    .contact-info-section .section-wrapper::after {
        top: 65px
    }
}

.contact-info-section .section-wrapper .cinfo-or {
    width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    left: 50%;
    top: 37%;
    font-weight: 700;
    z-index: 1;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

@media(min-width: 576px) {
    .contact-info-section .section-wrapper .cinfo-or {
        top: 37px
    }
}

.contact-info-section .section-wrapper .cinfo-or::after,
.contact-info-section .section-wrapper .cinfo-or::before {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.contact-info-section .section-wrapper .cinfo-or::before {
    width: 60px;
    height: 60px;
    background: rgba(255, 223, 64, .4)
}

.contact-info-section .section-wrapper .cinfo-or::after {
    width: 70px;
    height: 70px;
    background: rgba(255, 223, 64, .2)
}

.contact-info-section .section-wrapper .cinfo-or span {
    z-index: 1
}

@media(max-width: 575px) {
    .contact-info-section .section-wrapper .cinfo-item {
        width: 100%;
        margin-bottom: 120px
    }
}

.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-thumb {
    width: 120px;
    height: 120px;
    border: 2px solid #17703b;
    line-height: 116px;
    margin: 0 auto 30px;
    z-index: 1;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.contact-info-section .section-wrapper .cinfo-item .cinfo-inner .cinfo-content h6 {
    font-weight: 400
}


@media(min-width: 1440px) {
    .offer-section {
        background-size: 120% 220%
    }
}

@media(min-width: 992px) {
    .offer-section .section-header {
        max-width: 70%
    }
}

.offer-section .section-wrapper {
    padding: 50px 30px;
    box-shadow: 0 24px 46px rgba(16, 17, 21, .12);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px
}

.offer-section .section-wrapper .title {
    font-weight: 500
}

.offer-section .section-wrapper .date li {
    width: auto;
    height: auto;
    line-height: 1;
    background: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.offer-section .section-wrapper .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.instructor-section .section-wrapper .footer-btn p a {
    font-weight: 500
}

.instructor-section.style-2::after {
    right: -130px;
    bottom: 0;
    width: 60%;
    transform: skewX(-20deg)
}

@media(max-width: 1199px) {
    .instructor-section.style-2::after {
        display: none
    }
}

.instructor-section.style-2 .section-wrapper {
    z-index: 1
}

.instructor-section.style-2 .section-wrapper .col:nth-child(3n+2) {
    order: 2
}

@media(min-width: 1200px) {
    .instructor-section.style-2 .section-wrapper .col:nth-child(3n+2) {
        order: 2
    }
}

@media(min-width: 768px)and (max-width: 1199px) {
    .instructor-section.style-2 .section-wrapper .col:nth-child(3n+2) {
        order: 1
    }
}

.instructor-section.style-2 .section-wrapper .col:nth-child(3n+3) {
    order: 1
}

@media(min-width: 1200px) {
    .instructor-section.style-2 .section-wrapper .col:nth-child(3n+3) {
        order: 1
    }
}

@media(min-width: 768px)and (max-width: 1199px) {
    .instructor-section.style-2 .section-wrapper .col:nth-child(3n+3) {
        order: 2
    }
}

.instructor-section.style-2 .section-wrapper .count-item:nth-child(3n+2) .count-inner .count-icon::before {
    background: rgba(38, 201, 118, .1)
}

.instructor-section.style-2 .section-wrapper .count-item:nth-child(3n+2) .count-inner .count-icon::after {
    background: rgba(38, 201, 118, .2)
}

.instructor-section.style-2 .section-wrapper .count-item:nth-child(3n+3) .count-inner .count-icon::before {
    background: rgba(255, 223, 64, .1)
}

.instructor-section.style-2 .section-wrapper .count-item:nth-child(3n+3) .count-inner .count-icon::after {
    background: rgba(255, 223, 64, .2)
}

.instructor-section.style-2 .section-wrapper .count-item .count-inner {
    padding: 20px 0
}

.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-content span {
    font-weight: 400
}

.instructor-section.style-2 .section-wrapper .count-item .count-inner .count-content span.count {
    font-weight: 700
}

@media(min-width: 1200px) {
    .instructor-section.style-2 .section-wrapper .instructor-thumb {
        -webkit-transform: translateX(-60px);
        -ms-transform: translateX(-60px);
        transform: translateX(-60px)
    }
}

@media(min-width: 1200px) {
    .instructor-section.style-2 .section-wrapper .instructor-content .subtitle,
    .instructor-section.style-2 .section-wrapper .instructor-content .title,
    .instructor-section.style-2 .section-wrapper .instructor-content p {
        color: #101115
    }
}

.instructor-section.style-2 .section-wrapper .instructor-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 255, 255, .5)
}

.instructor-section.style-2 .section-wrapper .instructor-content .lab-btn span {
    color: #101115
}

.instructor-section.style-3 .section-wrapper .instructor-bottom {
    padding: 0 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(16, 17, 21, .06);
    margin-top: 24px
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi {
    width: 36px;
    height: 36px;
    line-height: 36px;
    top: 40%;
    z-index: 2;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: 0 2px 4px rgba(16, 17, 21, .06)
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi.instructor-slider-next {
    right: 0
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-slider .instructor-navi.instructor-slider-prev {
    left: 0
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item {
    box-shadow: none
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item:hover {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px)
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item:hover .instructor-inner .instructor-thumb img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.instructor-section.style-3 .section-wrapper .instructor-bottom .instructor-item .instructor-inner .instructor-thumb img {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.instructor-single-section .instructor-wrapper .instructor-single-top {
    padding: 30px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .2);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

@media(min-width: 576px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top {
        padding: 60px 50px
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-top::before {
    width: 50%;
    height: 100%;
    top: 0;
    left: 0px;
    border-bottom: 690px solid #17703b;
    border-right: 648px solid transparent
}

@media(min-width: 1200px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top::before {
        display: block
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb {
    box-shadow: 0px 2px 4px 3px rgba(83, 61, 82, .06)
}

@media(min-width: 576px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb {
        margin-bottom: 40px
    }
}

@media(min-width: 768px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb {
        margin-bottom: 50px
    }
}

@media(min-width: 1200px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb {
        width: calc(50% - 25px);
        margin-bottom: 0
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-thumb img {
    z-index: 1;
    border: 8px solid #fff
}

@media(min-width: 1200px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content {
        width: calc(50% - 15px)
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .title {
    margin-bottom: 3px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .subtitle {
    line-height: 38px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content .ratting i {
    font-size: 1rem
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content p {
    margin-bottom: 7px;
    line-height: 1.5
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content p.ins-desc {
    margin-bottom: 25px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li {
    line-height: 38px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li span.list-name {
    width: calc(100px)
}

@media(min-width: 576px) {
    .instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li span.list-name {
        width: calc(115px)
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr {
    width: calc(100% - 115px)
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::after,
.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::before {
    width: 2px;
    height: 2px;
    left: -10px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::after {
    top: 20px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr::before {
    top: 15px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li {
    margin-right: 20px
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li:last-child {
    margin: 0
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li a.behance {
    color: #1769ff
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li a.vimeo {
    color: #3b5998
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li a.twitter {
    color: #55acee
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li a.basketball {
    color: #e94c89
}

.instructor-single-section .instructor-wrapper .instructor-single-top .instructor-single-item .instructor-single-content ul li .list-attr li a.instagram {
    color: #e4405f
}

.instructor-single-section .instructor-wrapper .instructor-single-bottom {
    padding: 30px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .2);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

@media(min-width: 576px) {
    .instructor-single-section .instructor-wrapper .instructor-single-bottom {
        padding: 60px 50px
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-bottom .subtitle {
    margin-bottom: 25px
}

.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item {
    margin-bottom: 30px;
    margin-right: 15px
}

@media(min-width: 992px) {
    .instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item {
        margin-right: 40px;
        margin-bottom: 0
    }
}

@media(min-width: 576px) {
    .instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item:last-child {
        margin-right: 0
    }
}

.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item .skill-thumb .circles {
    position: relative
}

.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item .skill-thumb .circles .circle canvas {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.instructor-single-section .instructor-wrapper .instructor-single-bottom .skill-item .skill-thumb .circles .circle strong {
    text-align: center
}



.event-section * {
    z-index: 1
}

@media(max-width: 991px) {
    .event-section .section-wrapper .event-left .event-item .event-inner .event-content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0
    }
}

.event-section.style-2 .section-wrapper .event-navi-item {
    width: 40px;
    height: 40px;
    line-height: 36px;
    top: 100px;
    z-index: 11;
    cursor: pointer;
    border: 2px solid transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.event-section.style-2 .section-wrapper .event-navi-item:hover,
.event-section.style-2 .section-wrapper .event-navi-item.active {
    border-color: #fff
}

.event-section.style-2 .section-wrapper .event-navi-item.event-slider-next {
    right: -20px
}

.event-section.style-2 .section-wrapper .event-navi-item.event-slider-prev {
    left: -20px
}

.event-section.style-2 .event-sliders {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}


.workshop-join .section-wrapper {
    margin-top: -41px
}

.workshop-join .section-wrapper .workshop-title h5 {
    padding: 10px 30px;
    font-weight: 500;
    border-top-left-radius: 6px
}

.workshop-join .section-wrapper .workshop-title h5::after {
    right: -30px;
    top: 0;
    border-left: 30px solid #17703b;
    border-top: 45px solid transparent
}

@media(max-width: 575px) {
    .workshop-join .section-wrapper .workshop-title h5::after {
        display: none
    }
}

.workshop-join .workshop-join-form {
    padding: 30px;
    box-shadow: 0 10px 15px rgba(85, 85, 85, .3);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

@media(min-width: 992px) {
    .workshop-join .workshop-join-form form .input-area {
        width: calc(100% - 150px)
    }
}

.workshop-join .workshop-join-form form .input-area input {
    width: calc(100% / 1)
}

@media(min-width: 992px) {
    .workshop-join .workshop-join-form form .input-area input {
        width: calc(100% / 3 - 15px);
        margin-bottom: 0
    }
}

.date li {
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    align-content: center
}

@media(max-width: 767px) {
    .date li {
        margin: 10px
    }
}

.newsletters-section {
    background: #f8f8f8
}

.newsletters-section .newsletter-area {
    z-index: 1
}

.newsletters-section .newsletter-area::after {
    width: .5px
}

@media(max-width: 991px) {
    .newsletters-section .newsletter-area::after {
        display: none
    }
}

.newsletters-section .newsletter-area .news-mass {
    width: 70px;
    height: 70px;
    line-height: 70px;
    z-index: 1;
    font-size: 1.875rem;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

@media(max-width: 991px) {
    .newsletters-section .newsletter-area .news-mass {
        display: none
    }
}

.newsletters-section .newsletter-area .news-mass::after,
.newsletters-section .newsletter-area .news-mass::before {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.newsletters-section .newsletter-area .news-mass::before {
    width: 80px;
    height: 80px;
    opacity: .3
}

.newsletters-section .newsletter-area .news-mass::after {
    width: 90px;
    height: 90px;
    opacity: .2
}

.newsletters-section .newsletter-area .news-mass i {
    z-index: 1
}

@media(min-width: 992px) {
    .newsletters-section .newsletter-area .section-header {
        margin-bottom: 0;
        text-align: right;
        padding-right: 60px
    }
}

.newsletters-section .newsletter-area .section-header p {
    max-width: 70%
}

@media(min-width: 992px) {
    .newsletters-section .newsletter-area .section-header p {
        margin-left: auto
    }
}

@media(min-width: 992px) {
    .newsletters-section .newsletter-area .section-wrapper {
        max-width: 60%;
        padding-left: 60px
    }
}

.newsletters-section .newsletter-area .section-wrapper h5 {
    margin-bottom: 30px
}

.newsletters-section.style-2 .newsletter-area .section-header {
    margin-bottom: 60px
}

.newsletters-section.style-2 .newsletter-area .section-header .title {
    margin-top: -14px
}

.newsletters-section.style-2 .newsletter-area .section-header p {
    margin: 0 auto
}

.newsletters-section.style-2 .newsletter-area .section-wrapper {
    margin: 0 auto
}

.newsletter-form input {
    padding: 14px 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.newsletter-form button {
    padding: 15px 30px
}

.feature-section {
    background: #f8f8f8
}


.feature-section * {
    z-index: 1
}

.feature-section.style-2 .section-wrapper .feature-item .feature-inner {
    padding: 15px 0;
    box-shadow: none;
    background: transparent;
    align-items: flex-start
}

@media(max-width: 575px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner {
        text-align: center
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner {
        text-align: center
    }
}

.feature-section.style-2 .section-wrapper .feature-item .feature-inner:hover .feature-icon {
    font-size: 2.5rem
}

.feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border: 1px solid #17703b;
    font-size: 1.875rem;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(min-width: 576px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-icon {
        margin-bottom: 0;
        width: 80px
    }
}

@media(max-width: 575px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-icon {
        margin: 0 auto 30px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-icon {
        margin: 0 auto 30px
    }
}

.feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-content {
    width: calc(100% - 80px);
    padding: 0 15px
}

@media(max-width: 575px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-content {
        width: 100%
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-content {
        width: 100%
    }
}

@media(min-width: 576px) {
    .feature-section.style-2 .section-wrapper .feature-item .feature-inner .feature-content a h5 span {
        display: block
    }
}

.feature-section.style-3 .feature-shape.one {
    top: 0;
    left: 0
}

.feature-section.style-3 .feature-shape.two {
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.feature-section.style-3 .section-wrapper .feature-item .feature-inner .feature-thumb {
    margin: 0 auto 20px
}

.feature-section.style-3 .section-wrapper .feature-register {
    padding: 37px 50px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 24px 46px rgba(85, 85, 85, .3)
}

.feature-section.style-3 .section-wrapper .feature-register form input,
.feature-section.style-3 .section-wrapper .feature-register form select {
    padding: 14px 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}


.clients-section.style-2 {
    background: transparent
}

.clients-section.style-2 .section-header .title {
    margin-top: -14px
}

@media(min-width: 992px) {
    .clients-section.style-2 .section-header p {
        max-width: 550px;
        margin: 0 auto
    }
}

.clients-section.style-2 .section-wrapper {
    height: 400px;
    background: url(../../assets/images/clients/bg.png) no-repeat;
    background-position: center;
    background-size: 75% 100%
}

@media(max-width: 575px) {
    .clients-section.style-2 .section-wrapper {
        background-size: 125% 100%
    }
}

.clients-section.style-2 .section-wrapper .clients .client-list:hover .client-content,
.clients-section.style-2 .section-wrapper .clients .client-list.active .client-content {
    opacity: 1;
    visibility: visible
}

.clients-section.style-2 .section-wrapper .clients .client-list:hover .client-thumb img,
.clients-section.style-2 .section-wrapper .clients .client-list.active .client-thumb img {
    opacity: 1
}

.clients-section.style-2 .section-wrapper .clients .client-list:hover .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:hover .client-thumb::before,
.clients-section.style-2 .section-wrapper .clients .client-list.active .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list.active .client-thumb::before {
    -webkit-animation: circle-2 1.05s infinite;
    -moz-animation: circle-2 1.05s infinite;
    -ms-animation: circle-2 1.05s infinite;
    -o-animation: circle-2 1.05s infinite;
    animation: circle-2 1.05s infinite
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(1) {
    top: 70px;
    left: 20%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(2) {
    top: 40px;
    left: 40%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(2) .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(2) .client-thumb::before {
    background: #7256df
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(3) {
    top: 115px;
    left: 60%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(3) .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(3) .client-thumb::before {
    background: #eb59d5
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(4) {
    top: 265px;
    left: 35%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(4) .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(4) .client-thumb::before {
    background: #37d87b
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(5) {
    top: 210px;
    left: 50%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(5) .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(5) .client-thumb::before {
    background: #fe7855
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(6) {
    top: 288px;
    left: 70%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(6) .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(6) .client-thumb::before {
    background: #ffc313
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(7) {
    top: 75px;
    left: 75%;
    left: 70%
}

.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(7) .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list:nth-child(7) .client-thumb::before {
    background: #26aeea
}

.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb {
    width: 36px;
    height: 36px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::after,
.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::before {
    top: 50%;
    left: 50%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::after {
    width: 25px;
    height: 25px;
    opacity: .3
}

.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb::before {
    width: 15px;
    height: 15px;
    opacity: .5
}

.clients-section.style-2 .section-wrapper .clients .client-list .client-thumb img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    z-index: 1;
    opacity: 0
}

.clients-section.style-2 .section-wrapper .clients .client-list .client-content {
    opacity: 0;
    visibility: hidden;
    width: 140px;
    box-shadow: 0 0 20px rgba(136, 136, 136, .2);
    bottom: 50%;
    right: 50%;
    z-index: 3;
    border: 2px solid #17703b;
    font-weight: 700;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

@media(min-width: 768px) {
    .clients-section.style-2 .section-wrapper .clients .client-list .client-content {
        width: 170px;
        bottom: 50px;
        right: -30px;
        transform: translate(0%, 0%)
    }
}

.clients-section.style-3 {
    background-image: url(../images/testimonialsbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-top: 140px;
    padding-bottom: 140px;
}

.clients-section.style-3:hover .section-wrapper .clients-slider-nav.clients-slider2-next {
    left: 0px
}

.clients-section.style-3:hover .section-wrapper .clients-slider-nav.clients-slider2-prev {
    right: 0px
}

.clients-section.style-3 .section-header .abs-title {
    font-size: 52px;
    font-weight: 900;
    color: #efefef;
    left: 50%;
    bottom: -85px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

@media(min-width: 576px) {
    .clients-section.style-3 .section-header .abs-title {
        font-size: 130px;
        bottom: -160px
    }
}

@media(min-width: 992px) {
    .clients-section.style-3 .section-header .abs-title {
        font-size: 160px;
        bottom: -200px
    }
}

@media(min-width: 1200px) {
    .clients-section.style-3 .section-header .abs-title {
        font-size: 180px;
        bottom: -200px
    }

}


.clients-section.style-3 .section-wrapper .client-item .client-inner {
    background: transparent;
    padding-bottom: 0
}

@media(max-width: 991px) {
    .clients-section.style-3 .section-wrapper .client-item .client-inner {
        padding-top: 0
    }
}

.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb {
    border: none;
    overflow: visible
}

.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.clients-section.style-3 .section-wrapper .client-item .client-inner .client-thumb .quote {
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 0px;
    left: 0px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.clients-section.style-3 .section-wrapper .client-item .client-inner .client-content p {
    line-height: 1.5;
    max-width: 750px;
    margin: 0 auto
}

.clients-section.style-3 .section-wrapper .client-item .client-inner .client-content .client-info .client-degi {
    font-size: small
}

.clients-section.style-3 .section-wrapper .clients-slider-nav {
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.clients-section.style-3 .section-wrapper .clients-slider-nav.clients-slider2-next {
    left: -50px
}

.clients-section.style-3 .section-wrapper .clients-slider-nav.clients-slider2-prev {
    right: -50px
}


@media(max-width: 991px) {
    .register-section .section-header {
        margin-bottom: 20px
    }
}

.register-section .section-header .title {
    font-weight: 500
}

.register-section .section-wrapper {
    margin: auto;
    padding: 50px 30px;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0
}

@media(min-width: 992px) {
    .register-section .section-wrapper {
        max-width: 70%;
        margin-right: 0
    }
}

.register-section .section-wrapper * {
    z-index: 1
}

.register-section .section-wrapper::after {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, #733270, #733270a6, #73327030, #73327000, #73327000)
}

.register-section .section-wrapper .register-form input {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.register-section .section-wrapper .register-form .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

.app-section .section-header .lab-btn {
    background: transparent;
    border: 2px solid #17703b
}

.app-section .section-header .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 223, 64, .5)
}

@media(min-width: 576px) {
    .app-section .section-header p {
        max-width: 550px;
        margin: auto
    }
}

.app-section .section-wrapper ul li {
    margin: 5px
}

@media(min-width: 576px) {
    .app-section .section-wrapper ul li {
        margin: 0;
        margin-right: 10px
    }
    .app-section .section-wrapper ul li:last-child {
        margin-right: 0
    }
}

.student-feedbak-section::before {
    width: 50%;
    height: 100%;
    top: 0;
    border-bottom: 786px solid #17703b;
    border-right: 960px solid transparent
}

@media(min-width: 1440px) {
    .student-feedbak-section::before {
        display: block
    }
}

.student-feedbak-section .section-wrapper .sf-left {
    margin-bottom: 20px
}

@media(min-width: 992px) {
    .student-feedbak-section .section-wrapper .sf-left {
        margin-bottom: 0
    }
}

.student-feedbak-section .section-wrapper .sf-left .sfl-thumb {
    border: 10px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(16, 17, 21, .1)
}


@media(min-width: 1440px) {
    .choose-section::after {
        left: 10%;
        top: 59%;
        width: 55%
    }
}

@media(max-width: 767px) {
    .choose-section::after {
        display: none
    }
}

.choose-section .section-header ul li {
    padding: 5px 0
}

@media(min-width: 992px) {
    .choose-section .section-wrapper .choose-thumb {
        text-align: right
    }
}

.achievement-section .section-header {
    margin-bottom: 30px
}

@media(max-width: 991px) {
    .achievement-section .section-wrapper .counter-part {
        margin-bottom: 50px !important
    }
}

@media(min-width: 992px) {
    .achievement-section.style-2 .section-header {
        margin-bottom: 40px
    }
}

@media(max-width: 991px) {
    .achievement-section.style-2 .section-wrapper .counter-part {
        margin-bottom: 0px
    }
}

.achievement-section.style-2 .section-wrapper .count-item .count-inner {
    padding: 0 30px
}

.achievement-section.style-3 {
    background: #f9f9f9
}



.achievement-section.style-3 .counter-part {
    margin-bottom: 0
}

.achievement-section.style-3 .counter-part .col:nth-child(2) .count-item .count-inner .count-icon::before {
    background: rgba(38, 201, 118, .1)
}

.achievement-section.style-3 .counter-part .col:nth-child(2) .count-item .count-inner .count-icon::after {
    background: rgba(38, 201, 118, .2)
}

.achievement-section.style-3 .counter-part .col:nth-child(3) .count-item .count-inner .count-icon::before {
    opacity: .1
}

.achievement-section.style-3 .counter-part .col:nth-child(3) .count-item .count-inner .count-icon::after {
    opacity: .2
}

.achievement-section.style-3 .counter-part .col:nth-child(4) .count-item .count-inner .count-icon::before {
    opacity: .1
}

.achievement-section.style-3 .counter-part .col:nth-child(4) .count-item .count-inner .count-icon::after {
    opacity: .2
}

.achieve-part .col:last-child .achieve-item .achieve-inner .achieve-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(38, 201, 118, .5)
}

.achieve-part .col .achieve-item .achieve-inner .achieve-content .lab-btn {
    background: #f9e8b0
}

.achieve-part .col .achieve-item .achieve-inner .achieve-content .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(249, 232, 176, .5)
}

.skill-section::before {
    top: 0
}

@media(min-width: 992px) {
    .skill-section::before {
        width: 50%
    }
}

.skill-section .container {
    z-index: 1
}

@media(min-width: 992px) {
    .skill-section .section-header {
        margin-bottom: 0
    }
}

.skill-section .section-header .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(255, 255, 255, .5)
}

.group-select-section {
    padding: 26px 0
}

.group-select-section .group-select-left i {
    font-size: 1.25rem
}

.group-select-section .group-select-left span {
    font-size: 1.125rem;
    font-weight: 500
}

.shop-page .section-header h4 span {
    font-weight: 400
}

.shop-page .sidebar-widget .widget-search {
    border: none;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.shop-page .sidebar-widget .widget-search form input {
    border: 1px solid #ecf0f3
}

.shop-page .shop-title {
    padding: 16px 20px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.shop-page .shop-title .product-view-mode a {
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-page .shop-title .product-view-mode a:last-child {
    margin-left: 5px
}

.shop-page .shop-product-wrap {
    height: auto !important
}

.shop-page .shop-product-wrap.grid {
    height: auto
}

.shop-page .shop-product-wrap.grid .product-list-item {
    display: none
}

.shop-page .shop-product-wrap.list .col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.shop-page .shop-product-wrap.list .product-item {
    display: none
}

.shop-page .shop-product-wrap .product-item,
.shop-page .shop-product-wrap .product-list-item {
    padding: 10px;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-page .shop-product-wrap .product-item:hover .product-thumb .pro-thumb::after,
.shop-page .shop-product-wrap .product-list-item:hover .product-thumb .pro-thumb::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.shop-page .shop-product-wrap .product-item:hover .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-list-item:hover .product-thumb .product-action-link {
    opacity: 1;
    z-index: 9;
    bottom: 50%;
    transform: translate(-50%, 50%)
}

.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb::after,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb::after {
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-page .shop-product-wrap .product-item .product-thumb .pro-thumb img,
.shop-page .shop-product-wrap .product-list-item .product-thumb .pro-thumb img {
    transform-origin: left
}

.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link {
    opacity: 0;
    bottom: -100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link a,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    margin: 5px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-page .shop-product-wrap .product-item .product-thumb .product-action-link a.view-modal,
.shop-page .shop-product-wrap .product-list-item .product-thumb .product-action-link a.view-modal {
    cursor: pointer
}

.shop-page .shop-product-wrap .product-item .product-content,
.shop-page .shop-product-wrap .product-list-item .product-content {
    padding-top: 20px;
    text-align: center
}

.shop-page .shop-product-wrap .product-item .product-content h5,
.shop-page .shop-product-wrap .product-list-item .product-content h5 {
    margin-bottom: 5px
}

.shop-page .shop-product-wrap .product-item .product-content h5 a,
.shop-page .shop-product-wrap .product-list-item .product-content h5 a {
    text-decoration: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-page .shop-product-wrap .product-item .product-content p,
.shop-page .shop-product-wrap .product-list-item .product-content p {
    color: #555;
    margin-bottom: 5px
}

.shop-page .shop-product-wrap .product-item .product-content p i,
.shop-page .shop-product-wrap .product-list-item .product-content p i {
    color: #ecb341
}

@media(min-width: 768px) {
    .shop-page .shop-product-wrap .product-list-item .product-thumb {
        width: 35%
    }
}

.shop-page .shop-product-wrap .product-list-item .product-content {
    text-align: left;
    padding-left: 20px
}

@media(min-width: 768px) {
    .shop-page .shop-product-wrap .product-list-item .product-content {
        width: 65%
    }
}

.shop-page .modal.show {
    display: block;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
    pointer-events: visible;
    z-index: 99999999;
    -webkit-animation: slideInDown .4s forwards;
    animation: slideInDown .4s forwards;
    overflow-y: scroll;
    background: #000
}

.shop-page .modal .modal-dialog .modal-content button.close {
    width: 40px;
    height: 40px;
    top: 0;
    right: 2px;
    background: transparent;
    z-index: 9;
    text-shadow: none;
    opacity: 1;
    outline: none
}

.shop-page .modal .modal-dialog .modal-content .modal-body {
    background: #f6faf9;
    border-radius: 8px
}

.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .product-content {
    margin-top: 30px
}

@media(min-width: 576px) {
    .shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .product-content {
        margin-top: 0
    }
}

.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .product-content p i {
    color: #e9ba17
}

.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .cart-button {
    margin-top: 30px
}

.shop-page .modal .modal-dialog .modal-content .modal-body .product-details-inner .cart-button .lab-btn {
    border-radius: 2px;
    padding: 9px 24px;
    margin-left: 20px;
    margin-top: 0
}

.shop-single .sidebar-widget .widget-search {
    border: none
}

.shop-single .sidebar-widget .widget-search form input {
    border: 1px solid #ecf0f3
}

.shop-single .product-details {
    padding: 20px;
    margin-bottom: 40px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.shop-single .product-details .product-thumb .pro-single-top {
    margin-bottom: 20px
}

.shop-single .product-details .product-thumb .pro-single-top .single-thumb img {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.shop-single .product-details .product-thumb .pro-single-thumbs {
    max-width: 80%;
    margin: auto
}

.shop-single .product-details .product-thumb .pro-single-thumbs .single-thumb img {
    cursor: pointer;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.shop-single .product-details .product-thumb .pro-single-prev,
.shop-single .product-details .product-thumb .pro-single-next {
    cursor: pointer;
    width: 24px;
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    text-align: center;
    background: #17703b;
    top: 50%;
    outline: none;
    transform: translateY(-50%);
    z-index: 1
}

.shop-single .product-details .product-thumb .pro-single-prev {
    right: 0
}

.shop-single .product-details .product-thumb .pro-single-next {
    left: 0
}

.shop-single .product-details .post-content {
    padding-top: 30px
}

@media(min-width: 768px) {
    .shop-single .product-details .post-content {
        padding-top: 0
    }
}

.shop-single .product-details .post-content h6 {
    font-size: 18px;
    font-weight: 500
}

.shop-single .product-details .post-content p.rating i {
    color: #e9ba17
}

.shop-single .product-details .post-content form .select-product {
    margin-bottom: 20px
}

@media(min-width: 576px) {
    .shop-single .product-details .post-content form .select-product {
        width: 48%
    }
}

.shop-single .product-details .post-content form .select-product select {
    height: 40px;
    border: 1px solid #ecf0f3;
    color: #555;
    outline: none;
    z-index: 1;
    background: transparent;
    padding-left: 20px;
    font-size: 14px
}

.shop-single .product-details .post-content form .select-product i {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ecf0f3;
    text-align: center;
    z-index: 0
}

.shop-single .product-details .post-content form .cart-plus-minus {
    margin-bottom: 20px
}

@media(min-width: 576px) {
    .shop-single .product-details .post-content form .cart-plus-minus {
        margin-bottom: 0
    }
}

@media(min-width: 576px) {
    .shop-single .product-details .post-content form .discount-code {
        width: 60%
    }
}

.shop-single .product-details .post-content form .discount-code input {
    border-color: #ecf0f3
}

.shop-single .product-details .post-content form .lab-btn {
    margin-top: 20px;
    box-shadow: none;
    outline: none;
    border: none
}

.shop-single .review {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

@media(min-width: 992px) {
    .shop-single .review {
        margin-bottom: 0
    }
}

.shop-single .review .review-nav li {
    list-style: none;
    padding: 13px 33px;
    background: #06264d;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-right: 10px;
    cursor: pointer
}

.shop-single .review .review-nav li:last-child {
    margin-right: 0
}

.shop-single .review .review-content.review-content-show .review-showing {
    display: block
}

.shop-single .review .review-content.description-show .description {
    display: block
}

.shop-single .review .review-content .review-showing {
    display: none;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s
}

.shop-single .review .review-content .review-showing .content li {
    padding: 25px 30px;
    list-style: none;
    border-bottom: 1px solid #ecf0f3
}

@media(min-width: 768px) {
    .shop-single .review .review-content .review-showing .content li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }
}

.shop-single .review .review-content .review-showing .content li:last-child {
    border-bottom: none
}

.shop-single .review .review-content .review-showing .content li .post-thumb {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden
}

@media(min-width: 768px) {
    .shop-single .review .review-content .review-showing .content li .post-thumb {
        margin-bottom: 0
    }
}

@media(min-width: 768px) {
    .shop-single .review .review-content .review-showing .content li .post-content {
        width: calc(100% - 70px);
        padding-left: 20px
    }
}

.shop-single .review .review-content .review-showing .content li .post-content .entry-meta {
    margin-bottom: 5px
}

.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on {
    width: calc(100% - 110px)
}

.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on a {
    padding-right: 20px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    box-shadow: none;
    line-height: 28px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .posted-on p {
    line-height: 30px
}

.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .rating {
    width: 110px
}

.shop-single .review .review-content .review-showing .content li .post-content .entry-meta .rating i {
    color: #ecb341
}

.shop-single .review .review-content .review-showing .client-review {
    padding: 30px;
    border-top: 1px solid #ecf0f3
}

.shop-single .review .review-content .review-showing .client-review .review-form .review-title {
    margin-bottom: 40px
}

.shop-single .review .review-content .review-showing .client-review .review-form .review-title h5 {
    position: relative
}

.shop-single .review .review-content .review-showing .client-review .review-form .review-title h5::before {
    width: 50px;
    height: 4px;
    bottom: -20px;
    border-radius: 2px
}

.shop-single .review .review-content .review-showing .client-review .review-form .review-title h5::after {
    border-bottom: 4px dotted #17703b;
    width: 20px;
    bottom: -20px;
    left: 55px
}

.shop-single .review .review-content .review-showing .client-review .review-form form input {
    border: 1px solid #ecf0f3;
    padding: 10px
}

@media(max-width: 767px) {
    .shop-single .review .review-content .review-showing .client-review .review-form form .rating {
        margin-bottom: 20px
    }
}

.shop-single .review .review-content .review-showing .client-review .review-form form .rating .rating-title {
    padding-right: 5px
}

.shop-single .review .review-content .review-showing .client-review .review-form form .rating ul li {
    list-style: none
}

.shop-single .review .review-content .review-showing .client-review .review-form form .rating ul li i {
    color: #e9ba17
}

.shop-single .review .review-content .review-showing .client-review .review-form form textarea {
    border: 1px solid #ecf0f3;
    padding: 10px;
    outline: none
}

.shop-single .review .review-content .review-showing .client-review .review-form form button {
    color: #fff;
    font-weight: 500;
    padding: 10px 30px;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.shop-single .review .review-content .description {
    padding: 30px;
    display: none;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s
}

.shop-single .review .review-content .description .post-item {
    flex-direction: row-reverse
}

.shop-single .review .review-content .description .post-item .post-thumb {
    text-align: center
}

@media(min-width: 768px) {
    .shop-single .review .review-content .description .post-item .post-thumb {
        width: calc(100% / 2)
    }
}

.shop-single .review .review-content .description .post-item .post-content {
    padding: 30px 0
}

@media(min-width: 768px) {
    .shop-single .review .review-content .description .post-item .post-content {
        width: calc(100% / 2)
    }
}

.shop-single .review .review-content .description .post-item .post-content ul li {
    padding: 8px 0;
    padding-left: 30px
}

.shop-single .review .review-content .description .post-item .post-content ul li::before {
    content: "";
    top: 8px;
    left: 0;
    color: #17703b;
    font-family: IcoFont !important
}

.shop-single .review .review-content .ratting i {
    color: #17703b
}

.shop-cart .section-wrapper .cart-top {
    margin-bottom: 40px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.shop-cart .section-wrapper .cart-top table {
    font-weight: 700
}

@media(max-width: 767px) {
    .shop-cart .section-wrapper .cart-top table .cat-product .p-content {
        display: none
    }
}

@media(max-width: 767px) {
    .shop-cart .section-wrapper .cart-top table .cat-quantity .cart-plus-minus {
        width: 96px
    }
    .shop-cart .section-wrapper .cart-top table .cat-quantity .cart-plus-minus .cart-plus-minus-box {
        width: 96px
    }
    .shop-cart .section-wrapper .cart-top table .cat-quantity .cart-plus-minus .qtybutton {
        width: 30px
    }
}

@media(max-width: 767px) {
    .shop-cart .section-wrapper .cart-top table .cat-price {
        display: none
    }
}

.shop-cart .section-wrapper .cart-top table thead tr th {
    color: #fff;
    padding: 18px;
    border: none
}

@media(max-width: 575px) {
    .shop-cart .section-wrapper .cart-top table thead tr th {
        font-size: 14px;
        padding: 10px
    }
}

.shop-cart .section-wrapper .cart-top table thead tr th:last-child {
    text-align: right
}

.shop-cart .section-wrapper .cart-top table tbody tr {
    border-bottom: 1px solid #ecf0f3;
    border-right: none;
    border-left: none;
    border-top: none
}

.shop-cart .section-wrapper .cart-top table tbody tr:last-child {
    border-bottom: none
}

.shop-cart .section-wrapper .cart-top table tbody tr td {
    padding: 10px;
    border: none
}

@media(min-width: 768px) {
    .shop-cart .section-wrapper .cart-top table tbody tr td {
        padding: 20px
    }
}

.shop-cart .section-wrapper .cart-top table tbody tr td:last-child {
    text-align: right
}

.shop-cart .section-wrapper .cart-top table tbody tr td.product-item {
    align-items: center
}

.shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-thumb {
    width: 50px;
    margin: auto
}

@media(min-width: 768px) {
    .shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-thumb {
        width: 85px
    }
}

.shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-content {
    width: calc(100% - 85px);
    padding-left: 10px
}

@media(max-width: 767px) {
    .shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-content {
        display: none
    }
}

.shop-cart .section-wrapper .cart-top table tbody tr td.product-item .p-content a {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(max-width: 767px) {
    .shop-cart .section-wrapper .cart-top table tbody tr td.cat-toprice,
    .shop-cart .section-wrapper .cart-top table tbody tr td.cat-edit {
        text-align: center;
        font-size: 14px
    }
}

.shop-cart .section-wrapper .cart-bottom {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box {
    padding: 20px 30px;
    margin-top: 30px
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon {
    max-width: 340px;
    display: flex
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input {
    padding: 10px;
    border: 1px solid #ecf0f3;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .coupon input[type=submit] {
    color: #fff;
    font-weight: 500;
    margin-left: -10px;
    cursor: pointer;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout {
    margin-top: 30px
}

@media(min-width: 992px) {
    .shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout {
        max-width: 390px;
        text-align: right;
        margin-top: 0
    }
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input[type=submit] {
    padding: 10px 30px;
    border: 1px solid #ecf0f3;
    width: auto;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(max-width: 575px) {
    .shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input[type=submit] {
        width: 100%;
        margin-bottom: 15px
    }
    .shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input[type=submit]:last-child {
        margin-bottom: 0
    }
}

.shop-cart .section-wrapper .cart-bottom .cart-checkout-box .cart-checkout input[type=submit]:hover {
    border-color: #17703b
}

.shop-cart .section-wrapper .cart-bottom .shiping-box {
    padding: 30px 20px;
    border-top: 1px solid #fff
}

@media(max-width: 767px) {
    .shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping {
        margin-bottom: 30px
    }
}

.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select {
    margin-bottom: 20px;
    position: relative
}

.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select select {
    padding: 9px 20px;
    background: transparent;
    z-index: 1;

    border: 1px solid #ecf0f3;
    position: relative;
    outline: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

@media(min-width: 992px) {
    .shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select.shipping-select {
        width: 50%;
        float: left
    }
}

.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .outline-select .select-icon {
    top: 0;
    right: 0;
    padding: 9px 20px;
    color: #fff;
    border: 1px solid #ecf0f3;
    border-radius: 0px 3px 3px 0px;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0
}

.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .cart-page-input-text {
    float: right;
    padding: 10px 20px;
    font-size: 14px;
    margin-bottom: 22px;
    border: 1px solid #ecf0f3;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

@media(min-width: 992px) {
    .shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping .cart-page-input-text {
        width: 45%
    }
}

.shop-cart .section-wrapper .cart-bottom .shiping-box .calculate-shiping button {
    width: 170px;
    font-size: 14px;
    color: #fff;
    padding: 10px;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.shop-cart .section-wrapper .cart-bottom .shiping-box .cart-overview ul li {
    list-style: none;
    border: 1px solid #ecf0f3;
    padding: 8px 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

@media(max-width: 991px) {
    .four-zero-section .four-zero-content {
        margin-bottom: 30px
    }
}

.four-zero-section .four-zero-content .title {
    font-size: 42px
}

@media(min-width: 1200px) {
    .four-zero-section .four-zero-content .title {
        font-size: 100px;
        line-height: 1
    }
}

@media(min-width: 576px) {
    .four-zero-section .four-zero-content p {
        font-size: 1.5rem;
        line-height: 1.5
    }
}

@media(min-width: 992px) {
    .map-address-section .section-header {
        max-width: 60%;
        margin: 0 auto 40px
    }
}

@media(min-width: 992px) {
    .map-area {
        margin-bottom: 0
    }
}

.map-area .maps {
    height: 398px;
    border: 10px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

@media(min-width: 1200px) {
    .contact-section .section-header,
    .contact-section .contact-form {
        max-width: 60%;
        margin: 0 auto
    }
}

@media(min-width: 1200px) {
    .contact-section .section-header {
        margin: 0 auto 40px
    }
}

.contact-form {
    justify-content: space-between;
    margin-bottom: -30px
}

.contact-form .form-group {
    margin-bottom: 30px;
    width: 100%
}

@media(min-width: 576px) {
    .contact-form .form-group {
        width: calc(50% - 15px)
    }
}

.contact-form .form-group input {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .06);
    height: 57px;
    padding: 0 25px
}

.contact-form .form-group textarea {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .06);
    padding: 25px;
    color: #391965;
    display: flex
}

.contact-form .form-group button {
    height: 55px
}

@media(max-width: 575px) {
    .contact-form {
        margin-bottom: -20px
    }
    .contact-form .form-group {
        margin-bottom: 20px
    }
}

.course-select-area .csa-title .csa-right span {
    display: inline-block
}

@media(min-width: 576px) {
    .course-select-area .csa-title .csa-right span.total-lecture {
        margin-right: 20px
    }
}

.course-select-area .csa-body ul li .card .card-header h5 button {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #101115;
    text-align: left
}

.course-select-area .csa-body ul li .card .card-header h5 button span {
    font-size: small
}

.course-select-area .csa-body ul li .card .card-header h5 button .icon {
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.course-select-area .csa-body ul li .card .card-header h5 button .icon i {
    font-size: 20px;
    font-weight: 700;
    color: #101115
}

.course-select-area .csa-body ul li .card .card-body .course-lists {
    padding: 10px 1.25rem;
    margin: 0 -1.25rem;
    border-top: 1px solid #ecf0f3
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left {
    padding-left: 20px
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left>i {
    width: 16px;
    height: 16px;
    top: 5px;
    left: 0;
    font-size: 16px
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left>i.complite {
    color: #17703b
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left h6,
.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left p {
    margin-bottom: 0
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left p {
    font-size: 15px;
    line-height: 26px;
    color: rgba(60, 59, 55, .62)
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-left p i {
    margin-right: 5px
}

.course-select-area .csa-body ul li .card .card-body .course-lists .csa-right span.run-watch {
    margin-right: 20px
}

.course-view-section .course-view .video-part .vp-title h3 {
    color: #000
}

.course-view-section .course-view .video-part .vp-video video {
    width: 100%;
    border: none;
    outline: none
}

.course-view-section .course-view .video-part .vp-content h4,
.course-view-section .course-view .video-part .vp-content p {
    color: #000
}

@media(max-width: 991px) {
    .course-view-section .course-view .video-part .vp-author {
        margin-bottom: 30px
    }
}

.course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-thumb {
    margin-bottom: 20px
}

@media(min-width: 576px) {
    .course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-thumb {
        width: 80px;
        height: 80px;
        margin-bottom: 0
    }
}

@media(min-width: 576px) {
    .course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-contnet {
        width: calc(100% - 100px)
    }
}

.course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-contnet h6,
.course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-contnet p {
    color: #000
}

.course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-contnet h6 {
    margin-bottom: 10px
}

@media(min-width: 576px) {
    .course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-contnet h6 {
        margin-bottom: 0
    }
}

.course-view-section .course-view .video-part .vp-author .vpa-item .vpa-inner .vpa-contnet .social-media a {
    margin: 2px;
    font-size: 20px;
    color: #000
}

@media(max-width: 991px) {
    .course-view-section .course-view .video-part .content-wrapper {
        height: 30px;
        overflow: hidden
    }
}

.course-view-section .course-view .video-part .content-wrapper .content-icon {
    top: -5px;
    right: 5px;
    color: #000;
    font-size: 28px
}

.course-view-section .course-view .video-part .content-wrapper.open {
    height: auto
}

.course-view-section .course-view .video-list-area .video-body .course-select-area {
    padding: 30px 15px
}

.course-view-section .course-view .video-list-area .video-body .course-select-area .csa-title h5 {
    color: #000
}

.course-view-section .course-view .video-list-area .video-body .course-select-area .csa-body {
    height: 100vh;
    overflow-y: auto
}

.course-view-section .course-view .video-list-area .video-body .course-select-area .csa-body::-webkit-scrollbar {
    width: 5px;
    background: #29303b
}

.course-view-section .course-view .video-list-area .video-body .course-select-area .csa-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

.course-view-section .course-view .video-list-area .video-body .course-select-area .csa-body::-webkit-scrollbar-thumb {
    background: #17703b;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px
}

.course-view-section .course-view .video-list-area .video-body .course-select-area .csa-body ul {
    margin-right: 5px
}

.overview-announce-section {
    background: #1c222d;
    padding-top: 50px
}

.overview-announce-section .course-view-bottom ul {
    border: none !important;
    margin-bottom: 30px
}

.overview-announce-section .course-view-bottom ul li a {
    border: none;
    color: #fff;
    font-size: 24px;
    padding: 0;
    padding-right: 20px;
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom ul li a::after {
    top: -50px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #17703b;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0)
}

.overview-announce-section .course-view-bottom ul li a.active,
.overview-announce-section .course-view-bottom ul li a:hover {
    color: #fff;
    background: transparent
}

.overview-announce-section .course-view-bottom ul li a.active::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-head h6 {
    color: #fff
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-head p {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li {
    padding: 15px 0;
    border-top: 1px solid #29303b
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-left {
    width: 100%
}

@media(min-width: 576px) {
    .overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-left {
        width: 200px
    }
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-left p {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li.fullview .overview-right.overview-description {
    height: auto
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li.fullview .view-details {
    background: none;
    padding-top: 0px;
    bottom: -5px
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li.fullview .view-details span.more {
    display: none
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li.fullview .view-details span.less {
    display: block
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right {
    width: 100%
}

@media(min-width: 576px) {
    .overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right {
        width: calc(100% - 200px)
    }
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right.overview-description {
    height: 70px
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items {
    padding: 5px 0
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items .or-left,
.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items .or-right {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items .or-left {
    width: 100px
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right .or-items .or-left:after {
    content: ":";
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right p {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right h6 {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right ul {
    padding-left: 20px
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .overview-right ul li {
    color: rgba(183, 198, 210, .62);
    padding: 5px 0;
    list-style: disc;
    border: none;
    overflow: visible
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .view-details {
    bottom: 10px;
    left: 0;
    background: linear-gradient(rgba(28, 34, 45, 0.17), rgba(28, 34, 45, 0.9), #1c222d);
    width: 100%;
    padding-top: 20px
}

@media(min-width: 576px) {
    .overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .view-details {
        left: 190px
    }
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .view-details span {
    color: #fff
}

.overview-announce-section .course-view-bottom .tab-content .overview-area .overview-body ul li .view-details span.less {
    display: none
}

@media(min-width: 992px) {
    .overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists {
        width: 75%
    }
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists ul li {
    padding: 15px 0
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top {
    width: 100%
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items {
    justify-content: space-between
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-thumb {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content {
    width: calc(100% - 75px)
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content h5 {
    color: #b7c6d2
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content p {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content p span {
    margin-left: 15px;
    padding-left: 15px;
    font-size: 16px;
    display: inline-block;
    font-weight: 400
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-top .ap-items .ap-content p span::after {
    left: 0;
    top: 50%;
    width: 4px;
    height: 4px;
    background: rgba(60, 59, 55, .62);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-bottom h4 {
    color: #b7c6d2;
    margin-bottom: 10px
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .administer-post .ap-bottom p {
    color: rgba(183, 198, 210, .62)
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe {
    justify-content: space-between
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-thumb {
    width: 52px;
    height: 52px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content {
    width: calc(100% - 70px)
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content form textarea {
    background: #29303b;
    border-color: transparent;
    color: rgba(183, 198, 210, .62);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content h6 {
    color: #b7c6d2;
    margin-bottom: 0px
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content h6 span {
    margin-left: 15px;
    padding-left: 15px;
    font-size: 16px;
    display: inline-block;
    font-weight: 400
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content h6 span::after {
    left: 0;
    top: 50%;
    width: 4px;
    height: 4px;
    background: rgba(60, 59, 55, .62);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.overview-announce-section .course-view-bottom .tab-content .announcement-area .announcement-lists .student-reviwe .student-content p {
    color: rgba(183, 198, 210, .62);
    margin-bottom: 0
}

.authors {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1);
    justify-content: space-between;
    align-items: center;
    padding: 30px 25px;
    margin: 30px 0
}

@media(max-width: 575px) {
    .authors {
        justify-content: center
    }
}

.authors .author-thumb {
    width: 150px;
    height: 150px
}

.authors .author-thumb img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.authors .author-content {
    width: 100%;
    margin-top: 30px;
    text-align: center
}

@media(min-width: 576px) {
    .authors .author-content {
        width: calc(100% - 180px);
        margin-top: 0;
        text-align: left
    }
}

.authors .author-content h5 {
    margin-bottom: 2px
}

.authors .author-content span {
    font-size: 1.125rem
}

.authors .author-content p {
    font-size: 17px
}

.authors .author-content .scocial-media {
    margin-bottom: 0
}

.authors .author-content .scocial-media a {
    font-size: 1rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: transparent
}

.authors .author-content .scocial-media a.facebook {
    color: #3b5998
}

.authors .author-content .scocial-media a.twitter {
    color: #55acee
}

.authors .author-content .scocial-media a.linkedin {
    color: #007bb5
}

.authors .author-content .scocial-media a.vimeo {
    color: #3b5998
}

@media(max-width: 575px) {
    .authors .author-content .social-icons {
        justify-content: center
    }
}

.course-side-detail .csd-title {
    padding: 15px
}

.course-side-detail .csd-title .csdt-left sup {
    top: -5px
}

.course-side-detail .csd-content .sidebar-payment {
    padding: 15px 20px 5px
}

.course-side-detail .csd-content .sidebar-social {
    padding: 15px 20px 5px
}

.course-side-detail .csd-content .sidebar-social .ss-content ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 3px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.course-side-detail .csd-content .course-enroll {
    padding: 15px 20px
}

.course-side-detail .csd-content .course-enroll .lab-btn:hover {
    box-shadow: 0 5px 10px rgba(38, 201, 118, .3)
}

.csdc-lists ul li {
    font-size: 1.125rem;
    padding: 15px 20px
}

.csdc-lists ul li:nth-child(even) {
    background: #f9f9f9
}

.course-side-cetagory .csc-title {
    padding: 19px 30px
}

.course-side-cetagory .csc-title h5 {
    font-weight: 600
}

.course-side-cetagory .csc-content .csdc-lists ul li .csdc-left a,
.course-side-cetagory .csc-content .csdc-lists ul li .csdc-right {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.title-border {
    font-size: 1.25rem;
    margin: 0 0 28px 0;
    padding: 20px 30px;
    border-bottom: 1px solid #ecf0f3
}

@media(min-width: 992px) {
    .title-border {
        padding: 20px 30px
    }
}

.navigations-part {
    padding: 25px;
    margin: 30px 0
}

.navigations-part .left {
    margin: 0
}

@media(min-width: 768px) {
    .navigations-part .left {
        width: calc(100% / 2)
    }
}

@media(max-width: 575px) {
    .navigations-part .right {
        margin-top: 20px
    }
}

@media(min-width: 768px) {
    .navigations-part .right {
        width: calc(100% / 2)
    }
}

@media(min-width: 768px) {
    .navigations-part .left .title,
    .navigations-part .right .title {
        max-width: 90%
    }
}

.navigations-part .left .next,
.navigations-part .left .prev,
.navigations-part .right .next,
.navigations-part .right .prev {
    font-weight: 700
}

.comments {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

@media(min-width: 992px) {
    .comments .comment-list {
        padding: 20px 30px
    }
}

@media(max-width: 575px) {
    .comments .comment-list {
        margin: 0
    }
}

.comments .comment-list .comment .com-thumb {
    height: 50px;
    width: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

@media(min-width: 576px) {
    .comments .comment-list .comment .com-thumb {
        margin-bottom: 0
    }
}

@media(min-width: 768px) {
    .comments .comment-list .comment .com-thumb {
        height: 80px;
        width: 80px
    }
}

.comments .comment-list .comment .com-content {
    border-bottom: 1px solid #ecf0f3;
    width: calc(100% - 100px)
}

@media(max-width: 575px) {
    .comments .comment-list .comment .com-content {
        width: 100%
    }
}

.comments .comment-list .comment .com-content:last-child {
    border-bottom: none
}

@media(min-width: 576px) {
    .comments .comment-list .comment .com-content .com-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center
    }
}

.comments .comment-list .comment .com-content .com-title .com-title-meta h6 a {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

@media(max-width: 575px) {
    .comments .comment-list .comment .com-content .com-title .reply {
        margin-bottom: 10px;
        display: inline-block
    }
}

.comments .comment-list .comment .com-content .com-title .reply .comment-reply-link {
    font-weight: 600;
    font-size: 14px
}

@media(min-width: 576px) {
    .comments .comment-list .comment .comment-list {
        padding-left: 30px
    }
}

@media(max-width: 575px) {
    .comments .comment-list .comment .comment-list {
        padding: 0px
    }
}

.comments .comment-list .comment .comment-list .comment {
    border-bottom: 1px solid #ecf0f3;
    padding: 20px 0px
}

.comments .comment-list .comment .comment-list .comment:last-child {
    border: none
}

.comment-respond {
    margin: 30px 0;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.comment-respond .add-comment {
    padding: 10px 30px 40px
}

.comment-respond .add-comment .comment-form input[type=text] {
    padding: 10px 15px
}

@media(min-width: 576px) {
    .comment-respond .add-comment .comment-form input[type=text] {
        width: calc(100% / 2 - 15px)
    }
}

@media(min-width: 768px) {
    .comment-respond .add-comment .comment-form input[type=text]:nth-child(3) {
        width: 100% !important
    }
}

.comment-respond .add-comment .comment-form p.comment-form-cookies-consent input {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px)
}

.blog-single .section-wrapper .post-item .post-inner .post-content {
    padding: 50px 30px
}

.blog-single .section-wrapper .post-item .post-inner .post-content iframe {
    height: 370px
}

.blog-single .section-wrapper .post-item .post-inner .post-content blockquote {
    margin: 0 0 20px
}

.blog-single .section-wrapper .post-item .post-inner .post-content blockquote::before {
    width: 40px;
    height: 40px;
    content: "";
    font-size: 1.875rem;
    font-family: IcoFont !important
}

@media(max-width: 767px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content blockquote::before {
        display: none
    }
}

@media(min-width: 768px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content blockquote p {
        padding-left: 60px;
        font-size: 18px;
        line-height: 30px
    }
}

.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section {
    margin: 40px -30px -25px;
    padding: 23px 30px 0;
    border-top: 1px solid #ecf0f3
}

@media(max-width: 767px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags {
        margin-bottom: 15px
    }
}

@media(min-width: 768px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags {
        width: calc(100% / 2)
    }
}

.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li {
    margin: 5px
}

@media(min-width: 992px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li:first-child {
        margin-left: 0
    }
}

.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a {
    padding: 5px 18px;
    border: 1px solid #ecf0f3;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a:hover,
.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a .active {
    border-color: #17703b
}

.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media {
    width: calc(100% / 1)
}

@media(min-width: 576px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media {
        text-align: right;
        margin-top: 0
    }
}

@media(min-width: 768px) {
    .blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .scocial-media {
        width: calc(100% / 2)
    }
}

.widget {
    padding: 25px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.widget .widget-header {
    padding: 25px 25px 0;
    border-bottom: 1px solid #ecf0f3
}

.widget .widget-header .title {
    font-size: 1.25rem
}

.widget .widget-wrapper {
    padding: 15px 0
}

.widget .widget-wrapper li {
    list-style: none;
    border-bottom: 1px solid #ecf0f3;
    padding: 15px 25px
}

.widget .widget-wrapper li:last-child {
    border-bottom: none
}

@media(max-width: 991px) {
    .widget.widget-search {
        margin-top: 30px
    }
}

.widget.widget-search .search-wrapper input {
    height: 50px;
    padding-left: 10px;
    line-height: 50px;
    margin-bottom: 0;
    border: none;
    width: 100%;
    outline: none
}

.widget.widget-search .search-wrapper button {
    top: 0;
    right: 0;
    line-height: 50px;
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    font-size: 1.25rem
}

.widget.widget-archive,
.widget.widget-category {
    padding: 0
}

.widget.widget-archive .widget-wrapper li a,
.widget.widget-category .widget-wrapper li a {
    padding: 15px 25px
}

.widget.widget-post ul.widget-wrapper li .post-thumb {
    width: 90px;
    height: 80px
}

.widget.widget-post ul.widget-wrapper li .post-thumb a {
    display: inline-flex
}

.widget.widget-post ul.widget-wrapper li .post-content {
    width: calc(100% - 110px)
}

.widget.widget-post ul.widget-wrapper li .post-content a h6 {
    line-height: 1.2;
    font-weight: 500;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.widget.widget-instagram ul.widget-wrapper {
    padding: 25px 20px
}

.widget.widget-instagram ul.widget-wrapper li {
    width: calc(100% / 3);
    border: none
}

.widget.widget-instagram ul.widget-wrapper li a {
    margin: 5px
}

/* .widget.widget-tags ul.widget-wrapper {
    padding: 20px
} */

.widget.widget-tags ul.widget-wrapper li {
    border: none
}

.widget.widget-tags ul.widget-wrapper li a {
    padding: 5px 10px;
    border: 1px solid #ecf0f3;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    margin: 5px;
    font-size: 14px
}

.widget.widget-tags ul.widget-wrapper li a.active,
.widget.widget-tags ul.widget-wrapper li a:hover {
    border-color: #fff
}

.widget.recipe-categori .widget-wrapper {
    padding: 25px 15px
}

.widget.recipe-categori .widget-wrapper .recipe-item {
    border: 1px dotted #ecf0f3;
    border-width: 1px;
    border-top: none;
    border-right: none;
    margin: 5px
}

.widget.shop-widget {
    box-shadow: 0 0 10px rgba(136, 136, 136, .1);
    margin-top: 35px
}

.widget.shop-widget .widget-wrapper .shop-menu li {
    list-style: none;
    border-bottom: 1px solid #ecf0f3;
    padding: 12px 15px
}

.widget.shop-widget .widget-wrapper .shop-menu li:last-child {
    border-bottom: none
}

.widget.shop-widget .widget-wrapper .shop-menu li a {
    font-weight: 500
}

.widget.shop-widget .widget-wrapper .shop-menu li a.show::before {
    display: none
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu {
    margin: 0;
    display: none;
    transform: translate(0px, 0px) !important;
    position: static !important
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu.show {
    display: block;
    padding: 10px;
    border: none
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li {
    padding: 2px 0;
    border-bottom: none
}

.widget.shop-widget .widget-wrapper .shop-menu li .shop-submenu li a {
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.widget.letest-product {
    margin-top: 35px;
    box-shadow: 0 0 10px rgba(136, 136, 136, .1)
}

.widget.letest-product .widget-wrapper ul li {
    padding: 15px;
    list-style: none;
    border-bottom: 1px solid #ecf0f3
}

.widget.letest-product .widget-wrapper ul li:last-child {
    border-bottom: none
}

.widget.letest-product .widget-wrapper ul li:hover .product-thumb img {
    transform: scale(1.1)
}

.widget.letest-product .widget-wrapper ul li .product-thumb {
    width: 70px;
    height: 70px
}

@media(min-width: 992px)and (max-width: 1199px) {
    .widget.letest-product .widget-wrapper ul li .product-thumb {
        width: 100%;
        height: 100%;
        margin-bottom: 15px
    }
}

.widget.letest-product .widget-wrapper ul li .product-thumb img {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.widget.letest-product .widget-wrapper ul li .product-content {
    width: calc(100% - 70px)
}

@media(min-width: 992px)and (max-width: 1199px) {
    .widget.letest-product .widget-wrapper ul li .product-content {
        width: 100%;
        margin-bottom: 15px
    }
}

.widget.letest-product .widget-wrapper ul li .product-content h6 a {
    font-weight: 500;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease
}

.widget.letest-product .widget-wrapper ul li .product-content p i {
    font-size: 12px;
    color: #e9ba17
}

.widget.letest-product .widget-wrapper ul li .product-content p span {
    font-size: 14px
}

footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title {
    margin-top: -8px
}

@media(min-width: 576px) {
    footer.style-2 .footer-top .footer-item .footer-inner .footer-content .title {
        margin-bottom: 15px
    }
}

footer.style-2 .footer-top .footer-item .footer-inner .footer-content .content ul li {
    padding: 3px 0
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content p {
    font-size: 1rem
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.office-address li {
    padding: 2px 0;
    font-weight: 500
}

footer.style-2 .footer-top .footer-item.our-address .footer-inner .footer-content .content ul.social-icons li a {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li i {
    font-size: 1.5rem;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px)
}

footer.style-2 .footer-top .footer-item.twitter-post .footer-inner .footer-content .content ul li p {
    width: calc(100% - 35px);
    font-size: 1rem
}



.footer-top .footer-item {
    margin: 15px 0
}

@media(min-width: 992px) {
    .footer-top .footer-item {
        margin: 0
    }
}

.footer-top .footer-item .footer-inner .footer-content .title {
    margin-top: -8px
}

.footer-top .footer-item .footer-inner .footer-content .title h4::after {
    width: 50px;
    height: 2px;
    bottom: -15px;
    left: 0
}

.footer-top .footer-item .footer-inner .footer-content .content ul li {
    padding: 8px 0
}

.footer-top .footer-item.our-address .footer-inner .footer-content .content ul li i {
    margin-top: 10px
}

.footer-bottom {
    padding: 35px 0;
    background: #fff
}

.footer-bottom .section-wrapper p {
    text-align: center;
    margin-bottom: 0
}

.footer-bottom .section-wrapper p a {
    padding: 5px;
    font-weight: 600
}

.footer-bottom .section-wrapper p a:hover {
    color: #17703b
}

.news-footer-wrap {
    background: #1f1f33
}

@media(max-width: 1439px) {
    .news-footer-wrap .fs-shape {
        display: none
    }
}

.news-footer-wrap .fs-shape img.fst-1 {
    top: 0;
    left: -60px
}

.news-footer-wrap .fs-shape img.fst-2 {
    bottom: 104px;
    right: 0
}

.news-letter {
    padding: 60px 0
}

.news-letter .section-wrapper {
    border-bottom: 1px solid #363648;
    padding-bottom: 40px;
    z-index: 1
}

@media(min-width: 992px) {
    .news-letter .section-wrapper {
        margin-bottom: 40px;
        padding-bottom: 60px
    }
}

@media(min-width: 1200px) {
    .news-letter .section-wrapper {
        justify-content: space-between;
        text-align: left
    }
}

.news-letter .section-wrapper .news-title {
    margin-bottom: 30px
}

@media(min-width: 1200px) {
    .news-letter .section-wrapper .news-title {
        width: 40%;
        margin-bottom: 0
    }
}

.news-letter .section-wrapper .news-form {
    width: 100%
}

@media(min-width: 1200px) {
    .news-letter .section-wrapper .news-form {
        width: calc(100% - 50%)
    }
}

@media(min-width: 576px) {
    .news-letter .section-wrapper .news-form form .nf-list {
        background: #fff
    }
}

.news-letter .section-wrapper .news-form form .nf-list::after {
    content: "";
    width: 30px;
    height: 30px;
    top: 18px;
    left: 10px;
    font-size: 1.5rem;
    font-family: IcoFont !important
}

@media(min-width: 576px) {
    .news-letter .section-wrapper .news-form form .nf-list::after {
        top: 50%;
        transform: translateY(-50%)
    }
}

.news-letter .section-wrapper .news-form form .nf-list input {
    box-shadow: none;
    border: none;
    outline: none
}

.news-letter .section-wrapper .news-form form .nf-list input[type=email] {
    padding: 20px;
    padding-left: 50px;
    width: 100%
}

@media(min-width: 576px) {
    .news-letter .section-wrapper .news-form form .nf-list input[type=email] {
        padding-right: 170px
    }
}

@media(min-width: 1200px) {
    .news-letter .section-wrapper .news-form form .nf-list input[type=email] {
        width: auto
    }
}

.news-letter .section-wrapper .news-form form .nf-list input[type=submit] {
    width: 200px;
    font-size: 1rem;
    font-weight: 700;
    width: 162px;
    height: 54px;
    border: none;
    outline: none;
    margin-top: 20px
}

@media(min-width: 576px) {
    .news-letter .section-wrapper .news-form form .nf-list input[type=submit] {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        margin-top: 0
    }
}



@media (max-width: 580px) and (min-width: 320px){
    .headercnt {
        display: none;
    }
    .bnrtext {
        margin-top: 0px !important;
        margin-right: 0px !important;
    }
    .abtbox2 {
        border-left: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-left-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 50px;
        font-weight: bold;
        border-top-left-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 13px;
        padding-right: 10px;
        margin-bottom: 5px;
    }
    .abtbox3 {
        border-right: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-right-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 43px;
        font-weight: bold;
        border-top-right-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 10px;
        margin-bottom: 5px;
    }
    .abtbox2 {
        border-left: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-left-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 50px;
        font-weight: bold;
        border-top-left-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 13px;
        padding-right: 10px;
        margin-bottom: 5px;
    }
    div#wrong {
        padding-top: 30px;
    }
    .abtbox1 {
        border-right: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-right-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 43px;
        font-weight: bold;
        border-top-right-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 10px;
        margin-bottom: 5px;
    }
    section.newcontct .container{
        margin-top: 0px;
    }
    section.faqsec {
        padding-top: 40px;
    }
    .react-responsive-modal-modal {
        padding: 30px;
        border-radius: 10px;
    }
    section.jobseekr h2 {
        text-align: left;
        margin-bottom: 10px;
    }
    p#booterheader {
        font-size: 16px;
        line-height: 26px;
    }
    div#jobhiring {
        margin-top: 10px;
    }
    p#booterheader {
        font-size: 16px;
        line-height: 26px;
    }
    section.jobseekr {
        padding-top: 40px;
        padding-bottom: 10px;
    }
    .jbeeker {
        padding: 30px 30px 30px 30px;
        height: unset;
        margin-bottom: 30px;
        margin-left: unset;
        background: #17703b;
        border-radius: 10px;
    }
    img.jobseeker {
        display: none;
    }
    .jobseeker-content {
        margin-left: unset;
    }
    img.companyhiring {
        display: none;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: unset;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: unset;
    }
    .companyhiring-content {
        margin-right: unset;
    }
    .banner-section .all-shapes {
        width: 250px;
        height: 250px;
        border-radius: 100%;
        border-width: 1.75px;
        top: 74%;
        right: 11%;
        background: #ecf3ef;
        animation: rotate 50s infinite linear;
        box-shadow: 0px 5px 10px 0px #ecf3ef;
    }
    section.Updatefaq .accordion-header {
        text-align: center;
        margin-bottom: 10px;
        line-height: 25px;
    }
    section.Updatefaq .container {
        padding: 0px;
    }
    div#accUpdatefaq {
        padding: 0;
    }
    section.Updatefaq {
        padding-top: 25px;
    }
    section.faqsec {
        padding-bottom: 40px;
    }
    img.hire {
        border: 1px solid #e4e4e491;
        padding: 10px;
        width: 25%;
    }
    section.blogsection {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    h3.contus {
        margin-bottom: 20px;
    }
    .thankyoubox img {
        width: 45%;
        margin-bottom: 30px;
    }
    section.thankyou {
        padding-bottom: 70px;
   padding-top: 120px;
 }
    .thankyoubox {
        background: #fff;
        padding: 50px 20px;
        width: 100%;}
    .pageblog {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    section.blogsection .post-inner {
        border-radius: 10px;
        box-shadow: 0 5px 10px rgb(16 17 21 / 10%);
        transition: all .3s ease;
        margin-bottom: 40px;
    }
    .dlab-post-media.dlab-img-effect {
        margin-top: 40px;
    }
    .insd-section {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    section.hiringserivce9 {
        padding-top: 0px;
        padding-bottom: 40px;
    }
    section.hiringserivce7 {
        padding-top: 40px;
    }
    section.alsersections8 {
        padding-top: 40px;
    }
    section.alsersections4 {
        padding-top: 40px;
    }
    section.hiringserivce5 {
        padding-top: 0px;
    }
    section.serpgsec6 {
        padding-top: 40px;
    }
    section.hiringserivce3 .react-tabs__tab {
        transition: 0.3s all ease-in-out;
        display: inline-block;
        border: 1px solid #f2f7f5;
        border-bottom: none;
        bottom: unset;
        line-height: 23px;
        margin-bottom: 15px;
        position: relative;
        list-style: none;
        text-align: center;
        margin-right: 10px;
        width: 100%;
        border-radius: 5px;
        height: 138px;
        background: #f2f7f5;
    }
    section.hiringserivce3 {
        padding-top: 40px;
    }
    .hrtabcontent br {
        display: none;
    }
    .top-notch {
        box-shadow: 0 0 10px rgb(136 136 136 / 10%);
        margin-right: unset;
        margin-bottom: 30px;
    }
    section.serpgsec3 {
        padding-top: 30px;
    }
    .timeline > li > .timeline-badge {
        color: #353535;
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 1.1em;
        font-weight: 700;
        text-align: center;
        position: absolute;
        top: 22%;
        left: 3%;
        margin-left: -18px;
        background-color: #fff;
        z-index: 100;
        border-top-right-radius: 10%;
        border-top-left-radius: 10%;
        border-bottom-right-radius: 10%;
        border-bottom-left-radius: 10%;
    }
    div#headintb {
        padding-top: 0px;
    }
    section.hiringserivce {
        padding-bottom: 0px;
    }
    .react-tabs__tab {
        transition: 0.3s all ease-in-out;
        display: inline-block;
        border: 1px solid #f2f7f5;
        border-bottom: none;
        bottom: unset;
        margin-bottom: 15px;
        position: relative;
        list-style: none;
        text-align: center;
        margin-right: 10px;
        width: 100%;
        border-radius: 5px;
        height: 140px;
        background: #f2f7f5;
    }
    .react-tabs__tab-panel--selected {
        display: block;
        margin-left: 0px;
    }
    section.alsersections {
        padding-bottom: 0px;
    }
    div#perfect {
        background: #fff;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: unset;
        border-radius: 16px;
        box-shadow: unset;
        padding: 40px 20px 40px 20px;
        z-index: -111;
    }
h6.How {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
}
    .cacilds h2 {
        color: #fff;
        margin-bottom: 15px;
        font-size: 25px;
    }
    .cacilds {
        background: #17703b;
        padding: 30px 20px 10px 20px;
        border-radius: 10px;
        box-shadow: 0 6px 25px 0 #17703b26;
    }
    section.bottom.sersec {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    h2.Privacy {
        margin-bottom: 10px;
    }
    section.Privacypolicy2 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    section.Privacypolicy {
    background: #f2f7f5;
    padding: 130px 0px 60px 0px;
    margin-bottom: 10px;
}
    h1.Privacy {
        text-align: left;
        font-size: 30px;
    }
    section.abtbnner h1 br {
        display: none;
    }
    section.Servicepagebanner {
        padding-top: 130px;
        padding-bottom: 50px;
    }
    section.Servicepagebanner h1 {
        text-align: left;
        font-size: 30px;
    }
    section.second.footer {
        background: #f2f7f5;
        padding: 30px 0px;
    }
    ul#Reserved {
        float: left;
    }
    section.second.footer form#myForm {
        width: 100%;
    }
    div#candidate {
        margin-top: 0px;
    }
    div#lasthiring {
        margin-top: 0px;
    }
    .feature-register input {
        outline: none;
        border: 1px solid #d9d9da;
        margin-bottom: 5px;
        border-radius: 5px;
        line-height: 15px;
        font-size: 14px;
    }
    section.contactsec .feature-register {
        margin-top: 0px;
    }
    section.contactsec .feature-register {
        margin-top: 0px;
        padding: 20px;
    }
    section.process {
        padding-bottom: 20px;
    }
    .swiper-Industriesserve {
        z-index: 111111;
        background: #fafafa;
        padding: 10px 20px;
        margin-top: 40px;
        width: 100%;
        border: 1px solid #7777770f;
        height: 120px;
        text-align: center;
        margin-bottom: 40px;
    }
    img.hire2 {
        border: 1px solid #e4e4e491;
        padding: 10px;
        width: 25%;}
    .homeform {
        margin-left: 0px;
    }
    .swiper-Industriesserve::before{
        display: none;
    }
	    .swiper-Industriesserve::after{display: none;}
    .contact-item {
        padding: 26px 30px;
        box-shadow: 0 0px 10px rgb(136 136 136 / 10%);
        width: 100%;
        background: #17703b;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .section-cont {
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .section-cont .feature-register {
        margin-bottom: 30px;
    }
    p.Are {
        text-align: left;
        margin-top: 10px;
        font-size: 16px;
    }
    section.contactbner h1 {
        text-align: left;
        font-size: 30px;
    }
    section.contactbner {
        padding-top: 130px;
        padding-bottom: 50px;
    }
    .clients-section {
        padding-bottom: 40px;
    }
    div#blackcounter1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .section-header .subtitle {
        font-size: 1.125rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #17703b;
        font-weight: 500;
        transform: translateY(-8px);
    }
    .jobs h6 {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;
    }
    .jobs {
        margin-left: 10px;
    }
    div#blackcounter1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    section.hrounter h4 {
        background: #17703b;
        padding: 20px;
        border: 1px solid #e7f5ef;
        border-radius: 10px 10px 0px 0px;
        margin-bottom: 30px;
        color: #fff;
        font-size: 16px;
    }
    section.hrounter {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    div#blackcounter {
        padding: 0px 15px 20px 15px;
        box-shadow: 0px 5px 20px 3px #edf4f1;
        border-radius: 0px 0px 10px 10px;
    }
    img.cert {
        margin-top: 40px;
    }
    section#Certificates br {
        display: none;
    }
    section.abtsec3 p {
        text-align: left;
    }
    section.abtsec4 h6 {
        padding: 5px 0px;
        font-size: 16px;
    }
    .sponsor-section {
        padding: 20px 0;
    }
    section.abtsec3 h2 {
        margin-bottom: 15px;
        text-align: left;
    }
    .Expenses {
        padding-top: 40px;
        padding-right: 0px;
    }
    section.abtsec3 {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    section.abtsec2 {
        background: #f2f7f5;
        padding-top: 0px;
        margin-top: 50px;
        padding-bottom: 30px;
        position: relative;
        z-index: -111111;
    }
    img.visi {
        margin-top: 20px;
        max-width: 100%;
    }
    div#tean-right {
        position: relative;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: unset;
        margin-top: 30px;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 20px 20px 20px 20px;
        z-index: 111;
    }
    img.hrteam {
        left: unset;
        border-radius: 10px;
        width: 100%;
        margin-top: 21px;
        position: relative;
        z-index: 11111;
    }
    div#tean {
        margin-top: 0px !important;
        margin: 0;
        text-align: left !important;
    }
    section.abtbnner h1 {
        text-align: left;
        font-size: 30px;
    }
    p.working {
        font-size: 16px;
        line-height: 26px;
        text-align: left;
    }
    p.working br {
        display: none;
    }
    section.abtsec1 {
        padding-top: 40px;
        z-index: -1111;
        position: relative;
    }
    section.abtbnner {
          padding-top: 130px;
        padding-bottom: 50px;
    }

    .abtbotmbox {
        display: none;
    }

    .Industries {
        padding-top: 18px;
    }
    .sponsor-slider {
        text-align: center;
      }
  
    div#company {
        padding: 0px;
    }
    .box-content {
        width: 100%;
        padding: 20px 15px;}
    .arrows section .box-title:before {
               top: 10px;
               left: 10px;
    }
    .arrows section .box-title {
            padding-left: 25px;
        height: auto;
        font-size: 16px;
        line-height: 26px;
        padding-top: 10px;
        padding-bottom: 10px;}

            .box-close {
            height: auto;
             overflow: hidden;}
    .bnrtext h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }
  
    .bnrtext1 h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .bnrtext1 h1 {
        font-size: 32px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 41px;
        margin-bottom: 22px;
    }
    .bnrtext h1 {
        font-size: 32px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 41px;
        margin-bottom: 22px;
    }
    img#Smilinggirl {
        margin-top: 30px;
    }
    section.Myhomesec1 {
        padding-top: 40px;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
    }
    h2 {
        font-size:30px;
        line-height: 40px;
    }
    section.featuressec1 {
        padding: 10px;
    }
    .serviceBox h3.title {
        font-size: 16px;
     }
     span.featspan {
        margin-left: -8px;
    }
    section.industries {
        text-align: left;
        padding-top: 10px;
    }
    img.gentleman {
        display: none;
    }
    .allserivces {
        margin-bottom: 30px;
        padding: 30px 30px 30px 30px;
    }
    .services .box {
        margin-bottom: 20px;
    }
    section.process h2 {
     margin-top: 0px;
    }
    section.process {
        padding-top: 0px;
    }
    section.services {
        padding-bottom: 40px;
        padding-top: 40px;
        background: #f2f7f5;
        margin-top: 0px;
    }
    img.newhr {
        margin-top: 30px;
    }
    .clients-section.style-3.padding-tb {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    section.contactsec {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    section.newcontctbottm {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .feature-register {
        padding: 30px 30px 75px 30px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 6px 25px 0 #17703b26;
        margin-left: 0px;
    }
    img.contctlady {
        display: none;
    }
   div#Expense {
    padding: 40px 10px;
}
    .client-item .client-inner {
        padding: 0px 10px;
    }
    .clients-section.style-3 .section-wrapper .client-item .client-inner .client-content p {
        font-size: 16px;
        line-height: 26px;
    }
    section.faqsec {
        padding-left: 10px;
        padding-right: 10px;
          }
          section.faqsec h2 {
            text-align: left;
              }
              p.organiation {
                text-align: left;
            }
            section.faqhome {
                max-width: unset;
                margin: 0 auto;
                padding-top: 10px;
                padding-bottom: 50px;
            }
}
@media (max-width: 767px) and (min-width: 581px){
    .bnrtext {
        margin-top: 10px;
        margin-right: 0px;
    }
    .abtbox2 {
        border-left: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-left-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 50px;
        font-weight: bold;
        border-top-left-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 13px;
        padding-right: 10px;
        margin-bottom: 5px;
    }
    .abtbox1 {
        border-right: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-right-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 43px;
        font-weight: bold;
        border-top-right-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 10px;
        margin-bottom: 5px;
    }
    .abtbox3 {
        border-right: 10px solid #29733f;
        background: #ffffff;
        display: flow-root;
        border-bottom-right-radius: 50px;
        padding: 25px 10px 10px 15px;
        font-size: 43px;
        font-weight: bold;
        border-top-right-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 13px -30px;
        padding-left: 10px;
        margin-bottom: 5px;
    }
    section.newcontct .container{
        margin-top: 0px;
    }
    img.contctlady {
        display: none;
    }
    .feature-register {
        padding: 40px 40px 90px 40px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 6px 25px 0 #17703b26;
        margin-left: 0px;
    }
    section.newcontctbottm {
        padding-top: 40px;
        padding-bottom: 60px;
    }
    img.jobseeker {
        position: absolute;
        top: 15%;
    left: 2%;
    }
    section.faqsec {
        padding-top: 40px;
    }
    .react-responsive-modal-modal {
        padding: 30px;
        border-radius: 10px;
    }
    section.jobseekr h2 {
        text-align: left;
        margin-bottom: 10px;
    }
    p#booterheader {
        font-size: 16px;
        line-height: 26px;
    }
    div#jobhiring {
        margin-top: 10px;
    }
    .jobseeker-content {
        margin-left: 170px;
    }
  
    section.jobseekr {
        padding-top: 0px;
        padding-bottom: 10px;
    }
    div#jobhiring {
        margin-top: 10px;
    }
    .jbeeker {
        padding: 30px 30px 30px 30px;
        height: unset;
        margin-bottom: 30px;
        margin-left: unset;
        background: #17703b;
        border-radius: 10px;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: unset;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: unset;
    }
    .companyhiring-content {
        margin-right: 155px;
    }
    section.Updatefaq .accordion-header {
        text-align: center;
        margin-bottom: 10px;
        line-height: 25px;
    }
    img.companyhiring {
        position: absolute;
        top: 25%;
        right: 13%;
    }
    section.Updatefaq .container {
        padding: 0px;
    }
    div#accUpdatefaq {
        padding: 0;
    }
    section.Updatefaq {
        padding-top: 25px;
    }
    section.faqsec {
        padding-bottom: 40px;
    }
    section.thankyou {
             padding-bottom: 70px;
        padding-top: 120px;
      }
      .thankyoubox img {
        width: 45%;
        margin-bottom: 30px;
    }
    .thankyoubox {
        background: #fff;
        padding: 50px 20px;
        width: 100%;}
    .pageblog {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    section.blogsection .post-inner {
        border-radius: 10px;
        box-shadow: 0 5px 10px rgb(16 17 21 / 10%);
        transition: all .3s ease;
        margin-bottom: 80px;
    }
    section.hiringserivce9 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    section.serpgsec6 {
        padding-top: 60px;
    }
    section.serpgsec6 h2 {
        margin-top: 40px;
    }
    section.hiringserivce7 {
        padding-top: 60px;
    }
    section.alsersections8 {
        padding-top: 40px;
    }
    .hrtabcontent br {
        display: none;
    }
    section.hiringserivce5 {
        padding-top: 60px;
    }
    section.alsersections4 {
        padding-top: 40px;
    }
    .top-notch {
        box-shadow: 0 0 10px rgb(136 136 136 / 10%);
        margin-right: unset;
    }
    section.hiringserivce3 {
        padding-top: 60px;
    }
    section.serpgsec3 h2 {
        margin-bottom: 20px;
        margin-top: 40px;
    }
    section.hiringserivce {
        padding-bottom: 40px;
    }
    section.hiringserivce .hrtabtile {
        font-size: 18px;
        padding-top: 13px;
    }
    section.serpgsec3 {
        padding-top: 0px;
    }
    .react-tabs__tab {
        transition: 0.3s all ease-in-out;
        display: inline-block;
        border: 1px solid #f2f7f5;
        border-bottom: none;
        bottom: unset;
        position: relative;
        margin-bottom: 10px;
        list-style: none;
        text-align: center;
        margin-right: 10px;
        width: 100%;
        border-radius: 5px;
        height: 140px;
        background: #f2f7f5;
    }
    div#headintb {
        margin-top: 40px;
    }
    .react-tabs__tab-panel--selected {
        display: block;
        margin-left: 0px;
    }
    section.alsersections {
        padding-bottom: 60px;
    }
    div#perfect {
        background: #fff;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 0%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 60px 60px 60px;
        z-index: -111;
        margin-top: 40px;
    }
    section.bottom.sersec {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    h2.Privacy {
        margin-bottom: 10px;
    }
    section.Privacypolicy2 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    section.Privacypolicy {
        background: #f2f7f5;
        padding: 130px 0px 60px 0px;
        margin-bottom: 10px;
    }
        h1.Privacy {
            text-align: left;
            font-size: 30px;
        }
    section.abtbnner h1 br {
        display: none;
    }
    section.Servicepagebanner h1 {
        text-align: left;
        font-size: 45px;
    }
    section.Servicepagebanner {
        padding-top: 160px;
        padding-bottom: 60px;
    }
    .homeform {
        margin-left: 0px;
        margin-top: 0px;
    }
    .swiper-Industriesserve::after {
               left: 94%;}
    section.industries {
        text-align: center;
        padding-top: 30px;
    }
    section.services {
        padding-bottom: 120px;}
    img.hire {
   width: 12%;}
   div#lasthiring {
    margin-top: 0px;
}
    .allserivces {
            margin-bottom: 20px;
    }
    div#candidate {
        margin-top: 0px;
    }
    .feature-register input {
        outline: none;
        border: 1px solid #d9d9da;
        margin-bottom: 5px;
        border-radius: 5px;
        line-height: 15px;
        font-size: 14px;
    }

    .section-cont {
        padding-bottom: 50px;
    }
    .contact-item {
        padding: 26px 30px;
        box-shadow: 0 0px 10px rgb(136 136 136 / 10%);
        width: 100%;
        background: #17703b;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .section-cont .feature-register {
        margin-bottom: 30px;
    }
    section.contactbner h1 {
        text-align: left;
        font-size: 45px;
    }
    p.Are {
        text-align: left;}
    section.contactbner {
        padding-top: 160px;
        padding-bottom: 60px;
    }
    section.abtbnner h1 br {
        display: none;
    }
    .clients-section {
        padding-bottom: 50px;
    }

    section.abtsec3 p {
        text-align: left;
    }
  
    section.abtsec3 p br {
        display: none;
    }
    section.hrounter {
        padding-top: 10px;
        padding-bottom: 50px;
    }
    .jobs {
        margin-left: 0px;
        padding-top: 20px;
    }
    img.cert {
        margin-top: 40px;
    }
    section.abtsec3 h2 {
        margin-bottom: 20px;
        text-align: left;
    }
    .sponsor-section {
        padding: 20px 0;
    }
    section.abtsec3 {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    section.abtsec2 {
        background: #f2f7f5;
        padding-top: 200px;
        margin-top: -185px;
        padding-bottom: 30px;
        position: relative;
        z-index: -111111;
    }
    div#tean-right {
        position: relative;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: unset;
        margin-top: 40px;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 30px 30px 30px 30px;
        z-index: 111;
    }
    img.visi {
        margin-top: 20px;
        max-width: 100%;
    }
    .Expenses {
        padding-top: 50px;
    }
    img.hrteam {
        left: unset;
        border-radius: 10px;
        width: 100%;
        margin-top: 16px;
        position: relative;
        z-index: 11111;
    }
    .abtbotmbox {
        display: none;
    }
    section.abtsec1 {
        padding-top: 50px;
        z-index: -1111;
        position: relative;
    }
    div#tean {
        margin-top: 10px !important;
        margin: 0;
        text-align: left !important;
    }
    p.working {
        font-size: 20px;
        line-height: 30px;
        text-align: left;
    }
    section.abtbnner {
        padding-top: 160px;
        padding-bottom: 60px;
    }
    section.abtbnner h1 br {
        display: none;
    }
    section.abtbnner h1 {
        text-align: left;
        font-size: 45px;
    }
    section.Myhomesec1 {
        padding-top: 60px;
        text-align: left;
    }
    
    .box-content {
        width: 100%;
        padding: 20px 15px;}
    .arrows section .box-title:before {
               top: 10px;
               left: 10px;
    }
    .arrows section .box-title {
            padding-left: 25px;
        height: auto;
        line-height: 26px;
        padding-top: 10px;
        padding-bottom: 10px;}

            .box-close {
            height: auto;
             overflow: hidden;}
    .bnrtext h1 {
        font-size: 45px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 55px;
        margin-bottom: 0px;
    }
    img#Smilinggirl {
        margin-top: 30px;
    }
    h2 {
        font-size: 35px;
    }
    .services .box {
        margin-bottom: 20px;
    }
    section.process {
        padding-top: 60px;
    }
    section.process h2 {
        margin-bottom: 20px;
        margin-top: 0px;
    }
    img.newhr {
        margin-top: 30px;
    }
    .clients-section.style-3.padding-tb {
        padding-top: 50px;
        padding-bottom: 70px;
    }
    section.contactsec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    div#Expense {
       padding: 30px 10px;
  }
  section.faqsec h2 {
    text-align: left;
    margin-bottom: 20px;
}
p.organiation {
    text-align: left;
}
p.organiation br {
    display: none;
}
div#company {
    padding: 0px;
}
section.faqhome {
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 60px;
}
}
@media (max-width: 1199px) and (min-width: 992px){
    .menu-area .menu>ul>li>a {
    color: #101115;
    font-size: 1rem;
    font-weight: 700;
    padding: 34px 13px;
    text-transform: capitalize;
}
.header-section.header-fixed .header-bottom {
    background: #fff;
    padding-top: 35px;
}
}
@media (max-width: 991px) and (min-width: 768px){
    .headercnt .box-body p a {
        padding: 0px 0px;}
    section.process {
        padding-top: 60px;
    }
    div#wrong {
        padding-top: 40px;
    }
    section.newcontct .container{
        margin-top: -43px;
    }
    img.contctlady {
        position: unset;
        transition: all .3s ease;
        left: unset;
        width: 100%;
        top: unset;
        border-radius: 10px;
        box-shadow: 0 6px 25px 0 #17703b26;
    }
    .feature-register {
        padding: 40px 40px 100px 40px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 6px 25px 0 #17703b26;
        margin-left: 0px;
    }
    .cntctdetail {
        text-align: left;
    }
    .companyhiring-content {
        margin-right: 150px;
        margin-top: 14px;
    }
    section.jobseekr h2 {
        text-align: left;
        margin-bottom: 10px;
    }
    img.companyhiring {
        position: absolute;
        top: 7%;
        right: 13%;
    }
    .companyhiringcontent {
           margin-right: 0px;
    }
    .jobseeker-content {
        margin-left: 170px;
        margin-top: 14px;
    }
    img.jobseeker {
        position: absolute;
        top: 6%;
        left: 1%;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 240px;
        margin-left: 0px;
        margin-bottom: 30px;
        background: #17703b;
        border-radius: 10px;
    }
    div#jobhiring {
        margin-top: 30px;
    }
    section.jobseekr {
        padding-top: 10px;
        padding-bottom: 50px;
    }
    div#accUpdatefaq {
        padding: 0px;
    }
    section.Updatefaq .container {
        padding: 0px;
    }
    section.Updatefaq {
        padding-top: 30px;
    }
    section.faqsec {
        padding-bottom: 60px;
    }
    section.Servicepagebanner h1 {
        text-align: center;
        font-size: 45px;
    }
    section.serpgsec6 {
        padding-top: 60px;
    }
    section.hiringserivce9 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    section.alsersections8 {
        padding-top: 60px;
    }
    section.hiringserivce7 {
        padding-top: 60px;
    }
    section.hiringserivce3 {
        padding-top: 60px;
    }
    section.hiringserivce5 {
        padding-top: 60px;
    }
    section.alsersections4 {
        padding-top: 60px;
    }
    .top-notch {
        box-shadow: 0 0 10px rgb(136 136 136 / 10%);
        margin-right: 0px;
    }
    section.serpgsec3 {
        padding-top: 0px;
    }
    section.alsersections {
        padding-bottom: 60px;
    }
    .react-tabs__tab-panel--selected {
        display: block;
        margin-left: 0px;
    }
    .react-tabs h2 {
        margin-bottom: 20px;
        margin-top: 40px;
    }
    div#perfect {
        background: #fff;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 0%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 30px 10px 30px 20px;
        z-index: -111;
    }
    section.bottom.sersec {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    section.Privacypolicy2 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    h2.Privacy {
        margin-bottom: 20px;
    }
    h1.Privacy {
        text-align: center;
        font-size: 45px;
    }
    section.Servicepagebanner {
        padding-top: 180px;
        padding-bottom: 80px;
    }
    section.second.footer {
        background: #f2f7f5;
        padding: 60px 0px;
    }
    .swiper-Industriesserve::after {
                left: 96%;}
    
    section.second.footer form#myForm {
        width: 100%;
    }
    .arrows section .box-title {
               font-size: 16px;
        border-radius: 5px;}
    img.blackgirl {
        display: none;
    }
    .clients-section.style-3 {
        padding-top: 0px;
        padding-bottom: 70px;
    }
    .homeform {
        margin-left: 0px;
        margin-top: 0px;
    }
    img.clientlogo1 {
        max-width: 149px;
        max-height: 100px;
    }
    img.hire {
        border: 1px solid #e4e4e491;
        padding: 6px;
        width: 10%;}
        img.hire2 {
            border: 1px solid #e4e4e491;
            padding: 10px;
            width: 10%;}
    .section-cont {
        padding-bottom: 50px;
    }
    .section-cont .feature-register {
        margin-bottom: 30px;
    }
    section.contactbner h1 {
        text-align: center;
        font-size: 45px;
    }
    p.Are br {
        display: none;
    }
    section.contactbner {
        padding-top: 180px;
        padding-bottom: 80px;
    }
    div#tean-right {
        position: relative;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 7%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 30px 30px 30px 40px;
        z-index: 111;
    }
    section.hrounter {
        padding-top: 60px;
        padding-bottom: 80px;
    }
    div#blackcounter {
        padding: 0px 0px 20px 0px;
        box-shadow: 0px 5px 20px 3px #edf4f1;
        border-radius: 0px 0px 10px 10px;
        height: 520px;
    }
    .jobs {
        margin-left: 10px;
    }
    div#Reviews br {
        display: none;
    }
    div#blackcounter1 {
        padding-left: 20px;
        padding-right: 20px;
    }
    section.abtsec3 {
        padding-top: 60px;
        padding-bottom: 20px;
    }
    section.abtsec3 p br {
        display: none;
    }
    section.abtsec3 h2 {
        margin-bottom: 20px;
        text-align: left;
    }
  
    section.abtsec3 p {
        text-align: left;
    }
    img.visi {
        margin-top: 0px;
        max-width: 100%;
    }
    section.abtsec2 {
        background: #f2f7f5;
        padding-top: 50px;
        margin-top: 50px;
        padding-bottom: 50px;
        position: relative;
        z-index: -111111;
    }
    .Expenses {
        padding-top: 0px;
    }
   
    img.hrteam {
        left: 0%;
        border-radius: 10px;
        width: 85%;
        margin-top: 30px;
        position: relative;
        z-index: 11111;
    }
    section.abtsec1 {
        padding-top: 60px;
        z-index: -1111;
        position: relative;
    }
    div#tean {
        margin-top: 20px !important;
        margin: 0;
        text-align: left;
    }
    p.working br {
        display: none;
    }
    p.working {
        font-size: 20px;
        line-height: 30px;
    }    
    section.abtbnner h1 {
        text-align: center;
        font-size: 45px;
    }
    section.abtbnner {
          padding-top: 180px;
        padding-bottom: 80px;
    }
    section.abtbnner h1 br {
        display: none;
    }
    h2 {
        font-size: 34px;
    }
    .bnrtext {
        margin-top: 0px;
        margin-right: 10px;
    }
    .bnrtext h1 {
        font-size: 34px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 48px;
        margin-bottom: 28px;
    }
    .bnrtext1 h1 {
        font-size: 34px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 48px;
        margin-bottom: 28px;
    }
   
    section.Myhomesec1 {
        padding-top: 60px;
        text-align: left;
    }
    section.industries {
        text-align: center;
        padding-top: 50px;
    }
    section.industries p br {
        display: none;
    }
    .clients-slider .swiper-slide {
        margin-right: 18px !important;
        height: 430px;
    }
    img.gentleman {
        display: none;
    }
    .services .box {
        margin-top: 10px;}
    .allserivces {
            padding: 30px 30px 30px 30px;
       }
       div#candidate {
        margin-top: 0px;
    }
    div#Expense {
         padding: 30px 20px;}
         section.faqsec h2 {
            text-align: left;
            margin-bottom: 20px;
        }
        p.organiation br {
            display: none;
        }
        section.faqhome {
            margin: 0 auto;
            padding-top: 20px;
            padding-bottom: 60px;
        }
        div#lasthiring {
            margin-top: 0px;
        }
        section.contactsec {
            padding-top: 40px;
            padding-bottom: 60px;
        }
        p.organiation {
            text-align: left;
        }
        div#company {
            padding: 0px;
        }
        section.process h2 {
    margin-bottom: 20px;
    margin-top: 0px;
}
}
@media (max-width: 1024px) and (min-width: 1000px){
    section.abtbnner h1 {
        text-align: center;
        font-size: 48px;
    }
    .feature-register {
        padding: 40px 100px 100px 80px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 6px 25px 0 #17703b26;
        margin-left: 50px;
    }
    img.contctlady {
        position: relative;
        transition: all .3s ease;
        left: -17%;
        width: 100%;
        top: 5%;
        border-radius: 10px;
        box-shadow: 0 6px 25px 0 #17703b26;
    }
    img.companyhiring {
        position: absolute;
        top: 31%;
        right: 13%;
    }
    section.jobseekr {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    section.faqsec {
        padding-top: 50px;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: 325px;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: 0px;
    }
    img.jobseeker {
        position: absolute;
        top: 31%;
        left: 2%;
    }
    img.jobseeker {
        position: absolute;
        top: 31%;
        left: 2%;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 325px;
        margin-left: 0px;}
  
    section.Updatefaq {
        padding-top: 30px;
    }
    section.faqsec {
        padding-bottom: 60px;
    }
    section.hiringserivce7 {
        padding-top: 80px;
   
    }
    .react-tabs__tab-panel--selected {
        display: block;
        margin-left: 0px;
    }
    .timeline > li > .timeline-badge {
        color: #353535;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 1.4em;
        font-weight: 700;
        text-align: center;
        position: absolute;
        top: 16%;
        left: 0%;
        margin-left: -27px;
        background-color: #fff;
        z-index: 100;
        border-top-right-radius: 10%;
        border-top-left-radius: 10%;
        border-bottom-right-radius: 10%;
        border-bottom-left-radius: 10%;
    }
    span.Expert {
        display: none;
    }
    section.serpgsec6 {
        padding-top: 80px;
    }
    .top-notch {
        box-shadow: 0 0 10px rgb(136 136 136 / 10%);
        margin-right: 10px;
    }
    section.hiringserivce9 {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    section.alsersections8 {
        padding-top: 80px;
    }
    .hrtabtile {
        font-size: 16px;
    }
    .react-tabs__tab {
        transition: 0.3s all ease-in-out;
        display: inline-block;
        border: 1px solid #f2f7f5;
        border-bottom: none;
        bottom: unset;
        position: relative;
        list-style: none;
        text-align: center;
        margin-right: 10px;
        width: 135px;
        border-radius: 5px;
        height: 140px;
        background: #f2f7f5;
    }
    div#perfect {
        background: #fff;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 4%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 60px 60px 60px;
        z-index: -111;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
        float: left;
        margin-left: 20px;
    }
    /* .timeline:before {
        top: 69px;
        bottom: 50px;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: -6%;
        margin-left: 6px;
    } */
    section.bottom.sersec {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    p.working br {
        display: none;
    }
    section.Privacypolicy2 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    div#lasthiring {
        margin-top: 0px;
    }
    img.newhr {
        margin-top: 40px;
    }
    .homeform {
        margin-left: 0px;
        margin-top: 0px;
    }
    section.process {
    padding-top: 80px;
}
.swiper-Industriesserve::after {
 display: none;
}
.swiper-Industriesserve::before{
    display: none;
}
    section.industries p#booterheader br {
        display: none;
    }
    section.second.footer form#myForm {
        width: 100%;
    }
    .contact-item {
        padding: 26px 30px;
        box-shadow: 0 0px 10px rgb(136 136 136 / 10%);
        width: 100%;}
    
    section.contactbner h1 {
        text-align: center;
        font-size: 48px;
    }
    section.contactbner {
        padding-top: 180px;
        padding-bottom: 90px;
    }
    section.abtsec3 {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    img.visi {
        margin-top: 80px;
        max-width: 100%;
    }
    .abtbotmbox {
        background: #17703b;
        border-radius: 5px;
        margin-top: -25px;}
    div#blackcounter1 {
        padding-left: 20px;
        padding-right: 20px;
    }
    div#blackcounter {
        height: 530px;}
    .jobs {
        margin-left: 35px;
    }
    img.hrteam {
        left: 10%;
        border-radius: 10px;
        width: 80%;
        margin-top: 50px;
        position: relative;
        z-index: 11111;
    }
    div#tean-right {
        position: relative;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 3%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 30px 60px 55px;
        z-index: 111;
    }
    section.abtsec1 {
        padding-top: 40px;
        z-index: -1111;
        position: relative;
    }

    ul.abtlist li {
        padding: 0px 10px;
    }
    section.abtbnner {
        padding-top: 180px;
        padding-bottom: 90px;
    }
    .bnrtext {
        margin-top: 10px;
        margin-right: 10px;
    }
    .bnrtext h1 {
        font-size: 45px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 55px;
        margin-bottom: 10px;
    }
    section.industries {
        text-align: center;
        padding-top: 70px;
    }
    img.gentleman {
        display: none;
    }
    .allserivces {
padding: 30px 30px 30px 30px;
         }
         .services .box{margin-top: 10px;}
         section.process h2 {
            margin-bottom: 20px;
            margin-top: 0px;
        }
        .clients-section.style-3 {
          padding-top: 90px;
            padding-bottom: 100px;
        }
        section.faqhome {
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 60px;
        }
        div#Expense {
        padding: 30px 20px;}
        section.contactsec {
            padding-top: 40px;
            padding-bottom: 60px;
        }
         section.process {
            padding-top: 80px;
        }
         div#candidate {
            margin-top: 0px;
        }      
}
@media (max-width: 1199px) and (min-width: 1025px){
    .bnrtext {
        margin-top: 10px;
        margin-right: 10px;
    }
    img.contctlady {
        position: relative;
        transition: all .3s ease;
        left: -22%;
        width: 100%;
        top: 5%;
        border-radius: 10px;
        box-shadow: 0 6px 25px 0 #17703b26;
    }
    .feature-register {
        padding: 40px 140px 100px 80px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 6px 25px 0 #17703b26;
        margin-left: 0;
    }

    section.faqsec {
        padding-top: 60px;
    }
    section.jobseekr {
        padding-top: 10px;
        padding-bottom: 50px;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 240px;
        margin-left: 0px;
        background: #17703b;
        border-radius: 10px;
    }
    img.gentleman {
        display: none;
    }
    img.jobseeker {
    position: absolute;
    top: 7%;
    left: 1%;
}
    section.jobseekr {
        padding-top: 10px;
        padding-bottom: 60px;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: 325px;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: 0px;
    }
    img.companyhiring {
        position: absolute;
        top: 31%;
        right: 13%;
    }
    img.jobseeker {
        position: absolute;
        top: 31%;
        left: 2%;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 325px;
        margin-left: 0px;
        background: #17703b;
        border-radius: 10px;
    }
    .banner-section .all-shapes {
        width: 400px;
        height: 400px;
        border-radius: 100%;
        border-width: 1.75px;
        top: 24%;
        right: 8%;
        background: #ecf3ef;
        animation: rotate 50s infinite linear;
        box-shadow: 0px 5px 10px 0px #ecf3ef;
    }
    .bnrtext1 h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    section.Updatefaq {
        padding-top: 30px;
    }
    .bnrtext1 h1 {
        line-height: 54px;
        font-size: 44px;
    }
    section.faqsec {
        padding-bottom: 60px;
    }
    section.alsersections8 {
        padding-top: 100px;
    }
    section.hiringserivce7 {
        padding-top: 100px;
    }
    section.serpgsec6 {
        padding-top: 100px;
    }
    section.hiringserivce5 {
        padding-top: 100px;
    }
    section.alsersections4 {
        padding-top: 100px;
    }
    section.hiringserivce3 {
        padding-top: 100px;
    }
    section.serpgsec3 {
        padding-top: 60px;
    }
    .top-notch {
        box-shadow: 0 0 10px rgb(136 136 136 / 10%);
        margin-right: 10px;
    }
    p.working br {
        display: none;
    }
    .hrtabtile {
        font-size: 16px;
    }
    .react-tabs__tab {
        transition: 0.3s all ease-in-out;
        display: inline-block;
        border: 1px solid #f2f7f5;
        border-bottom: none;
        bottom: unset;
        position: relative;
        list-style: none;
        text-align: center;
        margin-right: 10px;
        width: 135px;
        border-radius: 5px;
        height: 140px;
        background: #f2f7f5;
    }
    div#perfect {
        background: #fff;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 4%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 60px 60px 60px;
        z-index: -111;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
        float: left;
        margin-left: 20px;
    }
    /* .timeline:before {
        top: 69px;
        bottom: 50px;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: -6%;
        margin-left: 6px;
    } */
    .react-tabs__tab-panel--selected {
        display: block;
        margin-left: 0px;
    }
    .timeline > li > .timeline-badge {
        color: #353535;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 1.4em;
        font-weight: 700;
        text-align: center;
        position: absolute;
        top: 16%;
        left: 0%;
        margin-left: -27px;
        background-color: #fff;
        z-index: 100;
        border-top-right-radius: 10%;
        border-top-left-radius: 10%;
        border-bottom-right-radius: 10%;
        border-bottom-left-radius: 10%;
    }
    span.Expert {
        display: none;
    }
    section.Privacypolicy2 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .clients-section.style-3.padding-tb {
        padding-top: 40px;
    }
    section.second.footer form#myForm {
        width: 100%;
    }
    .swiper-Industriesserve::after {
        display: block;
        content: '';
        position: relative;
        right: 0;
        bottom: 0px;
        left: 97%;}
    .homeform {
        margin-left: 0px;
        margin-top: 0px;
    }
    div#Expense {
        background: linear-gradient(130deg, #17703b 0%, #197d42 100%);
        border-radius: 20px;
        padding: 40px 20px 40px 20px;
        box-shadow: 0 6px 25px 0 #17703b26;
    }
    section.contactbner h1 {
        text-align: center;
        font-size: 50px;
    }
    div#blackcounter1 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .jobs {
        margin-left: 30px;
    }
    div#blackcounter {
        height: 533px;}
    section.abtsec3 {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    section.hrounter {
        padding-top: 80px;
        padding-bottom: 100px;
    }
    img.hrteam {
        left: 10%;
        border-radius: 10px;
        width: 80%;
        margin-top: 60px;
        position: relative;
        z-index: 11111;
    }
    img.visi {
        margin-top: 80px;
        max-width: 100%;
    }
    div#tean-right {
        position: relative;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 4%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 30px 60px 60px;
        z-index: 111;
    }
    section.abtsec1 {
        padding-top: 50px;
        z-index: -1111;
        position: relative;
    }
    ul.abtlist li {
        padding: 0px 10px;
    }
    section.abtbnner h1 {
        text-align: center;
        font-size: 50px;
    }
    .bnrtext h1 {
        font-size: 48px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 58px;
        margin-bottom: 10px;
    }
    section.industries {
        text-align: center;
        padding-top: 60px;
    }
    img.hire {
        border: 1px solid #e4e4e491;
        padding: 6px;
        width: 40%;
        background: #fff;
        box-shadow: -6px 10px 10px #17703b26;
        border-radius: 8px;
        margin-bottom: 15px;
    }
  
    .allserivces {
        padding: 30px 30px 30px 30px;
       margin-bottom: 20px;
    }
    section.process h2 {
        margin-bottom: 20px;
        margin-top: 0px;
    }
    section.contactsec {
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .services .box {
    height: 260px;
    }
    section.faqhome {
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 60px;
    }
}
@media (max-width: 1400px) and (min-width: 1200px){
    .bnrtext {
        margin-top: 20px;
        margin-right: 10px;
    }
    img.contctlady {
        position: relative;
        transition: all .3s ease;
        left: -20%;
        width: 100%;
        top: 5%;
        border-radius: 10px;
        box-shadow: 0 6px 25px 0 #17703b26;
    }
    section.jobseekr {
        padding-top: 30px;
        padding-bottom: 60px;
    }
    section.faqsec {
        padding-top: 60px;
    }
    img.jobseeker {
        position: absolute;
        top: 7%;
        left: 1%;
    }
    img.companyhiring {
        position: absolute;
        top: 7%;
        right: 13%;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: 240px;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: 0px;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 240px;
        margin-left: 0px;}
    section.Updatefaq {
        padding-top: 30px;
    }
    section.faqsec {
        padding-bottom: 60px;
    }
    img.hire {
        border: 1px solid #e4e4e491;
        padding: 6px;
        width: 40%;}
    section.alsersections8 {
        padding-top: 100px;
    }
    section.hiringserivce7 {
        padding-top: 100px;
    }
    section.serpgsec6 {
        padding-top: 100px;
    }
    section.hiringserivce5 {
        padding-top: 100px;
    }
    section.alsersections4 {
        padding-top: 100px;
    }
    section.hiringserivce3 {
        padding-top: 100px;
    }
    section.serpgsec3 {
        padding-top: 60px;
    }
    .top-notch {
        box-shadow: 0 0 10px rgb(136 136 136 / 10%);
        margin-right: 10px;
    }
    p.working br {
        display: none;
    }
    .hrtabtile {
        font-size: 16px;
    }
    .react-tabs__tab {
        transition: 0.3s all ease-in-out;
        display: inline-block;
        border: 1px solid #f2f7f5;
        border-bottom: none;
        bottom: unset;
        position: relative;
        list-style: none;
        text-align: center;
        margin-right: 10px;
        width: 135px;
        border-radius: 5px;
        height: 140px;
        background: #f2f7f5;
    }
    div#perfect {
        background: #fff;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 4%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 60px 60px 60px;
        z-index: -111;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
        float: left;
        margin-left: 20px;
    }
    /* .timeline:before {
        top: 69px;
        bottom: 50px;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: -6%;
        margin-left: 6px;
    } */
    .react-tabs__tab-panel--selected {
        display: block;
        margin-left: 0px;
    }
    .timeline > li > .timeline-badge {
        color: #353535;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 1.4em;
        font-weight: 700;
        text-align: center;
        position: absolute;
        top: 16%;
        left: 0%;
        margin-left: -27px;
        background-color: #fff;
        z-index: 100;
        border-top-right-radius: 10%;
        border-top-left-radius: 10%;
        border-bottom-right-radius: 10%;
        border-bottom-left-radius: 10%;
    }
    span.Expert {
        display: none;
    }
    section.bottom.sersec {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    
    section.Privacypolicy2 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .homeform {
        margin-left: 0px;
        margin-top: 0px;
    }
    .clients-section.style-3 {
         padding-top: 60px;
        padding-bottom: 100px;
    }
    div#Expense {
        background: linear-gradient(130deg, #17703b 0%, #197d42 100%);
        border-radius: 20px;
        padding: 60px 80px 60px 80px;}
    .swiper-Industriesserve::after {
        display: block;
        content: '';
        position: relative;
        right: 0;
        bottom: 0px;
        left: 97.4%;}
    img.hrteam {
        left: 10%;
        border-radius: 10px;
        width: 80%;
        margin-top: 50px;
        position: relative;
        z-index: 11111;
    }
    .jobs {
        margin-left: 60px;
    }
    section.abtbnner h1 {
        text-align: center;
        font-size: 50px;
    }
    div#tean-right {
        position: relative;
        transition: 0.3s all ease-in-out;
        position: relative;
        background: #fff;
        right: 5%;
        border-radius: 16px;
        box-shadow: 0 6px 25px 0 #17703b26;
        padding: 60px 60px 60px 80px;
        z-index: 111;
    }
    section.abtsec1 {
        padding-top: 60px;
        z-index: -1111;
        position: relative;
    }
    .bnrtext h1 {
        font-size: 48px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 58px;
        margin-bottom: 10px;
    }
    img.gentleman {
        margin-top: -74px;
        margin-left: 140px;
        max-width: 52%;
    }
    .services .box {
          height: 267px;
    }
    section.process h2 {
        margin-bottom: 20px;
        margin-top: 0px;
    }
    section.contactsec {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    section.faqhome {
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 60px;
    }
}
@media (max-width: 1440px) and (min-width: 1401px){
    .bnrtext {
        margin-top: 36px;
        margin-right: 20px;
    }
    .Expenses {
        padding-top: 149px;
        padding-right: 60px;
    }
    img.contctlady {
        position: relative;
        transition: all .3s ease;
        left: -22%;
        width: 93%;}
        .feature-register {
         margin-left: 50px;
        }
    div#candidate .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
        padding: 10px;
    }
    img.jobseeker {
        position: absolute;
        top: 7%;
        left: 2%;
    }
    section.jobseekr {
        padding-top: 30px;
        padding-bottom: 70px;
    }
    section.faqsec {
        padding-top: 60px;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: 240px;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: 0px;
    }
    img.companyhiring {
        position: absolute;
        top: 7%;
        right: 13%;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 240px;
        margin-left: 0px;}
    div#fsty .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
        padding: 10px;
    }
    section.Updatefaq {
        padding-top: 30px;
    }
    section.faqsec {
        padding-bottom: 60px;
    }
    .react-tabs__tab {
        margin-right: 10px;
        width: 170px;
         height: 120px;
         }
         .hrtabtile {
            line-height: 20px;
            font-size: 16px;
        }
        
        
    section.Privacypolicy2 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .homeform {
        margin-left: 0px;
        margin-top: -8px;
    }
    .clients-section.style-3 {
         padding-top: 60px;
        padding-bottom: 100px;
    }
    div#Expense {
        background: linear-gradient(130deg, #17703b 0%, #197d42 100%);
        border-radius: 20px;
        padding: 60px 80px 0px 80px;}
    section.abtbnner h1 {
        text-align: center;
        font-size: 50px;
    }
    img.hrteam {
        left: 3%;
        border-radius: 10px;
        width: 100%;
        margin-top: 36px;
        position: relative;
        z-index: 11111;
    }
    img.visi {
        margin-top: 80px;
        max-width: 100%;
    }
    .bnrtext h1 {
        font-size: 50px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 60px;
        margin-bottom: 22px;
    }
    .services .box {
    height: 280px;
    }
    img.gentleman {
        margin-top: 57px;
        margin-left: 155px;
        max-width: 52%;
    }
    section.process h2 {
        margin-bottom: 20px;
        margin-top: 0px;
    }
    section.contactsec {
        padding-top: 40px;
        padding-bottom: 60px;
    }
    section.faqhome {
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 60px;
    }
}
@media (max-width: 1600px) and (min-width: 1441px){
    .bnrtext {
        margin-top: 57px;
        margin-right: 30px;
    }
    .banner-section .cbs-content-list ul li.shape-1 {
        top: 37%;
        right: 37%;
    }
    div#candidate .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
        padding: 10px;
    }
    div#fsty .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
        padding: 10px;
    }
    .bnrtext h1 {
        font-size: 48px;
        letter-spacing: -2px;
        font-weight: 650;
        line-height: 58px;
        margin-bottom: 22px;
    }
    .services .box {
        height: 247px;
    }
    img.gentleman {
        margin-top: -36px;
        margin-left: 157px;
        max-width: 52%;
    }
}
@media (max-width: 1920px) and (min-width: 1601px){
    div#candidate .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
        padding: 10px;
    }
    div#fsty .col-xxl-3.col-xl-3.col-lg-3.col-md-12.col-12 {
        padding: 10px;
    }
 
}
@media (max-width: 1800px) and (min-width: 1600px){
    .banner-section .cbs-content-list ul li.shape-1 {
        animation: up-down 6s infinite linear;
        top: 28%;
        right: 40%;
    }
    .banner-section .cbs-content-list ul li.shape-4 {
        animation: up_down_3 6s infinite linear;
        top: 26%;
        right: 5%;
    }
    .banner-section .cbs-content-list ul li.shape-2 {
        animation: up_down_2 6s infinite linear;
        top: 64%;
        right: 2%;
    }
    .banner-section .all-shapes {
        right: 6%;
    }

}
@media (max-width: 1920px) and (min-width: 1801px){
    .banner-section .all-shapes {
        width: 600px;
        height: 600px;
        border-radius: 100%;
        border-width: 1.75px;
        top: 20%;
        right: 14%;}
   
        .banner-section .cbs-content-list ul li.shape-1 {
            animation: up-down 6s infinite linear;
            top: 38%;
            right: 43%;
        }
        .banner-section .cbs-content-list ul li.shape-4 {
            animation: up_down_3 6s infinite linear;
            top: 28%;
            right: 10%;
        }
        .banner-section .cbs-content-list ul li.shape-2 {
            animation: up_down_2 6s infinite linear;
            top: 61%;
            right: 9%;
        }

}
@media (max-width: 1920px) and (min-width: 768px){
    .mobile {
        display: none;
    }
    .desk {
        display: block;
    }
   
}

@media (max-width: 767px) and (min-width: 320px){
    .mobile {
        display: block;
    }
    section#Payroll .react-tabs__tab {
            height: 110px;}
            section.hiringserivce7.mobile .react-tabs__tab {
              height: 110px;}
    .desk {
        display: none;
    }
   
}

@media (max-width: 1365px) and (min-width: 320px){
    section.banner-section.mybannerupdated {
        background: #f9fcfb;
        display: none;
    }
}
@media (max-width: 1920px) and (min-width: 1366px){
    section.mybanner {
        display: none;
    }


}



@media (max-width: 1439px) and (min-width: 1366px){
    .banner-section .all-shapes {
        width: 500px;
        height: 500px;
        border-radius: 100%;
        border-width: 1.75px;
        top: 22%;
        right: 8%;
        background: #ecf3ef;
        animation: rotate 50s infinite linear;
        box-shadow: 0px 5px 10px 0px #ecf3ef;
    }
    .banner-section .cbs-content-list ul li.shape-2 {
        animation: up_down_2 6s infinite linear;
        top: 55%;
        right: 4%;
    }
    img.smartgirl {
        transition: all .3s ease;
        max-width: 80%;
        margin-bottom: -56px;
    }
    .banner-section .cbs-content-list ul li.shape-1 {
        animation: up-down 6s infinite linear;
        top: 33%;
        right: 35%;
    }
    .bnrtext1 h1 {
        line-height: 54px;
        font-size: 45px;
    }
    .banner-section .cbs-content-list ul li.shape-4 {
        animation: up_down_3 6s infinite linear;
        top: 25%;
        right: 5%;
    }
 }

 @media (max-width: 1599px) and (min-width: 1440px){
    .banner-section .all-shapes {
        width: 490px;
        height: 490px;
        border-radius: 100%;
        border-width: 1.75px;
        top: 28%;
        right: 9%;
        background: #ecf3ef;
        animation: rotate 50s infinite linear;
        box-shadow: 0px 5px 10px 0px #ecf3ef;
    }
    .Expenses {
    padding-top: 149px;
    padding-right: 60px;
}
    img.smartgirl {
        transition: all .3s ease;
        max-width: 80%;
        margin-bottom: -32px;
    }
    .banner-section .cbs-content-list ul li.shape-1 {
        animation: up-down 6s infinite linear;
        top: 38%;
        right: 34%;
    }
    .banner-section .cbs-content-list ul li.shape-4 {
        animation: up_down_3 6s infinite linear;
        top: 28%;
        right: 6%;
    }
    .banner-section .cbs-content-list ul li.shape-2 {
        animation: up_down_2 6s infinite linear;
        top: 57%;
        right: 3%;
    }
    .bnrtext1 h1 {
        line-height: 55px;
        font-size: 45px;
    }
 }
 @media (max-width: 1599px) and (min-width: 1341px){
    section.jobseekr {
        padding-top: 30px;
        padding-bottom: 50px;
    }
    img.companyhiring {
        position: absolute;
        top: 7%;
        right: 13%;
    }
    .companyhiringcontent {
        padding: 30px 30px 30px 30px;
        height: 240px;
        background: #b4d7c9;
        border-radius: 10px;
        margin-right: 0px;
    }
    .jbeeker {
        padding: 30px 30px 30px 50px;
        height: 240px;
        margin-left: 0px;
        background: #17703b;
        border-radius: 10px;
    }
    img.jobseeker {
        position: absolute;
        top: 7%;
        left: 2%;
    }
 }
